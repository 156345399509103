import { SheetShapeDeepFragment } from '../../gql/graphql';
import {
  CornerMultiPolygon2Algorithm,
  ExtrudedPolygonAlgorithm,
  ExtrudedPolygonAlgorithmType,
  GeometricAlgorithmType,
  MeshAlgorithm,
  MeshAlgorithmType,
  MultiPolygon2Algorithm,
  MultiPolygon2AlgorithmType,
  MultiPolygonAlgorithm,
  MultiPolygonAlgorithmType,
  ParameterizedMultiPolygon2Algorithm,
  Polyline2Algorithm,
  Polyline2AlgorithmType,
  PolylineAlgorithm,
  PolylineAlgorithmType,
  RandMultiPolygon2Algorithm,
  SlopedInsulationAlgorithm,
} from './algorithm-types';
import { calculateBottomSurfaceArea } from './algorithms/bottom-surface-area';
import { calculateBoundingBoxLength } from './algorithms/bounding-box-length';
import { calculateBoundingBoxWidth } from './algorithms/bounding-box-width';
import { calculateExtrudedShapeSideArea } from './algorithms/extruded-shape-side-area';
import { calculateExtrudedShapeThickness } from './algorithms/extruded-shape-thickness';
import { calculateExtrudedShapeVolume } from './algorithms/extruded-shape-volume';
import { calculateGrossSideAreaConvexHull } from './algorithms/gross-side-area-convex-hull';
import { calculateLargestFlatSurfaceArea } from './algorithms/largest-flat-surface';
import { calculateLargestSurfaceArea } from './algorithms/largest-surface-area';
import { calculateLargestSurfaceAreaWithoutOpenings } from './algorithms/largest-surface-area-without-openings';
import { calculateLineLength } from './algorithms/line-length';
import { calculateLine2Length } from './algorithms/line2-length';
import { calculatePerimeter } from './algorithms/perimeter';
import { calculateShapeArea } from './algorithms/shape-area';
import { calculateShapeBoundingBoxWidth } from './algorithms/shape-bounding-box-width';
import { calculateShapeBoundingBoxLength } from './algorithms/shape-bounding-box-length';
import { calculateShapeGrossSideArea } from './algorithms/shape-gross-side-area';
import { calculateShapePerimeter } from './algorithms/shape-perimeter';
import { calculateShapeVerticalComponent } from './algorithms/shape-vertical-component';
import { calculateSheetShapeArea } from './algorithms/sheet-shape-area';
import { calculateSheetShapeCornerAreas } from './algorithms/sheet-shape-corner';
import { calculateSheetShapeFireSectioning } from './algorithms/sheet-shape-fire-sectioning';
import { calculateSheetShapeGrossSideArea } from './algorithms/sheet-shape-gross-side-area';
import { calculateSheetShapePerimeter } from './algorithms/sheet-shape-perimeter';
import { calculateSheetShapeRandfelt } from './algorithms/sheet-shape-randfelt';
import { calculateSummedTetrahedronVolume } from './algorithms/summed-tetrahedron-volume';
import { calculateTopSurfaceArea } from './algorithms/top-surface-area';
import { calculateTotalSurfaceArea } from './algorithms/total-surface-area';
import { calculateVerticalComponent } from './algorithms/vertical-component';
import { MultiPolygon2, Triangle } from './geometric-types';
import { calculateLine2BaselineLength } from './algorithms/line2-baseline-length';
import { calculateSheetShapeBoundingBoxWidth } from './algorithms/sheet-shape-bounding-box-width';
import { calculateSheetShapeBoundingBoxLength } from './algorithms/sheet-shape-bounding-box-length';
import { SlopedInsulationCrossSection } from 'src/components/orderContractor/pdf-shapes/SheetShapeDrawing';

// eslint-disable-next-line complexity
export function getGeometricAlgorithmForType(
  algorithmType: GeometricAlgorithmType,
  // Some of our algorithms are too slow to run on large meshes, so we
  // set a limit for these. If the mesh is larger than this, we return undefined
  maxTrianglesForComplexAlgorithm = 5000
):
  | MeshAlgorithm
  | MultiPolygonAlgorithm
  | ExtrudedPolygonAlgorithm
  | PolylineAlgorithm
  | MultiPolygon2Algorithm
  | Polyline2Algorithm
  | ParameterizedMultiPolygon2Algorithm
  | CornerMultiPolygon2Algorithm
  | RandMultiPolygon2Algorithm
  | SlopedInsulationAlgorithm {
  switch (algorithmType) {
    case MeshAlgorithmType.BOUNDING_BOX_LENGTH:
      return (triangles: Triangle[]) =>
        calculateBoundingBoxLength(triangles, false);
    case MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH:
      return (triangles: Triangle[]) =>
        calculateBoundingBoxLength(triangles, true);
    case MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH:
      return calculateBoundingBoxWidth;
    case MeshAlgorithmType.VERTICAL_COMPONENT:
      return calculateVerticalComponent;
    case MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA:
      return (
        triangles: Triangle[],
        maxMatchDistancePlanes?: number | undefined
      ) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateGrossSideAreaConvexHull(triangles, maxMatchDistancePlanes);
    case MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateGrossSideAreaConvexHull(triangles, Infinity);
    case MeshAlgorithmType.TOTAL_MESH_AREA:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateTotalSurfaceArea(triangles);
    case MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateLargestFlatSurfaceArea(triangles);
    case MeshAlgorithmType.LARGEST_SURFACE_AREA:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateLargestSurfaceArea(triangles);
    case MeshAlgorithmType.LARGEST_SURFACE_AREA_WITHOUT_OPENINGS:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateLargestSurfaceAreaWithoutOpenings(triangles);
    case MeshAlgorithmType.TETRAHEDRON_VOLUME:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateSummedTetrahedronVolume(triangles);
    case MeshAlgorithmType.TOP_SURFACE_AREA:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculateTopSurfaceArea(triangles);
    case MeshAlgorithmType.BOTTOM_SURFACE_AREA:
      return calculateBottomSurfaceArea;
    case MeshAlgorithmType.MESH_PERIMETER:
      return (triangles: Triangle[]) =>
        triangles.length > maxTrianglesForComplexAlgorithm
          ? undefined
          : calculatePerimeter(triangles);
    case MultiPolygonAlgorithmType.POLYGON_VERTICAL_COMPONENT:
      return calculateShapeVerticalComponent;
    case MultiPolygonAlgorithmType.POLYGON_GROSS_SIDE_AREA:
      return calculateShapeGrossSideArea;
    case MultiPolygonAlgorithmType.POLYGON_AREA:
      return calculateShapeArea;
    case MultiPolygonAlgorithmType.POLYGON_PERIMETER:
      return calculateShapePerimeter;
    case MultiPolygonAlgorithmType.POLYGON_BOUNDING_BOX_WIDTH:
      return calculateShapeBoundingBoxWidth;
    case MultiPolygonAlgorithmType.POLYGON_BOUNDING_BOX_LENGTH:
      return calculateShapeBoundingBoxLength;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_THICKNESS:
      return calculateExtrudedShapeThickness;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_VOLUME:
      return calculateExtrudedShapeVolume;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_AREA:
      return calculateShapeArea;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_PERIMETER:
      return calculateShapePerimeter;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_SIDE_AREA:
      return calculateExtrudedShapeSideArea;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_BOUNDING_BOX_WIDTH:
      return calculateShapeBoundingBoxWidth;
    case ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_BOUNDING_BOX_LENGTH:
      return calculateShapeBoundingBoxLength;
    case PolylineAlgorithmType.LINE_LENGTH:
      return calculateLineLength;
    case MultiPolygon2AlgorithmType.POLYGON2_PERIMETER:
      return calculateSheetShapePerimeter;
    case MultiPolygon2AlgorithmType.POLYGON2_AREA:
      return calculateSheetShapeArea;
    case MultiPolygon2AlgorithmType.POLYGON2_FIRE_SECTIONING:
      return (
        multiPolygonInput: MultiPolygon2,
        buffer: number,
        externalShapes: SheetShapeDeepFragment[],
        unitScale: number
      ) =>
        calculateSheetShapeFireSectioning(
          multiPolygonInput,
          buffer,
          externalShapes,
          unitScale
        );
    case MultiPolygon2AlgorithmType.POLYGON2_CORNER_AREA:
      return (
        multiPolygonInput: MultiPolygon2,
        roofHeight: number | undefined,
        unitScale: number
      ) =>
        calculateSheetShapeCornerAreas(
          multiPolygonInput,
          roofHeight,
          unitScale
        );
    case MultiPolygon2AlgorithmType.POLYGON2_RAND_AREA:
      return (
        multiPolygonInput: MultiPolygon2,
        roofHeight: number | undefined,
        unitScale: number
      ) =>
        calculateSheetShapeRandfelt(multiPolygonInput, roofHeight, unitScale);
    case MultiPolygon2AlgorithmType.POLYGON2_GROSS_SIDE_AREA:
      return calculateSheetShapeGrossSideArea;
    case MultiPolygon2AlgorithmType.POLYGON2_BOUNDING_BOX_WIDTH:
      return calculateSheetShapeBoundingBoxWidth;
    case MultiPolygon2AlgorithmType.POLYGON2_BOUNDING_BOX_LENGTH:
      return calculateSheetShapeBoundingBoxLength;
    case Polyline2AlgorithmType.LINE2_LENGTH:
      return calculateLine2Length;
    case Polyline2AlgorithmType.LINE2_CROSS_SECTION_BASELINE_LENGTH:
      return (crossSection: SlopedInsulationCrossSection) =>
        calculateLine2BaselineLength(crossSection);
    case Polyline2AlgorithmType.LINE2_CROSS_SECTION_DIAGONAL_LENGTH:
      return calculateLine2Length;
  }
}
