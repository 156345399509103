import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ResolvedOrder } from './order';
import { OrderDeepFragment } from 'src/gql/graphql';

interface SelectedOrder {
  order: OrderDeepFragment;
  resolvedOrder: ResolvedOrder;
  resolvingOrder: boolean;
  orderError: any;
}

interface SelectedOrderContextValue {
  selectedOrder: SelectedOrder | undefined;
  setSelectedOrder: React.Dispatch<
    React.SetStateAction<SelectedOrder | undefined>
  >;
}

type SelectedOrderContextProviderProps = {
  children: ReactNode;
};

const SelectedOrderContext = createContext<
  SelectedOrderContextValue | undefined
>(undefined);

export const SelectedOrderContextProvider: React.FC<
  SelectedOrderContextProviderProps
> = ({ children }) => {
  // This just stores the selected order in a state value and exposes it.
  const [selectedOrder, setSelectedOrder] = useState<SelectedOrder>();
  return (
    <SelectedOrderContext.Provider value={{ selectedOrder, setSelectedOrder }}>
      {children}
    </SelectedOrderContext.Provider>
  );
};

export const useSelectedOrder = () => {
  const ctx = useContext(SelectedOrderContext);
  if (!ctx) {
    throw new Error(
      'useSelectedOrder hook must be used inside the SelectedOrderContext'
    );
  }
  return ctx;
};
