import { useToken } from '@chakra-ui/react';
import { clamp, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import { useClient, useQuery } from 'urql';

import { ModuleThread, Pool, spawn } from 'threads';
import { useFlag } from '@unleash/proxy-client-react';
import {
  ResolvedOrderEntry,
  resolveDbIds,
  resolveOrdersV2,
} from '../domain/resolve-order-entry';

import {
  ElementIdentifierDbidType,
  GetOrderDeepDocument,
  GetProjectSettingsDocument,
  OrderDeepFragment,
  OrderEntryDeepFragment,
  OrderStatus,
  UnitSettingsType,
} from '../gql/graphql';
import { intersects, merge } from '../utils/dbid-utils';
import { parseLabels } from '../utils/label-utils';
import { UserType, useUserTenant } from '../services/auth-info';
import { usePropertyContext } from './property-resolving';
import { SerializeNHashModule } from 'src/domain/worker/hashing-pool';

export type ResolvedOrder = Omit<
  OrderDeepFragment,
  'orderEntries' | 'labels'
> & {
  orderEntries: ResolvedOrderEntry[];
  labels: { key: string; value: string }[];
};

const MAX_WORKERS = 12;
const POOL_SIZE = clamp(navigator.hardwareConcurrency - 1, 1, MAX_WORKERS);

// Warning: Use with caution. Can be very slow
export function useResolvedOrders(
  projectId: string,
  orders: OrderDeepFragment[]
): {
  resolvedOrders: ResolvedOrder[] | null;
  isLoading: boolean;
  error: Error | null;
} {
  const user = useUserTenant();
  const client = useClient();
  const [resolvedOrders, setResolvedOrders] = useState<ResolvedOrder[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const { propertyContext, isAllPropertiesLoaded, error } =
    usePropertyContext(projectId);

  const [{ data: projectSettingsData }] = useQuery({
    query: GetProjectSettingsDocument,
    variables: {
      projectId,
    },
  });

  const backendCacheEnabled = useFlag('enable-backend-caching');
  // const backendCacheEnabled = false;

  const unitSettings = useMemo(() => {
    return projectSettingsData?.project?.settings
      ?.unitSettings as UnitSettingsType;
  }, [projectSettingsData]);

  const poolRef = useRef<Pool<ModuleThread<SerializeNHashModule>> | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isReadOnly = user.userDetails.userType !== UserType.Contractor;
  useEffect(() => {
    if (debounceTimeoutRef.current && backendCacheEnabled) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = null;
      // console.log('Pool termination cancelled due to rapid remounts');
    }
    const polygonCalculationLimit =
      projectSettingsData?.project?.settings?.polygonCalculationLimit;
    const debouncedResolve = debounce(async function resolve() {
      setIsLoading(true);
      try {
        if (isAllPropertiesLoaded) {
          if (!poolRef.current && backendCacheEnabled) {
            poolRef.current = Pool(
              async () =>
                await spawn<SerializeNHashModule>(
                  new Worker(
                    new URL(
                      '../domain/worker/hashing-worker.ts',
                      import.meta.url
                    ),
                    {
                      type: 'module',
                    }
                  ),
                  {
                    timeout: 100_000,
                  }
                ),
              POOL_SIZE
            );
          }

          const resolvedOrders = await resolveOrdersV2(
            poolRef,
            orders,
            projectId,
            propertyContext,
            client,
            backendCacheEnabled,
            isReadOnly,
            unitSettings,
            polygonCalculationLimit
          );

          setResolvedOrders(resolvedOrders);
        }
      } finally {
        setIsLoading(false);
      }
    }, 100);
    debouncedResolve();

    return () => {
      if (backendCacheEnabled) {
        debounceTimeoutRef.current = setTimeout(() => {
          poolRef.current
            ?.terminate(true)
            // .then(() => console.log('Pool closed successfully'))
            .catch((e) => console.error('Failed to close pool: ', e));
        }, 1000);
      }
    };
  }, [
    isReadOnly,
    isAllPropertiesLoaded,
    orders,
    projectId,
    propertyContext,
    client,
    unitSettings,
    projectSettingsData?.project?.settings?.polygonCalculationLimit,
  ]);

  return { resolvedOrders, isLoading, error: error ?? null };
}

export type OrderEntryWithOrder = {
  orderEntry: OrderEntryDeepFragment;
  order: OrderDeepFragment;
};

export type ResolvedDbIdsReturn = {
  getDbIdsForOrder: (orderId: string) => ElementIdentifierDbidType[];
  findOrdersMatchingDbIds: (
    searchDbIds: ElementIdentifierDbidType[]
  ) => OrderDeepFragment[];
  findOrderEntriesMatchingDbIds: (
    searchDbIds: ElementIdentifierDbidType[]
  ) => OrderEntryWithOrder[];
  isLoading: boolean;
};

export function useResolvedDbIds(
  projectId: string,
  orders: OrderDeepFragment[]
): ResolvedDbIdsReturn {
  const { propertyContext, isAllPropertiesLoaded, error } =
    usePropertyContext(projectId);

  const [resolvedOrders, setResolvedOrders] = useState<
    Map<string, ElementIdentifierDbidType[]>
  >(new Map());

  const [resolvedOrderEntries, setResolvedOrderEntries] = useState<
    Map<string, ElementIdentifierDbidType[]>
  >(new Map());

  const [orderMap, setOrderMap] = useState<Map<string, OrderDeepFragment>>(
    new Map()
  );

  const [orderEntryMap, setOrderEntryMap] = useState<
    Map<string, OrderEntryDeepFragment>
  >(new Map());

  const [orderEntryToOrderMap, setOrderEntryToOrderMap] = useState<
    Map<string, OrderDeepFragment>
  >(new Map());

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function resolve() {
      if (!isLoading) {
        setIsLoading(true);
      }
      if (isAllPropertiesLoaded) {
        const resolvedOrdersResult = new Map<
          string,
          ElementIdentifierDbidType[]
        >();
        const orderEntryToOrderResult = new Map<string, OrderDeepFragment>();
        const resolvedOrderEntriesResult = new Map<
          string,
          ElementIdentifierDbidType[]
        >();
        const orderMapResult = new Map<string, OrderDeepFragment>();
        const orderEntryMapResult = new Map<string, OrderEntryDeepFragment>();

        for (const order of orders) {
          let resolvedOrderResult: ElementIdentifierDbidType[] = [];
          for (const entry of order.orderEntries) {
            const resolvedDbIds =
              (await resolveDbIds(
                propertyContext,
                entry.elementIdentifiersQuery,
                entry.elementIdentifiersDbid
              )) ?? [];
            resolvedOrderEntriesResult.set(entry.id, resolvedDbIds);
            resolvedOrderResult = merge(resolvedOrderResult, resolvedDbIds);
            orderEntryToOrderResult.set(entry.id, order);
            orderEntryMapResult.set(entry.id, entry);
          }
          resolvedOrdersResult.set(order.id, resolvedOrderResult);
          orderMapResult.set(order.id, order);
        }
        setOrderEntryMap(orderEntryMapResult);
        setOrderMap(orderMapResult);
        setOrderEntryToOrderMap(orderEntryToOrderResult);
        setResolvedOrderEntries(resolvedOrderEntriesResult);
        setResolvedOrders(resolvedOrdersResult);
        setIsLoading(false);
      }
    }
    resolve();
  }, [isAllPropertiesLoaded, orders, propertyContext]);

  const findOrdersMatchingDbIds = useCallback(
    (dbIds: ElementIdentifierDbidType[]) => {
      const result: OrderDeepFragment[] = [];
      resolvedOrders.forEach((resolvedDbIds, orderId) => {
        if (intersects(dbIds, resolvedDbIds)) {
          const order = orderMap.get(orderId);
          invariant(order, 'Order not found in map. Bad code');
          result.push(order);
        }
      });
      return result;
    },
    [orderMap, resolvedOrders]
  );

  const findOrderEntriesMatchingDbIds = useCallback(
    (dbIds: ElementIdentifierDbidType[]) => {
      const result: OrderEntryWithOrder[] = [];
      resolvedOrderEntries.forEach((resolvedDbIds, orderEntryId) => {
        if (intersects(dbIds, resolvedDbIds)) {
          const orderEntry = orderEntryMap.get(orderEntryId);
          invariant(orderEntry, 'Order entry not found in map. Bad code');
          const order = orderEntryToOrderMap.get(orderEntryId);
          invariant(order, 'Order entry missing order. Bad code');
          result.push({
            orderEntry,
            order,
          });
        }
      });
      return result;
    },
    [orderEntryMap, orderEntryToOrderMap, resolvedOrderEntries]
  );

  const getDbIdsForOrder = useCallback(
    (orderId: string) => {
      const dbIds = resolvedOrders.get(orderId);
      if (!dbIds) {
        throw 'Order not found in map, call useResolvedOrders with the correct orders';
      }
      return dbIds;
    },
    [resolvedOrders]
  );

  return useMemo(
    () => ({
      isLoading,
      error,
      findOrdersMatchingDbIds,
      findOrderEntriesMatchingDbIds,
      getDbIdsForOrder,
    }),
    [
      isLoading,
      error,
      findOrdersMatchingDbIds,
      findOrderEntriesMatchingDbIds,
      getDbIdsForOrder,
    ]
  );
}

export type UseOrderReturn = {
  order: OrderDeepFragment | null;
  resolvedOrder: ResolvedOrder | null;
  resolving: boolean;
  loading: boolean;
  error: object | undefined;
};

export const useResolvedOrder = (
  projectId: string,
  orderId: string
): UseOrderReturn => {
  const [resolving, setResolving] = useState(false);
  // TODO: jonasws: 2022-03-03 Add a loading spinner here?
  const [resolvedOrder, setResolvedOrder] = useState<ResolvedOrder | null>(
    null
  );
  const user = useUserTenant();
  const client = useClient();

  const isReadOnly = user.userDetails.userType !== UserType.Contractor;
  const backendCacheEnabled = useFlag('enable-backend-caching');

  const [{ data: orderData, fetching, error: orderError }] = useQuery({
    query: GetOrderDeepDocument,
    variables: {
      id: orderId,
    },
  });

  const [{ data: projectSettingsData }] = useQuery({
    query: GetProjectSettingsDocument,
    variables: {
      projectId,
    },
  });

  const unitSettings = projectSettingsData?.project?.settings
    ?.unitSettings as UnitSettingsType;

  const { propertyContext, isAllPropertiesLoaded, error } =
    usePropertyContext(projectId);

  if (error) {
    throw 'Error fetching properties';
  }

  const poolRef = useRef<Pool<ModuleThread<SerializeNHashModule>> | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current && backendCacheEnabled) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = null;
      // console.log('Pool termination cancelled due to rapid remounts');
    }
    const resolve = async () => {
      const polygonCalculationLimit =
        projectSettingsData?.project?.settings?.polygonCalculationLimit;
      if (orderData?.order && isAllPropertiesLoaded) {
        if (orderData.order.isAggregatedOrder) {
          setResolvedOrder({
            ...orderData.order,
            labels: parseLabels(
              orderData.order.labels
            ) as ResolvedOrder['labels'],
            orderEntries: [],
          });
          return;
        }

        setResolving(true);

        try {
          if (!poolRef.current && backendCacheEnabled) {
            poolRef.current = Pool(
              async () =>
                await spawn<SerializeNHashModule>(
                  new Worker(
                    new URL(
                      '../domain/worker/hashing-worker.ts',
                      import.meta.url
                    ),
                    {
                      type: 'module',
                    }
                  ),
                  {
                    timeout: 100_000,
                  }
                ),
              POOL_SIZE
            );
          }

          const [resOrder] = await resolveOrdersV2(
            poolRef,
            [orderData.order],
            projectId,
            propertyContext,
            client,
            backendCacheEnabled,
            isReadOnly,
            unitSettings,
            polygonCalculationLimit
          );

          setResolvedOrder(resOrder);
        } catch (e) {
          console.error('An error occured during resolving', e);
          throw e;
        } finally {
          setResolving(false);
        }
      }
    };

    resolve();
    return () => {
      if (backendCacheEnabled) {
        debounceTimeoutRef.current = setTimeout(() => {
          poolRef.current
            ?.terminate(true)
            // .then(() => console.log('Pool closed successfully'))
            .catch((e) => console.error('Failed to close pool: ', e));
        }, 1000);
      }
    };
  }, [
    isReadOnly,
    propertyContext,
    orderData?.order,
    isAllPropertiesLoaded,
    unitSettings,
    projectId,
    user?.isProtan,
    projectSettingsData?.project?.settings?.polygonCalculationLimit,
  ]);

  return {
    order: orderData?.order ?? null,
    resolvedOrder: resolvedOrder,
    loading: fetching,
    // This is to only make it true when we're "updating" an already resolved order, and not on the "first page load"
    // resolving: resolving && resolvedOrder !== null,
    resolving,
    error: orderError,
  };
};

export function useOrderStatusColor() {
  const [gray100, openColor, inProgressColor, completedColor] = useToken(
    'colors',
    [
      'gray.100',
      orderStatusColorMapper(OrderStatus.Open),
      orderStatusColorMapper(OrderStatus.InProgress),
      orderStatusColorMapper(OrderStatus.Completed),
    ]
  );

  function orderStatusHexMapper(orderStatus: OrderStatus) {
    switch (orderStatus) {
      case OrderStatus.Open:
        return openColor;
      case OrderStatus.InProgress:
        return inProgressColor;
      case OrderStatus.Completed:
        return completedColor;
      default:
        return gray100;
    }
  }

  return { orderStatusHexMapper };
}

export function orderStatusColorMapper(orderStatus: OrderStatus): string {
  switch (orderStatus) {
    case OrderStatus.Open:
      return 'orange.200';
    case OrderStatus.InProgress:
      return 'cyan.400';
    case OrderStatus.Completed:
      return 'green.300';
    default:
      return 'gray.100';
  }
}

export function orderStatusLabelMapper(
  orderStatus: OrderStatus,
  translate: (key: string) => string
): string {
  const statusString = orderStatus.toLowerCase().replace('_', '-');
  return translate(`project:table-status.${statusString}`);
}
