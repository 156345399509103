import { PropsWithChildren, useEffect, useMemo } from 'react';
import { initializeAutodeskViewing } from 'src/components/viewers/common/utils/autodesk';
import { useActiveModels } from 'src/hooks/active-models';
import { useForgeAccessToken } from 'src/hooks/forge-auth';

export type Props = PropsWithChildren<{
  projectId: string;
}>;

export function AutodeskViewing({ projectId, children }: Props) {
  const { activeModels } = useActiveModels(projectId);
  const activeModelsMemo = useMemo(() => activeModels ?? [], [activeModels]);
  const { getAccessToken } = useForgeAccessToken(projectId, activeModelsMemo);

  useEffect(() => {
    initializeAutodeskViewing({ getAccessToken }).then(() =>
      console.info('Initialized viewing')
    );
  }, [getAccessToken, projectId]);

  return <>{children}</>;
}
