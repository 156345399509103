import { Substrate } from '../../ProductChoices';
import { PreAssembledFastenerProduct } from './hooks/pre-assembled-fastener-slices';

export const PREASSEMBLED_FASTENER_PRODUCTS_STEEL_AND_TIMBER: PreAssembledFastenerProduct[] =
  [
    // For Steel and Timber substrates
    {
      name: 'Protan EcotekT/JT2 ST2 65/60 Hylse m/pigg 65mm/JT2 ST2 6,0x60mm. Isolasjon 90-100',
      sku: '39003076',
      dimensions: {
        minInsulationDepth: 0.09,
        maxInsulationDepth: 0.1,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 65/80 Hylse m/pigg 65mm/JT2 ST2 6,0x80mm. Isolasjon 110-120',
      sku: '39003077',
      dimensions: {
        minInsulationDepth: 0.11,
        maxInsulationDepth: 0.12,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 85/100 Hylse m/pigg 85mm/JT2 ST2 6,0x100mm. Isolasjon 130-140',
      sku: '39003078',
      dimensions: {
        minInsulationDepth: 0.13,
        maxInsulationDepth: 0.14,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 105/80 Hylse m/pigg 105mm/JT2 ST2 6,0x80mm. Isolasjon 150-160',
      sku: '39003079',
      dimensions: {
        minInsulationDepth: 0.15,
        maxInsulationDepth: 0.16,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 105/100 Hylse m/pigg 105mm/JT2 ST2 6,0x100mm. Isolasjon 170',
      sku: '39003080',
      dimensions: {
        minInsulationDepth: 0.17,
        maxInsulationDepth: 0.17,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 135/80 Hylse m/pigg 135mm/JT2 ST2 6,0x80mm. Isolasjon 180-190',
      sku: '39003081',
      dimensions: {
        minInsulationDepth: 0.18,
        maxInsulationDepth: 0.19,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 135/100 Hylse m/pigg 135mm/JT2 ST2 6,0x100mm. Isolasjon 200',
      sku: '39003082',
      dimensions: {
        minInsulationDepth: 0.2,
        maxInsulationDepth: 0.2,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 165/80 Hylse m/pigg 165mm/JT2 ST2 6,0x80mm. Isolasjon 210-220',
      sku: '39003083',
      dimensions: {
        minInsulationDepth: 0.21,
        maxInsulationDepth: 0.22,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 165/100 Hylse m/pigg 165mm/JT2 ST2 6,0x100mm. Isolasjon 230',
      sku: '39003084',
      dimensions: {
        minInsulationDepth: 0.23,
        maxInsulationDepth: 0.23,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 195/80 Hylse m/pigg 195mm/JT2 ST2 6,0x80mm. Isolasjon 240-250',
      sku: '39003085',
      dimensions: {
        minInsulationDepth: 0.24,
        maxInsulationDepth: 0.25,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 195/100 Hylse m/pigg 195mm/JT2 ST2 6,0x100mm. Isolasjon 260',
      sku: '39003086',
      dimensions: {
        minInsulationDepth: 0.26,
        maxInsulationDepth: 0.26,
      },
    },
    {
      name: 'Protan Ecotk-T/JT2 250-225/60 Hylse 225 mm Skrue 6,0 x 60 mm',
      sku: '39003087',
      dimensions: {
        minInsulationDepth: 0.25,
        maxInsulationDepth: 0.25,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/80 Hylse m/pigg 225mm/JT2 ST2 6,0x80mm. Isolasjon 270-280',
      sku: '39003088',
      dimensions: {
        minInsulationDepth: 0.27,
        maxInsulationDepth: 0.28,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/100 Hylse m/pigg 225mm/JT2 ST2 6,0x100mm. Isolasjon 290-300',
      sku: '39003089',
      dimensions: {
        minInsulationDepth: 0.29,
        maxInsulationDepth: 0.3,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/120 Hylse m/pigg 225mm/JT2 ST2 6,0x120mm. Isolasjon 310-320',
      sku: '39003090',
      dimensions: {
        minInsulationDepth: 0.31,
        maxInsulationDepth: 0.32,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/160 Hylse m/pigg 225mm/JT2 ST2 6,0x160mm. Isolasjon 330-340',
      sku: '39003091',
      dimensions: {
        minInsulationDepth: 0.33,
        maxInsulationDepth: 0.34,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/180 Hylse m/pigg 225mm/JT2 ST2 6,0x180mm. Isolasjon 350-360',
      sku: '39003092',
      dimensions: {
        minInsulationDepth: 0.35,
        maxInsulationDepth: 0.36,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/180 Hylse m/pigg 225mm/JT2 ST2 6,0x180mm. Isolasjon 370-380',
      sku: '39003093',
      dimensions: {
        minInsulationDepth: 0.37,
        maxInsulationDepth: 0.38,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/200 Hylse m/pigg 225mm/JT2 ST2 6,0x200mm. Isolasjon 390-400',
      sku: '39003094',
      dimensions: {
        minInsulationDepth: 0.39,
        maxInsulationDepth: 0.4,
      },
    },
  ];

export const PREASSEMBLED_FASTENER_PRODUCTS_CONCRETE: PreAssembledFastenerProduct[] =
  [
    // For Concrete substrate
    {
      name: 'JBS-R/EcoTek-70-100-65/80 Hylse 65mm/JBS-R 7,5x80mm, isolasjon 70-100',
      sku: '39003137',
      dimensions: {
        minInsulationDepth: 0.07,
        maxInsulationDepth: 0.1,
      },
    },
    {
      name: 'JBS-R/EcoTek-90-120-85/80 Hylse 85mm/JBS-R 7,5x80mm, isolasjon 90-120',
      sku: '39003138',
      dimensions: {
        minInsulationDepth: 0.09,
        maxInsulationDepth: 0.12,
      },
    },
    {
      name: 'JBS-R/EcoTek-110-180-105/120 Hylse 105mm/JBS-R 7,5x120mm, isolasjon 110-180',
      sku: '39003139',
      dimensions: {
        minInsulationDepth: 0.11,
        maxInsulationDepth: 0.18,
      },
    },
    {
      name: 'JBS-R/EcoTek-170-270-165/150 Hylse 165mm/JBS-R 7,5x150mm, isolasjon 170-270',
      sku: '39003140',
      dimensions: {
        minInsulationDepth: 0.17,
        maxInsulationDepth: 0.27,
      },
    },
    {
      name: 'JBS-R/EcoTek-230-390-225/210 Hylse 225mm/JBS-R 7,5x210mm, isolasjon 230-390',
      sku: '39003141',
      dimensions: {
        minInsulationDepth: 0.23,
        maxInsulationDepth: 0.39,
      },
    },
    {
      name: 'JBS-R/EcoTek-340-500-335/210 Hylse 335mm/JBS-R 7,5x210mm, isolasjon 340-500',
      sku: '39003142',
      dimensions: {
        minInsulationDepth: 0.34,
        maxInsulationDepth: 0.5,
      },
    },
    {
      name: 'JBS-R/EcoTek-340-590-335/300 Hylse 335mm/JBS-R 7,5x300mm, isolasjon 340-590',
      sku: '39003143',
      dimensions: {
        minInsulationDepth: 0.34,
        maxInsulationDepth: 0.59,
      },
    },
  ];

export function getPreAssembledFastenerProductForInsulationDepthAndSubstrate(
  insulationDepth: number,
  substrate: Substrate
) {
  const products =
    substrate === Substrate.Concrete
      ? PREASSEMBLED_FASTENER_PRODUCTS_CONCRETE
      : PREASSEMBLED_FASTENER_PRODUCTS_STEEL_AND_TIMBER;

  if (!products.length) return null;

  // First try to find an exact match
  const exactMatch = products.find(
    (product) =>
      insulationDepth >= product.dimensions.minInsulationDepth &&
      insulationDepth <= product.dimensions.maxInsulationDepth
  );

  if (exactMatch) return exactMatch;

  // Find the product with the highest maximum depth
  const maxAvailableProduct = products.reduce(
    (max, product) =>
      product.dimensions.maxInsulationDepth > max.dimensions.maxInsulationDepth
        ? product
        : max,
    products[0]
  );

  // If the required depth is higher than the maximum available, return null
  if (insulationDepth > maxAvailableProduct.dimensions.maxInsulationDepth) {
    console.warn(
      `Insulation depth ${insulationDepth}mm exceeds maximum available fastener depth ${maxAvailableProduct.dimensions.maxInsulationDepth}mm`
    );
    return null;
  }

  // If we're between sizes, find the nearest smaller product
  const nearestSmaller = products
    .filter(
      (product) => product.dimensions.maxInsulationDepth <= insulationDepth
    )
    .sort(
      (a, b) =>
        b.dimensions.maxInsulationDepth - a.dimensions.maxInsulationDepth
    )[0];

  if (nearestSmaller) {
    return nearestSmaller;
  }

  console.warn(`No suitable fastener found for depth ${insulationDepth}mm`);
  return null;
}

function getPreAssembledFastenerProductsForSubstrate(
  substrate: Substrate
): PreAssembledFastenerProduct[] {
  if (substrate === Substrate.Steel || substrate === Substrate.Wood) {
    return PREASSEMBLED_FASTENER_PRODUCTS_STEEL_AND_TIMBER;
  }
  return PREASSEMBLED_FASTENER_PRODUCTS_CONCRETE;
}
