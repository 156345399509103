import { useEffect, useMemo, useState } from 'react';
import { DbIdsPerModel } from 'src/components/common/ForgeViewer';
import { isShapeHidden } from 'src/components/SheetShapes';
import { SelectedDbIds } from 'src/components/viewers/common/hooks/useSelectedDbIds';
import { useSheetShapesManager } from 'src/components/viewers/common/hooks/useSheetShapesManager';
import { useSheetsVisibility } from 'src/components/viewers/common/hooks/useSheetsVisibilityManager';
import { ViewerExtension } from 'src/components/viewers/common/utils/autodesk';
import { Edit2DSelectTool } from 'src/components/viewers/SheetsViewerV2/autodesk/tools/Edit2DSelectTool';
import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { ActiveSheet } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { ISheetShape } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import { PointShape } from 'src/components/viewers/SheetsViewerV2/shapes/PointShape';
import { PolygonShape } from 'src/components/viewers/SheetsViewerV2/shapes/PolygonShape';
import { PolylineShape } from 'src/components/viewers/SheetsViewerV2/shapes/PolylineShape';
import {
  clearTextLabels,
  upsertTextLabel,
} from 'src/components/viewers/SheetsViewerV2/utils/labels';
import Edit2D = Autodesk.Extensions.Edit2D;
import GuiViewer3D = Autodesk.Viewing.GuiViewer3D;
import Viewer3D = Autodesk.Viewing.Viewer3D;

export function useShapes(
  viewer: Viewer3D | GuiViewer3D | null | undefined,
  activeTool: ActiveTool,
  activeSheet: ActiveSheet,
  selectedDbIds: SelectedDbIds,
  visibilityManager: ReturnType<typeof useSheetsVisibility>
) {
  const labelsDivId = 'shape-labels';
  const [edit2D, setEdit2D] = useState<Autodesk.Extensions.Edit2D | null>(null);
  const activeSheetData = activeSheet.data;
  const shapesManager = useSheetShapesManager();
  const shapesToRender = useMemo(() => {
    if (!viewer || !edit2D || !activeSheetData) return null;

    return Object.values(shapesManager.renderedSheetShapes)
      .flatMap((shapes) => shapes)
      .filter(
        (shape) =>
          !isShapeHidden(
            visibilityManager.hiddenElements,
            shape.urn,
            shape,
            visibilityManager.hiddenSheetShapes,
            visibilityManager.isolatedElements,
            false
          )
      )
      .map((shape) => {
        if (shape.sheetShapePolygon) {
          return new PolygonShape(viewer, edit2D, activeSheetData, shape);
        } else if (shape.sheetShapeLine) {
          return new PolylineShape(viewer, edit2D, activeSheetData, shape);
        } else {
          return new PointShape(viewer, edit2D, activeSheetData, shape);
        }
      })
      .filter(Boolean);
  }, [
    viewer,
    edit2D,
    activeSheetData,
    shapesManager.renderedSheetShapes,
    visibilityManager.hiddenElements,
    visibilityManager.hiddenSheetShapes,
    visibilityManager.isolatedElements,
  ]);

  useEffect(() => {
    if (!viewer) return;

    const edit2D = viewer.getExtension(ViewerExtension.Edit2D) as Edit2D;

    if (!edit2D.defaultContext) {
      edit2D.registerDefaultTools();
    }

    setEdit2D(edit2D);
  }, [viewer]);

  useEffect(() => {
    if (!viewer || !edit2D?.defaultContext || !shapesToRender) return;

    const clearShapes = () => {
      clearTextLabels(viewer, labelsDivId);
      shapesToRender.map((instance) => instance.remove());
    };
    const updateTextLabelsCallback = () => {
      shapesToRender.forEach((model) => {
        model.getLabels().forEach((label, index) => {
          upsertTextLabel(viewer, labelsDivId, {
            ...label,
            urn: `${model.urn}-${model?.dbId}-${index}`,
          });
        });
      });
    };

    shapesToRender.forEach((model) => {
      const selectedDbIdsArray = model
        ? selectedDbIds.selectedDbIds[model.urn]
        : undefined;
      if (
        model &&
        Array.isArray(selectedDbIdsArray) &&
        selectedDbIdsArray.includes(model.dbId)
      ) {
        model.setSelected(true);
      } else if (model) {
        model.setSelected(false);
      }
    });

    updateTextLabelsCallback();

    viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      updateTextLabelsCallback
    );
    viewer.addEventListener(Autodesk.Viewing.MODEL_REMOVED_EVENT, clearShapes);

    return () => {
      clearShapes();
      viewer.removeEventListener(
        Autodesk.Viewing.MODEL_REMOVED_EVENT,
        clearShapes
      );
      viewer.removeEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        updateTextLabelsCallback
      );
    };
  }, [
    activeSheet.data,
    selectedDbIds.selectedDbIds,
    edit2D?.defaultContext,
    shapesToRender,
    viewer,
  ]);

  useEffect(() => {
    if (
      !viewer ||
      !edit2D?.defaultContext ||
      !shapesToRender ||
      !activeSheetData
    ) {
      return;
    } else if (activeTool !== ActiveTool.Select) {
      return; // omit registering and activating selecting tool
    }

    const changeSelectedShapes = (selectedShapes: ISheetShape[]) => {
      selectedDbIds.setSelectedDbIds(
        selectedShapes.reduce(
          (acc, shape) => ({
            ...acc,
            [shape.urn]: [...(acc[shape.urn] || []), shape.dbId],
          }),
          {} as DbIdsPerModel
        )
      );
    };
    const selectTool = new Edit2DSelectTool(
      viewer,
      shapesToRender,
      changeSelectedShapes
    );

    viewer.toolController.registerTool(selectTool);
    viewer.toolController.activateTool(selectTool.getName());

    return () => {
      viewer.toolController.deactivateTool(selectTool.getName());
      viewer.toolController.deregisterTool(selectTool);
    };
  }, [
    activeTool,
    activeSheetData,
    selectedDbIds.setSelectedDbIds,
    edit2D,
    shapesToRender,
    viewer,
  ]);

  return { shapesManager };
}
