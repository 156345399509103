import { unit } from 'mathjs';
import { GeometricProperty } from '../domain/geometry/geometric-types';
import { QuantityWithUnits } from '../domain/property-types';
import {
  AreaUnitTypeEnum,
  LengthUnitTypeEnum,
  RoundingEnum,
  Unit,
  UnitSettingsType,
  VolumeUnitTypeEnum,
} from '../gql/graphql';

export const unitToUnitUnicodeMap = new Map<string, string>([
  ['PCS', 'pcs'],
  ['M', 'm'],
  ['DM', 'dm'],
  ['CM', 'cm'],
  ['MM', 'mm'],
  ['FT', 'ft'],
  ['IN', 'in'],
  ['M2', 'm\u00B2'],
  ['DM2', 'dm\u00B2'],
  ['CM2', 'cm\u00B2'],
  ['MM2', 'mm\u00B2'],
  ['FT2', 'ft\u00B2'],
  ['IN2', 'in\u00B2'],
  ['M3', 'm\u00B3'],
  ['DM3', 'dm\u00B3'],
  ['CM3', 'cm\u00B3'],
  ['MM3', 'mm\u00B3'],
  ['FT3', 'ft\u00B3'],
  ['IN3', 'in\u00B3'],
  ['KG', 'kg'],
  ['AUTODESK.UNIT.UNIT:MILLIMETERS-1.0.1', 'mm'],
  ['AUTODESK.UNIT.UNIT:METERS-1.0.1', 'm'],
  ['AUTODESK.UNIT.UNIT:SQUAREMETERS-1.0.1', 'm\u00B2'],
  ['AUTODESK.UNIT.UNIT:CUBICMETERS-1.0.1', 'm\u00B3'],
  ['AUTODESK.UNIT.UNIT:SQUAREMETERKELVINSPERWATT-1.0.1', 'm\u00B2K/W'],
  ['AUTODESK.UNIT.UNIT:KILOJOULESPERKELVIN-1.0.1', 'kJ/K'],
  ['AUTODESK.UNIT.UNIT:WATTSPERSQUAREMETERKELVIN-1.0.1', 'W/(m\u00B2K)'],
]);

export const unicodeToHatNotation = (unit: string): string => {
  return unit.replace('\u00B2', '^2').replace('\u00B3', '^3');
};

export const formatUnit = (unit: string | null | undefined): string => {
  if (!unit) {
    return '';
  }
  return unitToUnitUnicodeMap.get(unit.toUpperCase().replace('^', '')) ?? unit;
};

export const roundingToPrecision = (rounding: RoundingEnum): number => {
  switch (rounding) {
    case RoundingEnum['2Decimals']:
      return 2;
    case RoundingEnum['1Decimals']:
      return 1;
    case RoundingEnum['0Decimals']:
      return 0;
    case RoundingEnum.Nearest_10:
      return -1;
    case RoundingEnum.Nearest_100:
      return -2;
    case RoundingEnum.Nearest_1000:
      return -3;
    default:
      return 2;
  }
};

export enum UnitType {
  Length = 'length',
  Area = 'area',
  Volume = 'volume',
}

// eslint-disable-next-line complexity
export const unitToUnitType = (unit: string): UnitType | null => {
  switch (unit) {
    case 'm':
    case 'cm':
    case 'mm':
    case 'ft':
    case 'in':
      return UnitType.Length;
    case 'm^2':
    case 'cm^2':
    case 'mm^2':
    case 'ft^2':
    case 'in^2':
      return UnitType.Area;
    case 'm^3':
    case 'cm^3':
    case 'mm^3':
    case 'ft^3':
    case 'in^3':
      return UnitType.Volume;
    default:
      return null;
  }
};

// eslint-disable-next-line complexity
export const convertGeometricPropertyToUnit = (
  geometricProperty: GeometricProperty
): Unit => {
  switch (geometricProperty) {
    case GeometricProperty.BOTTOM_SURFACE_AREA:
      return Unit.M2;
    case GeometricProperty.LARGEST_SIDE_AREA:
      return Unit.M2;
    case GeometricProperty.GROSS_SIDE_AREA:
      return Unit.M2;
    case GeometricProperty.TOP_SURFACE_AREA:
      return Unit.M2;
    case GeometricProperty.TOTAL_SURFACE_AREA:
      return Unit.M2;
    case GeometricProperty.EXTRUDED_SIDE_AREA:
      return Unit.M2;
    case GeometricProperty.VOLUME:
      return Unit.M3;
    case GeometricProperty.HEIGHT:
      return Unit.M;
    case GeometricProperty.LENGTH:
      return Unit.M;
    case GeometricProperty.WIDTH:
      return Unit.M;
    case GeometricProperty.CIRCUMFERENCE:
      return Unit.M;
    case GeometricProperty.THICKNESS:
      return Unit.M;
    case GeometricProperty.DIAMETER:
      return Unit.M;
    default:
      return Unit.Pcs;
  }
};

// eslint-disable-next-line complexity
export const UnitTypeToUnitSymbol = (
  unitType: LengthUnitTypeEnum | AreaUnitTypeEnum | VolumeUnitTypeEnum
): string => {
  switch (unitType) {
    case LengthUnitTypeEnum.Meters:
      return 'm';
    case LengthUnitTypeEnum.Decimeters:
      return 'dm';
    case LengthUnitTypeEnum.Centimeters:
      return 'cm';
    case LengthUnitTypeEnum.Millimeters:
      return 'mm';
    case LengthUnitTypeEnum.DecimalFeet:
      return 'ft';
    case LengthUnitTypeEnum.DecimalInches:
      return 'in';
    case AreaUnitTypeEnum.MetersSquared:
      return 'm^2';
    case AreaUnitTypeEnum.DecimetersSquared:
      return 'dm^2';
    case AreaUnitTypeEnum.CentimetersSquared:
      return 'cm^2';
    case AreaUnitTypeEnum.MillimetersSquared:
      return 'mm^2';
    case AreaUnitTypeEnum.DecimalFeetSquared:
      return 'ft^2';
    case AreaUnitTypeEnum.DecimalInchesSquared:
      return 'in^2';
    case VolumeUnitTypeEnum.MetersCubed:
      return 'm^3';
    case VolumeUnitTypeEnum.DecimetersCubed:
      return 'dm^3';
    case VolumeUnitTypeEnum.CentimetersCubed:
      return 'cm^3';
    case VolumeUnitTypeEnum.MillimetersCubed:
      return 'mm^3';
    case VolumeUnitTypeEnum.DecimalFeetCubed:
      return 'ft^3';
    case VolumeUnitTypeEnum.DecimalInchesCubed:
      return 'in^3';
    default:
      return '';
  }
};

// eslint-disable-next-line complexity
export const UnitTypeToUnit = (
  unitType: LengthUnitTypeEnum | AreaUnitTypeEnum | VolumeUnitTypeEnum
): Unit => {
  switch (unitType) {
    case LengthUnitTypeEnum.Meters:
      return Unit.M;
    case LengthUnitTypeEnum.Decimeters:
      return Unit.Dm;
    case LengthUnitTypeEnum.Centimeters:
      return Unit.Cm;
    case LengthUnitTypeEnum.Millimeters:
      return Unit.Mm;
    case LengthUnitTypeEnum.DecimalFeet:
      return Unit.Ft;
    case LengthUnitTypeEnum.DecimalInches:
      return Unit.In;
    case AreaUnitTypeEnum.MetersSquared:
      return Unit.M2;
    case AreaUnitTypeEnum.DecimetersSquared:
      return Unit.Dm2;
    case AreaUnitTypeEnum.CentimetersSquared:
      return Unit.Cm2;
    case AreaUnitTypeEnum.MillimetersSquared:
      return Unit.Mm2;
    case AreaUnitTypeEnum.DecimalFeetSquared:
      return Unit.Ft2;
    case AreaUnitTypeEnum.DecimalInchesSquared:
      return Unit.In2;
    case VolumeUnitTypeEnum.MetersCubed:
      return Unit.M3;
    case VolumeUnitTypeEnum.DecimetersCubed:
      return Unit.Dm3;
    case VolumeUnitTypeEnum.CentimetersCubed:
      return Unit.Cm3;
    case VolumeUnitTypeEnum.MillimetersCubed:
      return Unit.Mm3;
    case VolumeUnitTypeEnum.DecimalFeetCubed:
      return Unit.Ft3;
    case VolumeUnitTypeEnum.DecimalInchesCubed:
      return Unit.In3;
    default:
      return Unit.Pcs;
  }
};

// eslint-disable-next-line complexity
export const convertSIQuantity = (
  SIQuantity: QuantityWithUnits,
  unitSettings: UnitSettingsType
): QuantityWithUnits => {
  if (
    !SIQuantity.units ||
    !SIQuantity.value ||
    typeof SIQuantity.value !== 'number'
  ) {
    return SIQuantity;
  }
  const { units, value } = SIQuantity;

  let newUnits:
    | LengthUnitTypeEnum
    | AreaUnitTypeEnum
    | VolumeUnitTypeEnum
    | undefined = undefined;

  if (units === 'm' && unitSettings.length && unitSettings.length.units) {
    newUnits = unitSettings.length?.units;
  } else if (units === 'm^2' && unitSettings.area && unitSettings.area.units) {
    newUnits = unitSettings.area?.units;
  } else if (
    units === 'm^3' &&
    unitSettings.volume &&
    unitSettings.volume.units
  ) {
    newUnits = unitSettings.volume?.units;
  }

  if (!newUnits) {
    return SIQuantity;
  }

  const newUnitSymbol = UnitTypeToUnitSymbol(newUnits);
  const newQuantity = unit(value, units).to(newUnitSymbol);

  return {
    value: newQuantity.toNumeric(),
    units: newQuantity.formatUnits(),
  } as QuantityWithUnits;
};

export const convertQuantityToSIUnits = (
  quantity: QuantityWithUnits
): QuantityWithUnits => {
  const { units, value } = quantity;
  if (!units || !value) {
    return quantity;
  }
  try {
    const formattedUnits = unicodeToHatNotation(formatUnit(units));
    const convertNumericValueToSI = (value: number): number =>
      unit(value, formattedUnits).toSI().value;

    const convertStringValueToSI = (value: string): number =>
      convertNumericValueToSI(parseFloat(value));

    const convertValueToSI = (value: number | string): number =>
      typeof value === 'number'
        ? convertNumericValueToSI(value)
        : convertStringValueToSI(value);

    const convertUnitsToSI = (units: string): string =>
      unit(units).toSI().formatUnits();

    if (Array.isArray(value)) {
      return {
        value: value.map((elementValue) => convertValueToSI(elementValue)),
        units: unit(formattedUnits).toSI().formatUnits(),
      };
    } else {
      return {
        value: convertValueToSI(value),
        units: convertUnitsToSI(formattedUnits),
      };
    }
  } catch (e) {
    // unit conversion failed, return original quantity
    return quantity;
  }
};

export const parseUnit = (unit: string): Unit | null => {
  const normalized = unit.replaceAll('^', '').toUpperCase();
  if (Object.values(Unit).includes(normalized as Unit)) {
    return normalized as Unit;
  }
  return null;
};
