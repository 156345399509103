import { getCalibrationDistance } from '../../utils/calibration';
import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { useCalibration } from 'src/components/viewers/SheetsViewerV2/hooks/useCalibration';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import {
  CalibrationDefaultLineStyle,
  CalibrationDrawingLineStyle,
  CROSSHAIR_CURSOR,
  MEASUREMENT_TOOL_OVERLAY_ID,
  MEASUREMENT_TOOL_SNAPPING_ANGLE_STEP,
  VIEWER_TOP_OFFSET,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';
import {
  angleBetweenTwoPoints,
  endPointByAngle,
  snapAngle,
} from 'src/components/viewers/SheetsViewerV2/utils/cartesian';
import {
  drawCalibrationLine,
  LineStyle,
} from 'src/components/viewers/SheetsViewerV2/utils/drawing';
import {
  clearTextLabels,
  upsertTextLabel,
} from 'src/components/viewers/SheetsViewerV2/utils/labels';

export class MeasurementTool extends Autodesk.Viewing.ToolInterface {
  names = [ActiveTool.Measurement];
  labelsDivId = 'measurements-label';

  public pointA: THREE.Vector3 | null = null;
  public pointB: THREE.Vector3 | null = null;
  public dynamicPointB: THREE.Vector3 | null = null;

  constructor(
    private readonly calibration: ReturnType<typeof useCalibration>['existing'],
    private readonly viewer: AggregatedView['viewer'],
    private readonly activeSheetData: ActiveSheetData
  ) {
    super();

    delete (this as any).handleSingleClick;
    delete (this as any).handleMouseMove;
    delete (this as any).deactivate;
    delete (this as any).activate;
  }

  getCursor(): string {
    return CROSSHAIR_CURSOR;
  }

  activate(name: string, viewerApi?: Autodesk.Viewing.GuiViewer3D) {
    const callback = () => {
      this.clear(viewerApi);
      viewerApi?.removeEventListener(
        Autodesk.Viewing.MODEL_UNLOADED_EVENT,
        callback
      );
    };

    viewerApi?.addEventListener(
      Autodesk.Viewing.MODEL_UNLOADED_EVENT,
      callback
    );
  }

  clear(viewer = this.viewer) {
    clearTextLabels(viewer, this.labelsDivId);
    this.pointA = null;
    this.pointB = null;
    this.dynamicPointB = null;
    viewer.impl.clearOverlay(MEASUREMENT_TOOL_OVERLAY_ID);
    viewer.impl.invalidate(true, true, true);
  }

  deactivate() {
    this.clear();
  }

  handleSingleClick(event: MouseEvent, button: number): boolean {
    const clientX = event.clientX;
    const clientY = event.clientY - VIEWER_TOP_OFFSET;
    const cursorPosition = this.viewer.impl.intersectGround(clientX, clientY);

    if (!cursorPosition) return false;
    if (button === 2) return this._handleRightClick();
    if (button !== 0) return false;

    if (!this.pointA || this.pointB) {
      clearTextLabels(this.viewer, this.labelsDivId);
      this.pointA = cursorPosition;
      this.pointB = null;
    } else if (this.pointA && !this.pointB) {
      this.pointB = this._calculateEndPoint(cursorPosition, event.shiftKey);
      this._drawLine(
        this.viewer,
        this.pointA,
        this.pointB,
        CalibrationDefaultLineStyle
      );
    }

    return true;
  }

  handleMouseMove(event: MouseEvent): boolean {
    const clientX = event.clientX;
    const clientY = event.clientY - VIEWER_TOP_OFFSET;
    const cursorPosition = this.viewer.impl.intersectGround(clientX, clientY);

    if (!this.pointB && this.pointA && cursorPosition) {
      this.dynamicPointB = this._calculateEndPoint(
        cursorPosition,
        event.shiftKey
      );
      this._drawLine(
        this.viewer,
        this.pointA,
        this.dynamicPointB,
        CalibrationDrawingLineStyle
      );
    }

    return false; // When set to true, smooth panning is disabled.
  }

  drawLabel(viewer: AggregatedView['viewer']) {
    const label = this._getLabel(viewer);
    if (label) {
      upsertTextLabel(viewer, this.labelsDivId, { ...label, urn: '1' });
    }
  }

  private _handleRightClick() {
    if (this.pointB) {
      this.pointB = null;
      return true;
    } else if (this.pointA) {
      this.pointA = null;
      this.pointB = null;
      this.clear();
      return true;
    }

    return false;
  }

  private _calculateEndPoint(cursorPosition: THREE.Vector3, shiftKey: boolean) {
    if (!this.pointA) {
      throw new Error('Cannot calculate end point without start point');
    }

    const angle = angleBetweenTwoPoints([this.pointA, cursorPosition]);
    const snappedAngle = snapAngle(angle, MEASUREMENT_TOOL_SNAPPING_ANGLE_STEP);

    if (shiftKey) {
      return endPointByAngle(
        this.pointA,
        snappedAngle,
        this.pointA.distanceTo(cursorPosition)
      );
    } else {
      return cursorPosition;
    }
  }

  private _getLabel(viewer: AggregatedView['viewer']) {
    const { pointA, pointB: staticPointB, dynamicPointB } = this;
    const pointB = staticPointB || dynamicPointB;

    if (!pointA || !pointB || !this.calibration) {
      return null;
    }

    const currentDistance = pointA.distanceTo(pointB);
    const box = new THREE.Box3().setFromPoints([pointA, pointB]);
    const calibrationDistance = getCalibrationDistance(
      this.viewer,
      this.activeSheetData,
      this.calibration
    );
    const calibrationValue = this.calibration?.destinationWidth || 0;
    const lengthInDestinationUnit =
      Math.round(
        (currentDistance / calibrationDistance) * calibrationValue * 100
      ) / 100;

    return {
      label: `${lengthInDestinationUnit.toFixed(2)}${
        this.calibration.destinationUnit
      }`,
      point: viewer.worldToClient(box.getCenter()),
    };
  }

  private _drawLine(
    viewer: AggregatedView['viewer'],
    startPoint: THREE.Vector3,
    endPoint: THREE.Vector3,
    style: LineStyle
  ) {
    const line = drawCalibrationLine(startPoint, endPoint, style);

    viewer.impl.createOverlayScene(MEASUREMENT_TOOL_OVERLAY_ID, line.material);
    viewer.impl.addOverlay(MEASUREMENT_TOOL_OVERLAY_ID, line.shape);
    viewer.impl.invalidate(true, true, true);

    this.drawLabel(viewer);
  }
}
