import { UseMutationExecute } from 'urql';
import {
  Diamond,
  KileTypeSKU,
} from '../components/orderContractor/pdf-shapes/SheetWedgeShapeDrawing';
import {
  BulkUpsertSparkelPropertiesInput,
  BulkUpsertSparkelPropertiesMutation,
  CreateSheetShapeMutation,
  Exact,
  ForgeAttributeQueryTypeInput,
  GetOrderEntryTemplatesPageQuery,
  GetSparkelPropertiesForProjectQuery,
  SheetShapeDeepFragment,
} from '../gql/graphql';
import { SlopedInsulationCrossSection } from '../components/orderContractor/pdf-shapes/SheetShapeDrawing';
import { ResolvedOrderEntry } from '../domain/resolve-order-entry';
import {
  getSectioning,
  getTemplateForSku,
} from '../components/orderContractor/ProductChoices';
import { getProductAreasFromProperties } from 'src/components/orderContractor/pdf-shapes/SheetDrainLineDrawing';

export const updateWedgeProps = async (
  result: CreateSheetShapeMutation,
  diamond: Diamond,
  kileType: KileTypeSKU,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string
) => {
  if (diamond && result.createSheetShape?.sheetShape) {
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle1',
        thePropertyValue: diamond.triangle1 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle2',
        thePropertyValue: diamond.triangle2 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle3',
        thePropertyValue: diamond.triangle3 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle4',
        thePropertyValue: diamond.triangle4 ? '1' : '0',
      },
    });

    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'KileType',
        thePropertyValue: kileType,
      },
    });
  }
};

// eslint-disable-next-line complexity
export const updateDrainLineProps = async (
  sheetShape: SheetShapeDeepFragment | undefined,
  crossSection: SlopedInsulationCrossSection,
  roofShapeDbId: number | undefined,
  bulkUpsertSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  handleCreateFromTemplateClick: (
    templateId: string,
    title: string,
    query: ForgeAttributeQueryTypeInput | undefined,
    orderId: string
  ) => Promise<void>,
  resourceTemplateData: GetOrderEntryTemplatesPageQuery | undefined,
  resolvedOrderEntries: ResolvedOrderEntry[],
  propertyData: GetSparkelPropertiesForProjectQuery | undefined,
  unitScale: number,
  projectId: string,
  orderId: string,
  substrate: string
) => {
  if (crossSection && sheetShape && roofShapeDbId) {
    bulkUpsertSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: sheetShape.urn,
            dbIds: [sheetShape.dbId],
          },
        ],
        thePropertySet: 'CrossSection',
        thePropertyValue: JSON.stringify(crossSection),
      },
    });
    bulkUpsertSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: sheetShape.urn,
            dbIds: [sheetShape.dbId],
          },
        ],
        thePropertySet: 'RoofShapeDbId',
        thePropertyValue: roofShapeDbId.toString(),
      },
    });

    const allProducts = getProductAreasFromProperties(
      propertyData,
      sheetShape.dbId,
      sheetShape.urn,
      substrate
    );

    const promises = [];

    for (const productSKU of Object.keys(allProducts)) {
      if (productSKU && sheetShape && resourceTemplateData) {
        const shouldCreateFromTemplate = !resolvedOrderEntries.some(
          (orderEntry) =>
            orderEntry.customFields.find(
              (field) => field.resolvedValue.value === productSKU
            )
        );

        if (!shouldCreateFromTemplate) {
          continue;
        }

        const template = getTemplateForSku(
          productSKU,
          resourceTemplateData,
          undefined,
          true
        );

        if (template) {
          const templateCreationPromise = handleCreateFromTemplateClick(
            template.id,
            template.name,
            template.elementIdentifiersQuery || undefined,
            orderId
          );

          promises.push(templateCreationPromise);
        } else {
          console.log(
            `No template found for sku: ${productSKU}. Skipping template creation.`
          );
        }
      }
    }

    await Promise.all(promises);

    console.table(allProducts);
  }
};

export const setProtanProperties = async (
  result: CreateSheetShapeMutation,
  roofHeight: string | undefined,
  qkast: string | undefined,
  orderId: string,
  fireRequirements: string | undefined,
  energyRequirement: string | undefined,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string
) => {
  if (qkast && result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'QKast',
        thePropertyValue: qkast,
      },
    });
  }
  if (result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'OrderId',
        thePropertyValue: orderId,
      },
    });
  }
  if (roofHeight && result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Roof height',
        thePropertyValue: roofHeight,
      },
    });
  }

  if (
    fireRequirements === 'Combustible' &&
    result.createSheetShape?.sheetShape &&
    energyRequirement
  ) {
    const sectioning = getSectioning(energyRequirement);
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Fire sectioning',
        thePropertyValue: sectioning,
      },
    });
  }
};
