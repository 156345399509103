import { createContext, useContext } from 'react';

export enum ViewerMode {
  Models = 'models',
  Sheets = 'sheets',
  SheetsV2 = 'sheetsV2',
}

export type ViewerModeContextValue = {
  viewerMode: ViewerMode;
  setViewerMode: (mode: ViewerMode) => void;
};

const ViewerModeContext = createContext<ViewerModeContextValue | null>(null);

export const ViewerModeProvider = ViewerModeContext.Provider;

export const useViewerMode = (): ViewerModeContextValue => {
  const context = useContext(ViewerModeContext);
  if (context === null) {
    throw new Error('useViewerMode must be used within a ViewerModeProvider');
  }
  return context;
};
