import { useContext } from 'react';
import { SheetsViewerV2Context } from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.context';

export function useSheetViewerV2() {
  const context = useContext(SheetsViewerV2Context);

  if (!context) {
    throw new Error(
      'Cannot use SheetsViewerV2Context context without initialization'
    );
  }

  return context;
}
