import { ChakraColor } from 'src/utils/color-util';

export const AUTODESK_VIEWING_INITIALIZER_ENV = 'AutodeskProduction2';
export const AUTODESK_VIEWING_INITIALIZER_API = 'streamingV2_EU';
export const VIEWER_TOP_OFFSET = 68;

export const CALIBRATION_TOOL_SNAPPING_ANGLE_STEP = 45; // snap to n degrees while pressing shift key
export const CALIBRATION_TOOL_OVERLAY_ID = 'EDIT2D_CALIBRATION_TOOL_OVERLAY';
export const MEASUREMENT_TOOL_SNAPPING_ANGLE_STEP = 45; // snap to n degrees while pressing shift key
export const MEASUREMENT_TOOL_OVERLAY_ID = 'EDIT2D_MEASUREMENT_TOOL_OVERLAY';
export const CROSSHAIR_CURSOR = 'crosshair';

// DPI used for mapping points in a PDF to the system's coordinate system,
// following the PDF.js methodology (conversion consistent with the existing coordinate storage format)
export const PDF_COORDINATE_MAPPING_DPI = 72;
export const INCHES_IN_ONE_METER = 39.3701;

export const ENABLE_APS_SHEET_VIEWER_FLAG = 'enable-aps-sheet-viewer';

export const DefaultShapeStyle = {
  lineWidth: 0.5,
  fillAlpha: 0.7,
  lineColor: ChakraColor.gray800,
  fillColor: ChakraColor.gray500,
};
export const HoveredShapeStyle = {
  lineWidth: 0.5,
  fillAlpha: 0.7,
  lineColor: ChakraColor.yellow700,
  fillColor: ChakraColor.yellow500,
};
export const SelectedShapeStyle = {
  lineWidth: 0.5,
  fillAlpha: 0.8,
  lineColor: '#ffffff',
  fillColor: ChakraColor.blue300,
};

export const DefaultLineStyle = {
  lineWidth: 2,
  lineColor: ChakraColor.gray500,
};
export const HoveredLineStyle = {
  lineWidth: 2,
  lineColor: ChakraColor.yellow500,
};
export const SelectedLineStyle = {
  lineWidth: 2,
  lineColor: ChakraColor.blue500,
};

export const CalibrationDefaultLineStyle = {
  lineWidth: 0.05,
  lineColor: ChakraColor.yellow500,
};
export const CalibrationDrawingLineStyle = {
  lineWidth: 0.05,
  lineColor: ChakraColor.yellow300,
};
