import {
  groupBy,
  intersection,
  isEmpty,
  isEqual,
  omit,
  partition,
  round,
  sortBy,
} from 'lodash';
import invariant from 'tiny-invariant';
import { Client } from 'urql';
import { ModuleThread, Pool } from 'threads';
import { MutableRefObject } from 'react';
import {
  ColumnType,
  ElementIdentifierDbidType,
  ForgeAttributePredicateType,
  ForgeAttributeQueryType,
  ForgeAttributeType,
  OrderColumnDeepFragment,
  OrderDeepFragment,
  OrderEntryDeepFragment,
  PredicateType,
  Quantity,
  QuantityEnum,
  UnitSettingsType,
} from '../gql/graphql';
import { difference, merge } from '../utils/dbid-utils';

import { getColorValueFromGradient } from '../utils/color-util';
import {
  convertQuantityToSIUnits,
  convertSIQuantity,
  formatUnit,
  roundingToPrecision,
  unitToUnitType,
} from '../utils/unit-utils';
import {
  PropertyContext,
  resolveElementIdentifiersDBID,
  resolveProperties,
  resolveQuantity,
} from './property-operations';
import { ResolvedQuantity } from './property-types';
import { resolveDerivedFields } from './resolve-formula';
import {
  SerializeNHashModule,
  bulkComputeHashInWorker,
  BulkHashOutput,
} from './worker/hashing-pool';
import { makeTinierBatches, runWorkInBatches } from 'src/utils/promise-pool';
import { dedupeOperations, dedupedMutation } from 'src/utils/dedupe-mutations';
import { ResolvedOrder } from 'src/hooks/order';
import { parseLabels } from 'src/utils/label-utils';

export type ResolvedCustomField = Omit<
  OrderEntryDeepFragment['customFields'][0],
  'resolvedValue'
> & {
  resolvedValue: ResolvedQuantity;
  isSomeQuantityMissing: boolean;
};

export type ResolvedOrderEntry = Omit<
  OrderEntryDeepFragment,
  'customFields'
> & {
  isMissingResolvedQuantity: boolean;
  subRows?: ResolvedOrderEntry[];
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[];
  resolvedQuantity: ResolvedQuantity | null;
  customFields: ResolvedCustomField[];
};

export type ResolvedQuantities = {
  resolvedCustomFields: ResolvedCustomField[];
  resolvedQuantity: ResolvedQuantity | null;
  isMissingResolvedQuantity: boolean;
};

type OrderEntriesCache = {
  inputs: Map<
    string,
    { entry: Partial<OrderEntryDeepFragment>; context: PropertyContext }
  >;
  resolvedOrders: Map<string, ResolvedOrderEntry>;
  projectId: string;
  polygonCount: number | null | undefined;
};

const orderEntriesCache: OrderEntriesCache = {
  inputs: new Map(),
  resolvedOrders: new Map<string, ResolvedOrderEntry>(),
  projectId: '',
  polygonCount: null,
};

export interface ResolvedOrderReturnType {
  value: ResolvedOrderEntry;
  hash: string | null | undefined;
  persistToBackend: boolean;
}

export const resolveOrderV2 = async (
  pool: MutableRefObject<Pool<ModuleThread<SerializeNHashModule>> | null>,
  projectId: string,
  order: OrderDeepFragment,
  context: PropertyContext,
  backendCacheEnabled: boolean,
  isReadOnly: boolean,
  unitSettings?: UnitSettingsType,
  polygonCount?: undefined | null | number
): Promise<{
  resolvedOrder: ResolvedOrder;
  entriesToCacheInBackend: Array<ResolvedOrderEntry>;
  hashesWithEntryIds: Array<BulkHashOutput>;
}> => {
  // Reset cache if we navigate to a different project
  if (
    projectId !== orderEntriesCache.projectId ||
    polygonCount !== orderEntriesCache.polygonCount
  ) {
    orderEntriesCache.inputs = new Map();
    orderEntriesCache.resolvedOrders = new Map();
    orderEntriesCache.projectId = projectId;
    orderEntriesCache.polygonCount = polygonCount;
  }
  // find all the entries that are already in the F.E cache and that are valid and hold them in the structure that I wanna return
  const resolvedEntriesInFrontendCache = getEntriesInFrontendCache(
    order.orderEntries,
    context,
    isReadOnly
  );

  // if we have all the entries, then we return them here
  const allEntriesInFeCache = new Set<string>();
  for (const resolvedEntryInFe of resolvedEntriesInFrontendCache) {
    allEntriesInFeCache.add(resolvedEntryInFe.id);
  }

  const entriesNotInFrontendCache = order.orderEntries.filter(
    (entry) => !allEntriesInFeCache.has(entry.id)
  );

  // if all entries are in the F.E cache then return early
  if (entriesNotInFrontendCache.length === 0) {
    const resolvedOrder = {
      ...order,
      labels: parseLabels(order.labels) as ResolvedOrder['labels'],
      orderEntries: sortBy(resolvedEntriesInFrontendCache, [
        (entry) => entry.createdAt,
        (entry) => entry.name,
      ]),
    };

    return {
      resolvedOrder,
      entriesToCacheInBackend: [],
      hashesWithEntryIds: [],
    };
  }

  const entriesToCacheInBackend: Array<ResolvedOrderEntry> = [];
  let hashesWithEntryIds: Array<BulkHashOutput> = [];

  if (backendCacheEnabled) {
    // We want to skip all these processes if we are in the sharing and just return whatever backend cache that ships with the entries
    if (!isReadOnly) {
      const loadedModels = Object.keys(context.loadedModels);
      const orderColumnIds = order.orderColumns.map((col) => col.id);
      // Grab all the entries that need to be hashed
      const entriesForHashing = entriesNotInFrontendCache.map((entry) =>
        omit(entry, ['orderEntryCache'])
      );
      const input = { loadedModels, context, polygonCount, orderColumnIds };
      hashesWithEntryIds = await bulkComputeHashInWorker(
        input,
        entriesForHashing,
        pool
      );
    }

    const hashMap = new Map(
      hashesWithEntryIds.map((hash) => [hash.entryId, hash])
    );

    const resolvedEntriesInBackendCache = getEntriesInBackendCache(
      order.orderEntries,
      context,
      hashMap,
      isReadOnly
    );
    // We need to figure out if the combination of all entries in frontend cache and backend cache consists of all the entries and return early
    // instead of combining these 2 arrays, you can just look into what is currently in the F.E cache?
    const allCachedResolvedEntries = [
      ...resolvedEntriesInBackendCache,
      ...resolvedEntriesInFrontendCache,
    ];

    const allResolvedEntryIds = new Set<string>();
    for (const cachedResolvedEntry of allCachedResolvedEntries) {
      allResolvedEntryIds.add(cachedResolvedEntry.id);
    }

    const entriesNotInCache = order.orderEntries.filter(
      (entry) => !allResolvedEntryIds.has(entry.id)
    );

    if (entriesNotInCache.length === 0) {
      const resolvedOrder = {
        ...order,
        labels: parseLabels(order.labels) as ResolvedOrder['labels'],
        orderEntries: sortBy(allCachedResolvedEntries, [
          (entry) => entry.createdAt,
          (entry) => entry.name,
        ]),
      };

      return { resolvedOrder, entriesToCacheInBackend: [], hashesWithEntryIds };
    }

    // Right now we resolve the entries that are not in the cache
    const operations = entriesNotInCache.map((entry) => {
      const sanitizedEntry = omit(entry, ['orderEntryCache']);
      const hashWithId = hashMap.get(entry.id);
      const op = () =>
        resolveEntry(
          sanitizedEntry,
          entry,
          order.orderColumns,
          context,
          polygonCount ?? 5000,
          unitSettings
        );

      return dedupeOperations(`${entry.id}-${hashWithId?.hash}`, op);
    });

    const dedupedOpResult = await Promise.all(
      operations.map(async ({ original, valuePromise }) => ({
        original,
        value: await valuePromise,
      }))
    );

    // original resolved entries do the actual wait, non-original are just served a pending promise to wait on
    const originalResolved: Array<ResolvedOrderEntry> = dedupedOpResult
      .filter((rslt) => rslt.original === true)
      .map((rslt) => rslt.value);
    // const resolvedEntries = dedupedOpResult.map((rslt) => rslt.value);

    entriesToCacheInBackend.push(...originalResolved);

    // We are returning whatever that was in the cache and what we have just resolved.
    // Everything should be in the F.E cache by now
    // const allResolvedEntries = [...resolvedEntries, ...allCachedResolvedEntries];
  } else {
    const resolvePromises = entriesNotInFrontendCache.map((entryToResolve) => {
      const sanitizedEntry = omit(entryToResolve, ['orderEntryCache']);
      return resolveEntry(
        sanitizedEntry,
        entryToResolve,
        order.orderColumns,
        context,
        polygonCount ?? 5000,
        unitSettings
      );
    });

    await Promise.all(resolvePromises);
  }

  // Since we are reading from cache, ensure that return resolvedEntries that appear in this order (instead of the entire contents of the cache)
  const allResolvedEntries = order.orderEntries
    .map((entry) => orderEntriesCache.resolvedOrders.get(entry.id))
    .filter((item): item is ResolvedOrderEntry => item != undefined);

  const resolvedOrder = {
    ...order,
    labels: parseLabels(order.labels) as ResolvedOrder['labels'],
    orderEntries: sortBy(allResolvedEntries, [
      (entry) => entry.createdAt,
      (entry) => entry.name,
    ]),
  };

  return { resolvedOrder, entriesToCacheInBackend, hashesWithEntryIds };
};

export const resolveOrdersV2 = async (
  pool: MutableRefObject<Pool<ModuleThread<SerializeNHashModule>> | null>,
  orders: Array<OrderDeepFragment>,
  projectId: string,
  context: PropertyContext,
  urqlClient: Client,
  backendCacheEnabled: boolean,
  isReadOnly = false,
  unitSettings?: UnitSettingsType,
  polygonCount?: undefined | null | number
): Promise<Array<ResolvedOrder>> => {
  const { errors, results } = await runWorkInBatches(orders, async (order) => {
    return resolveOrderV2(
      pool,
      projectId,
      order,
      context,
      backendCacheEnabled,
      isReadOnly,
      unitSettings,
      polygonCount
    );
  });
  if (errors.length > 0) {
    console.error(errors);
  }

  // only set the backend cache if the backend cache has been enabled and if we are not in a shared state?
  if (backendCacheEnabled && !isReadOnly) {
    const backendCacheEntries = results.flatMap(
      (rst) => rst.entriesToCacheInBackend
    );

    const hashesWithEntryIds = results.flatMap((rst) => rst.hashesWithEntryIds);

    const dataForBackendCache: Array<{
      value: ResolvedOrderEntry;
      hash: string;
      orderEntryId: string;
    }> = [];
    const hashMap = new Map(
      hashesWithEntryIds.map((hash) => [hash.entryId, hash])
    );
    backendCacheEntries.forEach((entry) => {
      const hashWithId = hashMap.get(entry.id);
      invariant(hashWithId, 'Hash should be present for this entry');
      dataForBackendCache.push({
        value: getResolvedEntryForBackendCaching(entry),
        hash: hashWithId.hash,
        orderEntryId: entry.id,
      });
    });

    if (dataForBackendCache.length > 0) {
      const batchedWork = makeTinierBatches(dataForBackendCache);
      await runWorkInBatches(batchedWork, async (work) => {
        const key = work
          .map(({ hash, orderEntryId }) => `${hash}-${orderEntryId}`)
          .sort()
          .join();
        return dedupedMutation(urqlClient, key, work);
      });
    }
  }

  return results.map((rst) => rst.resolvedOrder);
};

function getEntriesInFrontendCache(
  entries: Array<OrderEntryDeepFragment>,
  context: PropertyContext,
  isReadOnly: boolean
): Array<ResolvedOrderEntry> {
  // Attempt to retrieve the cache
  const retVal = [];
  for (const entry of entries) {
    const resolvedOrderEntry = orderEntriesCache.resolvedOrders.get(entry.id);
    // We can check if the we are in the shared order, if we are, we can push the result and continue with the next iteration
    if (isReadOnly && resolvedOrderEntry) {
      retVal.push(resolvedOrderEntry);
      continue;
    }

    // Omit fields used for caching from the entry to make the comp less expensive
    const cachedEntryInput = orderEntriesCache.inputs.get(entry.id);
    const sanitizedEntry = omit(entry, ['orderEntryCache']);
    const areInputsEqual = isEqual(
      { entry: sanitizedEntry, context },
      cachedEntryInput
    );
    if (resolvedOrderEntry && areInputsEqual) {
      retVal.push(resolvedOrderEntry);
    }
  }
  return retVal;
}

function getEntriesInBackendCache(
  entries: Array<OrderEntryDeepFragment>,
  context: PropertyContext,
  hashMap: Map<string, BulkHashOutput>,
  isReadOnly: boolean
): Array<ResolvedOrderEntry> {
  const retVal = [];
  for (const entry of entries) {
    // set the cache early and continue in the next iteration loop
    if (entry.orderEntryCache && isReadOnly) {
      retVal.push(entry.orderEntryCache.value);
      orderEntriesCache.inputs.set(entry.id, {
        entry: omit(entry, ['orderEntryCache']),
        context,
      });
      orderEntriesCache.resolvedOrders.set(
        entry.id,
        entry.orderEntryCache.value
      );
      continue;
    }

    // will run if not in share mode
    const hashWithId = hashMap.get(entry.id);
    if (
      entry.orderEntryCache &&
      hashWithId &&
      hashWithId.hash === entry.orderEntryCache.hash
    ) {
      retVal.push(entry.orderEntryCache.value);
      // Set the cache as well.
      orderEntriesCache.inputs.set(entry.id, {
        entry: omit(entry, ['orderEntryCache']),
        context,
      });
      orderEntriesCache.resolvedOrders.set(
        entry.id,
        entry.orderEntryCache.value
      );
    }
  }
  return retVal;
}

// eslint-disable-next-line complexity
export async function resolveEntry(
  sanitizedEntry: Omit<OrderEntryDeepFragment, 'orderEntryCache'>,
  entry: OrderEntryDeepFragment,
  orderColumns: OrderColumnDeepFragment[],
  context: PropertyContext,
  polygonCount: number,
  unitSettings?: UnitSettingsType | undefined
) {
  let resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] = [];
  let resolvedQuantity: ResolvedQuantity | null = null;
  let resolvedCustomFields: ResolvedCustomField[] = [];
  let missingQuantity = false;

  const dynamicValues = await resolveOrderEntryDynamicValues(
    entry,
    orderColumns,
    context,
    polygonCount || 5000
  );

  if (dynamicValues.resolvedElementIdentifiersDbid) {
    resolvedElementIdentifiersDbid =
      dynamicValues.resolvedElementIdentifiersDbid;
  }
  if (dynamicValues.resolvedQuantity) {
    resolvedQuantity = dynamicValues.resolvedQuantity;
  }
  if (dynamicValues.resolvedCustomFields) {
    resolvedCustomFields = dynamicValues.resolvedCustomFields;
  }
  if (dynamicValues.missingQuantity) {
    missingQuantity = dynamicValues.missingQuantity;
  }

  const resolvedEntry: ResolvedOrderEntry = {
    ...sanitizedEntry,
    resolvedQuantity: resolvedQuantity,
    resolvedElementIdentifiersDbid,
    customFields: resolvedCustomFields,
    isMissingResolvedQuantity: missingQuantity,
  };

  const subRows = await resolveSubRows(
    context,
    orderColumns,
    entry,
    resolvedElementIdentifiersDbid,
    entry.groupByAttributes?.[0] ?? null,
    entry.groupByAttributes?.slice(1) ?? null,
    unitSettings
  );

  resolvedEntry.subRows = subRows;

  // Set the cache to be used for subsequent requests
  orderEntriesCache.inputs.set(entry.id, { entry: sanitizedEntry, context });
  orderEntriesCache.resolvedOrders.set(entry.id, resolvedEntry);

  return resolvedEntry;
}

// function getNumberOfBytes<T>(obj: T) {
//   const objStr = JSON.stringify(obj);
//   const sizeInBytes = new TextEncoder().encode(objStr).length;
//   return sizeInBytes / 1024 ** 2;
// }

export function getResolvedEntryForBackendCaching(
  resolvedOrderEntry: ResolvedOrderEntry
) {
  if (!resolvedOrderEntry) return resolvedOrderEntry;
  const resolvedQty = omit(resolvedOrderEntry.resolvedQuantity, 'dataPoints');
  const customFields = resolvedOrderEntry.customFields.map((field) => {
    const qty = omit(field.resolvedValue, 'dataPoints');
    return {
      ...field,
      resolvedValue: qty,
    };
  });

  const subRows: Array<ResolvedOrderEntry> =
    resolvedOrderEntry.subRows?.map(getResolvedEntryForBackendCaching) ?? [];

  return {
    ...resolvedOrderEntry,
    resolvedQuantity: resolvedQty,
    customFields,
    subRows,
  };
}

export type OrderEntryDynamicValues = {
  resolvedQuantity: ResolvedQuantity | null;
  missingQuantity: boolean;
  resolvedCustomFields: ResolvedCustomField[];
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] | null;
};

export const resolveOrderEntryDynamicValues = async (
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'elementIdentifiersQuery' | 'elementIdentifiersDbid' | 'customFields'
  >,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  context: PropertyContext,
  polygonCount = 5000
): Promise<OrderEntryDynamicValues> => {
  let resolvedElementIdentifiersDbid = await resolveDbIds(
    context,
    orderEntry.elementIdentifiersQuery,
    orderEntry.elementIdentifiersDbid
  );

  const { resolvedCustomFields, resolvedQuantity, isMissingResolvedQuantity } =
    await resolveQuantities(
      orderEntry,
      orderColumns,
      resolvedElementIdentifiersDbid,
      context,
      polygonCount
    );
  return {
    resolvedQuantity,
    resolvedCustomFields,
    resolvedElementIdentifiersDbid,
    missingQuantity: isMissingResolvedQuantity,
  };
};

export async function resolveDbIds(
  context: PropertyContext,
  elementIdentifiersQuery: ForgeAttributeQueryType | null | undefined,
  elementIdentifiersDbid: ElementIdentifierDbidType[] | null | undefined
) {
  if (elementIdentifiersQuery) {
    return await resolveElementIdentifiersDBID(
      context,
      elementIdentifiersQuery
    );
  } else if (elementIdentifiersDbid) {
    return excludeMissingDbIds(context, elementIdentifiersDbid);
  } else {
    return [];
  }
}

// Exclude links to models and shapes that are not present in the property context,
// Which can occur if for example a model is removed from the project, or if a shape is deleted
function excludeMissingDbIds(
  context: PropertyContext,
  elementIdentifiersDbid: ElementIdentifierDbidType[]
) {
  const loadedUrns = [
    ...Object.keys(context.loadedModels),
    ...Object.keys(context.loadedShapes),
    ...Object.keys(context.loadedSheetShapes),
  ];

  const loadedShapes = {
    ...context.loadedShapes,
    ...context.loadedSheetShapes,
  };

  return elementIdentifiersDbid
    .filter((dbId) => loadedUrns.includes(dbId.modelUrn))
    .map((dbId) => {
      if (dbId.modelUrn in loadedShapes) {
        const loadedShapeDbIds = loadedShapes[dbId.modelUrn].map(
          (shape) => shape.dbId
        );
        return {
          ...dbId,
          // Exclude shape dbIds that are not loaded, to handle deleted shapes
          dbIds: intersection(dbId.dbIds, loadedShapeDbIds),
        };
      }
      return dbId;
    });
}

export type Field = {
  columnId: string;
  quantity: Omit<Quantity, '__typename'>;
};

export type ResolvedField = {
  columnId: string;
  resolvedQuantity: ResolvedQuantity;
  isSomeQuantityMissing: boolean;
};

async function resolveNonDerivedFields(
  nonDerivedFields: Field[],
  context: PropertyContext,
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[],
  polygonCount = 5000
): Promise<ResolvedField[]> {
  const result: ResolvedField[] = [];
  for (const field of nonDerivedFields) {
    const resolvedQuantity = await resolveQuantity(
      context,
      resolvedElementIdentifiersDbid,
      field.quantity,
      undefined,
      polygonCount
    );
    const isMissing = isSomeQuantityMissing(
      resolvedQuantity,
      resolvedElementIdentifiersDbid
    );
    result.push({
      columnId: field.columnId,
      resolvedQuantity,
      isSomeQuantityMissing: isMissing,
    });
  }
  return result;
}

function getFields(
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'customFields' | 'quantity' | 'unitPrice'
  >,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[]
): Field[] {
  const fields = orderEntry.customFields.map((cf) => ({
    columnId: cf.orderColumnId,
    quantity: cf.value,
  }));

  const quantityColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.Quantity
  );

  if (quantityColumn && orderEntry.quantity) {
    fields.push({
      columnId: quantityColumn.id,
      quantity: orderEntry.quantity,
    });
  }

  const priceColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.UnitPrice
  );

  if (priceColumn) {
    fields.push({
      columnId: priceColumn.id,
      quantity: {
        type: QuantityEnum.Static,
        value: orderEntry.unitPrice,
      },
    });
  }

  const elementsColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.LinkedCount
  );

  if (elementsColumn) {
    fields.push({
      columnId: elementsColumn.id,
      quantity: {
        type: QuantityEnum.Static,
        value: resolvedElementIdentifiersDbid.length,
      },
    });
  }
  return fields;
}

function getResolvedQuantityFromFields(
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedFields: ResolvedField[]
): ResolvedField | null {
  const quantityColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.Quantity
  );

  const resolvedQuantityField = resolvedFields.find(
    (field) => field.columnId === quantityColumn?.id
  );
  if (
    !resolvedQuantityField ||
    typeof resolvedQuantityField.resolvedQuantity.value !== 'number'
  ) {
    return null;
  }

  return resolvedQuantityField;
}

async function resolveQuantities(
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'customFields' | 'quantity' | 'unitPrice'
  >,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[],
  context: PropertyContext,
  polygonCount = 5000
): Promise<ResolvedQuantities> {
  const fields = getFields(
    orderEntry,
    orderColumns,
    resolvedElementIdentifiersDbid
  );
  const [derivedFields, nonDerivedFields] = partition(
    fields,
    (cf) => cf.quantity.type === QuantityEnum.Derived
  );

  const resolvedNonDerivedFields = await resolveNonDerivedFields(
    nonDerivedFields,
    context,
    resolvedElementIdentifiersDbid,
    polygonCount
  );

  const resolvedDerivedFields = resolveDerivedFields(
    resolvedNonDerivedFields,
    derivedFields
  );
  const resolvedFields = [
    ...resolvedNonDerivedFields,
    ...resolvedDerivedFields,
  ];

  const resolvedQuantity = getResolvedQuantityFromFields(
    orderColumns,
    resolvedFields
  );

  const resolvedCustomFields = getResolvedCustomFieldsFromFields(
    orderColumns,
    resolvedFields,
    orderEntry
  );

  return {
    resolvedCustomFields,
    resolvedQuantity: resolvedQuantity?.resolvedQuantity ?? null,
    isMissingResolvedQuantity: resolvedQuantity?.isSomeQuantityMissing ?? false,
  };
}

function getResolvedCustomFieldsFromFields(
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedFields: ResolvedField[],
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'customFields' | 'unitPrice' | 'quantity'
  >
) {
  const customColumnIds = orderColumns
    .filter((oc) => oc.columnType === ColumnType.Custom)
    .map((oc) => oc.id);
  return resolvedFields
    .filter((field) => customColumnIds.includes(field.columnId))
    .map((field): ResolvedCustomField => {
      const customField = orderEntry.customFields.find(
        (cf) => cf.orderColumnId === field.columnId
      );
      invariant(customField, 'Custom field not found');
      return {
        ...customField,
        resolvedValue: field.resolvedQuantity,
        isSomeQuantityMissing: field.isSomeQuantityMissing,
      };
    });
}

function isSomeQuantityMissing(
  resolvedQuantity: ResolvedQuantity | null,
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] | undefined
) {
  if (!resolvedQuantity?.dataPoints || !resolvedElementIdentifiersDbid) {
    return false;
  }
  const inputDbids = resolvedElementIdentifiersDbid
    .flatMap((dbIds) => dbIds.dbIds.map((dbId) => `${dbIds.modelUrn}:${dbId}`))
    .sort();
  const outputDbids = resolvedQuantity.dataPoints
    .map((point) => `${point.modelUrn}:${point.dbId}`)
    .sort();
  return !isEqual(inputDbids, outputDbids);
}

export const resolveSubRows = async (
  context: PropertyContext,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  entry: OrderEntryDeepFragment,
  dbIdsForParent: ElementIdentifierDbidType[],
  groupByAttribute: ForgeAttributeType | null,
  groupingChain: ForgeAttributeType[] | null,
  unitSettings?: UnitSettingsType
): Promise<ResolvedOrderEntry[] | undefined> => {
  if (!context || groupByAttribute === null || !dbIdsForParent) {
    return undefined;
  }

  const subRows = await findSubrows(
    context,
    dbIdsForParent,
    groupByAttribute,
    entry.elementIdentifiersQuery,
    unitSettings
  );

  const { sortedSubRows, minValue, maxValue } =
    sortSubrowsAndExtractMaxima(subRows);

  let currentIndex = 0;

  const resolveSubRowEntries: ResolvedOrderEntry[] = [];
  for (const {
    subrowName,
    dbIdsForSubrow,
    elementIdentifiersQuery,
  } of sortedSubRows) {
    const {
      resolvedCustomFields,
      resolvedQuantity,
      isMissingResolvedQuantity,
    } = await resolveQuantities(entry, orderColumns, dbIdsForSubrow, context);

    const color = getSubrowColor(
      entry,
      sortedSubRows,
      currentIndex,
      minValue,
      maxValue
    );
    const entryWithoutCache = omit(entry, ['orderEntryCache']);

    const resolvedEntry: ResolvedOrderEntry = {
      ...entryWithoutCache,
      color,
      name: subrowName,
      isMissingResolvedQuantity,
      elementIdentifiersQuery,
      groupByAttributes: null,
      resolvedQuantity: resolvedQuantity,
      resolvedElementIdentifiersDbid: dbIdsForSubrow,
      customFields: resolvedCustomFields,
    };

    if (groupingChain !== null && groupingChain.length > 0) {
      resolvedEntry.subRows = await resolveSubRows(
        context,
        orderColumns,
        { ...entryWithoutCache, color },
        dbIdsForSubrow,
        groupingChain[0],
        groupingChain.slice(1),
        unitSettings
      );
    }
    resolveSubRowEntries.push(resolvedEntry);

    currentIndex++;
  }

  return resolveSubRowEntries;
};

function sortSubrowsAndExtractMaxima(
  subRows: {
    subrowName: string;
    dbIdsForSubrow: ElementIdentifierDbidType[];
    elementIdentifiersQuery: ForgeAttributeQueryType | null;
  }[]
) {
  // Determine if subrowName values are numeric
  const areSubrowNamesNumeric = subRows.every(
    (row) => !isNaN(Number(row.subrowName))
  );
  let maxValue = undefined;
  let minValue = undefined;

  let sortedSubRows = subRows;

  if (areSubrowNamesNumeric) {
    // Find the max and min values
    for (const row of sortedSubRows) {
      const value = Number(row.subrowName);
      if (!maxValue || value > maxValue) {
        maxValue = value;
      }
      if (!minValue || value < minValue) {
        minValue = value;
      }
    }

    // Sort the subrows by the numeric value
    sortedSubRows = subRows.sort(
      (a, b) => Number(a.subrowName) - Number(b.subrowName)
    );
  } else {
    // Sort the subrows by the subrowName
    sortedSubRows = subRows.sort((a, b) =>
      a.subrowName.localeCompare(b.subrowName)
    );
  }

  return {
    sortedSubRows,
    minValue,
    maxValue,
  };
}

function getSubrowColor(
  entry: OrderEntryDeepFragment,
  allSubRows: {
    subrowName: string;
    dbIdsForSubrow: ElementIdentifierDbidType[];
    elementIdentifiersQuery: ForgeAttributeQueryType | null;
  }[],
  index: number,
  minValue: number | undefined,
  maxValue: number | undefined
) {
  let color = entry.color;

  if (color.includes('gradient')) {
    let factor;
    if (allSubRows.length === 1) {
      factor = 0.5;
    } else {
      factor = index / (allSubRows.length - 1);
    }

    if (
      !isNaN(Number(entry.name)) &&
      maxValue &&
      minValue &&
      maxValue !== minValue
    ) {
      // Calculate the factor based on the min and max values
      factor = (Number(entry.name) - minValue) / (maxValue - minValue);
    }
    color = getColorValueFromGradient(color, factor);
  }

  return color;
}

async function findSubrows(
  context: PropertyContext,
  dbIds: ElementIdentifierDbidType[],
  groupByAttribute: ForgeAttributeType,
  dbIdQuery: ForgeAttributeQueryType | null | undefined,
  unitSettings?: UnitSettingsType
) {
  const properties = (
    await resolveProperties(context, groupByAttribute, dbIds)
  ).map((property) => {
    if (typeof property.value === 'number') {
      // Round for grouping by floating point numbers
      if (property.units && unitSettings) {
        const propertyAsSIQuantity = convertQuantityToSIUnits(property);
        const convertedValue = convertSIQuantity(
          {
            value: propertyAsSIQuantity.value,
            units: propertyAsSIQuantity.units,
          },
          unitSettings
        );

        if (!convertedValue || !convertedValue.value || !convertedValue.units) {
          return property;
        }

        const unitType = unitToUnitType(convertedValue.units);
        const rounding = unitType
          ? unitSettings[unitType]?.rounding
          : undefined;
        const precision = rounding ? roundingToPrecision(rounding) : 2;

        const propertyValue = round(convertedValue.value as number, precision);
        const propertyUnits = formatUnit(convertedValue.units);

        property.value = `${propertyValue} ${propertyUnits}`;
      } else {
        property.value = round(
          convertQuantityToSIUnits(property).value as number,
          2
        );
      }
    }
    return property;
  });

  const propertiesForSubrows = groupBy(
    properties,
    (property) => property.value
  );

  const dbIdsForSubrows = Object.entries(propertiesForSubrows).map(
    ([subrowName, propertiesForSubrow]) => {
      const propertiesForModel = groupBy(
        propertiesForSubrow,
        (property) => property.modelUrn
      );
      const dbIdsForSubrow: ElementIdentifierDbidType[] = Object.entries(
        propertiesForModel
      ).map(([modelUrn, properties]) => ({
        modelUrn,
        dbIds: properties.map((properties) => properties.dbId),
      }));
      let newDbIdQuery: ForgeAttributeQueryType | null = null;
      if (dbIdQuery) {
        const predicate: ForgeAttributePredicateType = {
          attribute: {
            category: groupByAttribute.category,
            name: groupByAttribute.name,
          },
          predicate: PredicateType.Equals,
          attributeValues: [subrowName],
        };
        newDbIdQuery = {
          predicates: [...dbIdQuery.predicates, predicate],
        };
      }
      return {
        subrowName,
        dbIdsForSubrow,
        elementIdentifiersQuery: newDbIdQuery,
      };
    }
  );

  const dbIdsMissingGroupByAttribute = difference(
    dbIds,
    dbIdsForSubrows
      .map((dbIds) => dbIds.dbIdsForSubrow)
      .reduce((prev, next) => merge(prev, next), [])
  );

  if (!isEmpty(dbIdsMissingGroupByAttribute)) {
    let subrowDbIdQuery: ForgeAttributeQueryType | null = null;
    if (dbIdQuery) {
      const predicate: ForgeAttributePredicateType = {
        attribute: {
          category: groupByAttribute.category,
          name: groupByAttribute.name,
        },
        predicate: PredicateType.NotExists,
        attributeValues: [],
      };
      subrowDbIdQuery = {
        predicates: [...dbIdQuery.predicates, predicate],
      };
    }
    dbIdsForSubrows.push({
      subrowName: `Missing property ${groupByAttribute.name}`,
      dbIdsForSubrow: dbIdsMissingGroupByAttribute,
      elementIdentifiersQuery: subrowDbIdQuery,
    });
  }
  return dbIdsForSubrows;
}
