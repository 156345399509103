import { AbstractSheetFilledShape } from './abstract/AbstractSheetFilledShape';
import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { SheetShapeStyleType } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import { DefaultShapeStyle } from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';
import { SheetShapeDeepFragment } from 'src/gql/graphql';
import Shape = Autodesk.Edit2D.Shape;
import Edit2D = Autodesk.Extensions.Edit2D;
import GuiViewer3D = Autodesk.Viewing.GuiViewer3D;
import Viewer3D = Autodesk.Viewing.Viewer3D;

export class PointShape extends AbstractSheetFilledShape {
  static Radius = 0.1;
  private readonly pointShape: Shape;

  constructor(
    viewer: Viewer3D | GuiViewer3D,
    edit2d: Edit2D,
    activeSheetData: ActiveSheetData,
    shapeModel: SheetShapeDeepFragment
  ) {
    super(viewer, edit2d, activeSheetData, shapeModel);

    if (!shapeModel.sheetShapePoint) {
      throw new Error('shapeModel.sheetShapeLine must be defined');
    }

    const convertedPoint = this.convertPointToRender(
      shapeModel.sheetShapePoint.point
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.pointShape = new Autodesk.Edit2D.Circle(
      convertedPoint.x,
      convertedPoint.y,
      0.005
    );
    this.pointShape.style.fillColor = DefaultShapeStyle.lineColor;
    this.pointShape.style.lineColor = DefaultShapeStyle.lineColor;
    this.pointShape.style.fillAlpha = 1;
    this.pointShape.style.lineAlpha = 1;

    this.shapes = [
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new Autodesk.Edit2D.Circle(
        convertedPoint.x,
        convertedPoint.y,
        PointShape.Radius
      ),
    ];
    this.textLabelPoints = [convertedPoint];
    this.setStyle(SheetShapeStyleType.default);
  }

  draw() {
    super.draw();
    this.edit2d.defaultContext.layer.addShape(this.pointShape);
  }

  remove() {
    super.draw();
    this.edit2d.defaultContext.layer.removeShape(this.pointShape);
  }
}
