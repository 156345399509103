import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'urql';
import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { ActiveSheet } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { ActiveSheetPagination } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheetPagination';
import { useCalibrationTool } from 'src/components/viewers/SheetsViewerV2/hooks/useCalibrationTool';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { ApiCoordinatesConverter } from 'src/components/viewers/SheetsViewerV2/utils/apiCoordinatesConverter';
import {
  CalibrationUnit,
  CreateCalibrationDocument,
  SheetScaleType,
  UpdateCalibrationDocument,
} from 'src/gql/graphql';
import { Sheet } from 'src/hooks/sheets';

type Props = {
  aggregatedView: AggregatedView | null;
  sheets: Sheet[];
  activeSheet: ActiveSheet;
  pagination: ActiveSheetPagination;
  activeTool: ActiveTool;
  setActiveTool: Dispatch<SetStateAction<ActiveTool>>;
};

export type CreateOrUpdateExistingCalibration = (
  length: number,
  unit: CalibrationUnit
) => Promise<void>;
export type ExistingCalibration = SheetScaleType | null;
export type CalibrationTool = {
  isCalibrating: boolean;
  isComplete: boolean;
  isUpdating: boolean;
  enable: () => void;
  disable: () => void;
};

export type SheetViewerV2Calibration = {
  existing: ExistingCalibration;
  tool: CalibrationTool;
  createOrUpdate: CreateOrUpdateExistingCalibration;
};

export function useCalibration({
  aggregatedView,
  pagination,
  sheets,
  activeSheet,
  activeTool,
  setActiveTool,
}: Props): SheetViewerV2Calibration {
  const [points, setPoints] = useState<THREE.Vector3[]>([]);
  const isComplete = useMemo(() => points.length === 2, [points.length]);
  const { isCalibrating, enable, disable } = useCalibrationTool({
    activeTool,
    setActiveTool,
    viewer: aggregatedView?.viewer,
    initPointA: points[0],
    initPointB: points[1],
    onChange: (pointA, pointB) => {
      if (pointA && pointB) {
        setPoints([pointA, pointB]);
      } else if (pointA) {
        setPoints([pointA]);
      } else {
        setPoints([]);
      }
    },
  });

  const existingCalibration = useMemo(() => {
    const pageCalibrations =
      sheets.find(({ id }) => id === activeSheet.data?.id)
        ?.sheetPageCalibrations || [];

    return (
      pageCalibrations.find(
        (where) => where.pageNumber === pagination.pageNumber
      ) || null
    );
  }, [activeSheet.data?.id, pagination.pageNumber, sheets]);

  const [createCalibrationResults, createCalibration] = useMutation(
    CreateCalibrationDocument
  );
  const [updateCalibrationResults, updateCalibration] = useMutation(
    UpdateCalibrationDocument
  );
  const createOrUpdate: CreateOrUpdateExistingCalibration = useCallback(
    async (length, unit) => {
      if (!activeSheet.data || !aggregatedView?.viewer) {
        return;
      }

      const converter = new ApiCoordinatesConverter(
        aggregatedView.viewer,
        activeSheet.data.viewport
      );
      const pointA = converter.convertToApiPoint(points[0]);
      const pointB = converter.convertToApiPoint(points[1]);
      const calibration = {
        destinationUnit: unit,
        destinationWidth: length,
        pointA,
        pointB,
      };

      if (!existingCalibration) {
        await createCalibration({
          input: {
            sheetPageCalibration: {
              sheetId: activeSheet.data.id,
              pageNumber: pagination.pageNumber,
              calibration: calibration,
            },
          },
        });
      } else {
        await updateCalibration({
          input: {
            sheetId: activeSheet.data.id,
            pageNumber: pagination.pageNumber,
            patch: { calibration },
          },
        });
      }
    },
    [
      activeSheet.data,
      aggregatedView?.viewer,
      createCalibration,
      existingCalibration,
      pagination.pageNumber,
      points,
      updateCalibration,
    ]
  );

  const isUpdating = useMemo(
    () =>
      createCalibrationResults.fetching ||
      updateCalibrationResults.fetching ||
      false,
    [createCalibrationResults.fetching, updateCalibrationResults.fetching]
  );

  useEffect(() => {
    setPoints([]);
    return () => disable();
  }, [activeSheet.data?.id]);

  useEffect(() => {
    if (!aggregatedView?.viewer || !activeSheet.data) {
      return;
    }

    if (existingCalibration?.calibration && points.length === 0) {
      const converter = new ApiCoordinatesConverter(
        aggregatedView.viewer,
        activeSheet.data.viewport
      );
      const { pointA, pointB } = existingCalibration.calibration;
      const convertedA = converter.convertToAutodeskPoint(pointA);
      const convertedB = converter.convertToAutodeskPoint(pointB);

      setPoints([convertedA, convertedB]);
    }
  }, [
    aggregatedView?.viewer?.model,
    activeSheet.data?.id,
    sheets,
    points,
    pagination.pageNumber,
    existingCalibration?.calibration,
  ]);

  return {
    createOrUpdate,
    existing: existingCalibration?.calibration || null,
    tool: {
      isCalibrating,
      isComplete,
      isUpdating,
      enable,
      disable: () => {
        setPoints([]);
        disable();
      },
    },
  };
}
