import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  faCheck,
  faCircleDot,
  faCopy,
  faDrawPolygon,
  faEllipsisH,
  faFolderMinus,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { useMutation } from 'urql';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PolylineIcon } from '../../assets/icons/draw-polyline.svg';
import {
  CreateSheetShapeDocument,
  CreateSheetShapeInput,
  DeleteShapeDocument,
  DeleteSheetShapeDocument,
  SheetShapeDeepFragment,
  UpdateSheetShapeDocument,
} from '../../gql/graphql';
import { useShapes } from '../../hooks/shapes';
import { useSheetShapes } from '../../hooks/sheet-shapes';
import { getSheetShapeUrn } from '../viewers/common/hooks/useSheetShapesManager';
import { useSheetContextMenuContext } from '../../services/viewer/SheetsContextMenu';
import { useSelection } from '../../services/viewer/selection';
import ConfirmDeletionModal from '../common/ConfirmDeletionModal';
import { useSheetViewer } from '../common/SheetViewer';
import SparkelIcon from '../common/icon/SparkelIcon';
import { ShapeItemProps } from './ShapeItem';
import { ToggleShapeVisibilityButton } from './ToggleShapeVisibilityButton';
import { useUserTenant } from 'src/services/auth-info';

// eslint-disable-next-line complexity
export function SheetShapeItem({
  shape,
  existingShapes,
  readOnly,
  onChange,
  index,
}: ShapeItemProps & {
  shape: SheetShapeDeepFragment;
}) {
  const {
    isOpen: showDeleteShapeModal,
    onOpen: onDeleteShapeModalOpen,
    onClose: onDeleteShapeModalClose,
  } = useDisclosure();

  const {
    isOpen: showDeleteSelectedShapesModal,
    onOpen: onDeleteSelectedShapesModalOpen,
    onClose: onDeleteSelectedShapesModalClose,
  } = useDisclosure();

  const {
    drawing: { isDrawing, shapeToEdit, editShape },
  } = useSheetShapes();

  const { getDuplicatedShapeName } = useShapes(shape.projectId);

  const {
    deselectDbIds,
    selectedShapes: selected3dShapes,
    selectedSheetShapes,
  } = useSelection();

  const { register, handleSubmit } = useForm<{ name: string }>({
    defaultValues: { name: shape.name },
  });
  const toast = useToast();

  const { tenant, isProtan } = useUserTenant();

  const [isRenaming, setIsRenaming] = useState(false);

  const [, createShapeMutation] = useMutation(CreateSheetShapeDocument);
  const [updateShapeResult, updateShapeMutation] = useMutation(
    UpdateSheetShapeDocument
  );

  const [, deleteShapeMutation] = useMutation(DeleteShapeDocument);
  const [, deleteSheetShapeMutation] = useMutation(DeleteSheetShapeDocument);

  const { registerItem, unregisterItem } = useSheetContextMenuContext();

  const shapeUrn = shape.urn;
  const {
    visibilityManager: { hiddenElements, hide, show },
  } = useSheetViewer();

  const { t } = useTranslation('project');

  const isHidden =
    shapeUrn in hiddenElements && hiddenElements[shapeUrn].includes(shape.dbId);
  const isEditingThisShape = shapeToEdit === shape.id;

  const duplicateShape = async () => {
    const shapeName = getDuplicatedShapeName(shape.name);
    const input = getSheetShapeInput(shape, shapeName);

    const result = await createShapeMutation({
      input: input,
    });

    if (result.error) {
      console.error(result.error);
      toast({
        status: 'error',
        title: t('shapes.error.duplicate.title'),
        description: t('shapes.error.duplicate.description'),
      });
    } else {
      toast({ title: t('shape.success.duplicate.title'), status: 'success' });
    }
  };

  const removeFromFolder = async () => {
    const result = await updateShapeMutation({
      input: { id: shape.id, patch: { folder: null } },
    });

    if (result.error) {
      console.error(result.error);
      toast({
        status: 'error',
        title: t('shapes.error.remove-from-folder.title'),
        description: t('shapes.error.remove-from-folder.description'),
      });
    }
  };

  const deleteShape = useCallback(async () => {
    const result = await deleteSheetShapeMutation({ input: { id: shape.id } });

    if (result.error) {
      toast({
        status: 'error',
        title: t('shapes.error.delete.title'),
        description: t('shapes.error.delete.description'),
      });
      throw result.error;
    } else {
      toast({
        title: t('shapes.success.delete.title'),
        status: 'success',
      });

      deselectDbIds({ [shapeUrn]: [shape.dbId] });
    }
  }, [
    deleteSheetShapeMutation,
    deselectDbIds,
    shape.dbId,
    shape.id,
    shapeUrn,
    t,
    toast,
  ]);

  const selectedShapes = useMemo(
    () =>
      existingShapes.filter((shape) => {
        const urn = shape.urn;
        const selectedDbIds = selected3dShapes[urn] || selectedSheetShapes[urn];
        return selectedDbIds?.includes(shape.dbId);
      }),
    [existingShapes, selected3dShapes, selectedSheetShapes]
  );
  const deleteSelectedShapes = useCallback(async () => {
    const deleteTasks = selectedShapes.map((shape) => {
      if (shape.__typename === 'Shape') {
        return deleteShapeMutation({
          input: { id: shape.id },
        });
      } else if (shape.__typename === 'SheetShape') {
        return deleteSheetShapeMutation({
          input: { id: shape.id },
        });
      } else {
        throw new Error('Unknown shape type');
      }
    });

    try {
      await Promise.all(deleteTasks);
      toast({
        status: 'success',
        title: t('shapes.success.delete-multiple.title'),
        position: 'top',
        duration: 2500,
      });
      deselectDbIds({ [shapeUrn]: selectedShapes.map((s) => s.dbId) });
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        title: t('shapes.error.delete-multiple.title'),
        position: 'top',
      });
    }
  }, [
    deleteShapeMutation,
    deleteSheetShapeMutation,
    deselectDbIds,
    selectedShapes,
    shapeUrn,
    t,
    toast,
  ]);

  const onSubmitShapeName = handleSubmit(async ({ name }) => {
    const { error } = await updateShapeMutation({
      input: {
        id: shape.id,
        patch: {
          name,
        },
      },
    });
    if (error) {
      console.error(error);
      toast({
        title: t('shapes.error.rename.title'),
        status: 'error',
      });
    } else {
      setIsRenaming(false);
      toast({
        title: t('shapes.success.rename.title'),
        status: 'success',
      });
    }
  });

  useEffect(() => {
    if (readOnly) {
      return;
    }

    registerItem({
      id: `edit-shape-${shape.id}`,
      label: t('shapes.edit-shape'),
      group: 'shapes',
      icon: <SparkelIcon size="sm" icon={faPen} fixedWidth />,
      hidden: (params) => {
        if (typeof params.props?.target === 'undefined') {
          return true;
        }

        return !(
          params.props.target.dbId === shape.dbId &&
          params.props.target.modelUrn === shapeUrn
        );
      },
      onClick: () => editShape(shape.id),
    });
    registerItem({
      id: `delete-shape-${shape.id}`,
      label: t('shapes.delete'),
      group: 'shapes',
      icon: (
        <SparkelIcon size="sm" icon={faTrash} color={'red.400'} fixedWidth />
      ),
      hidden: (params) => {
        if (typeof params.props?.target === 'undefined') {
          return true;
        }

        return !(
          params.props.target.dbId === shape.dbId &&
          params.props.target.modelUrn === shapeUrn
        );
      },
      onClick: onDeleteShapeModalOpen,
    });
    return () => {
      unregisterItem(`edit-shape-${shape.id}`);
      unregisterItem(`delete-shape-${shape.id}`);
    };
  }, [
    editShape,
    onDeleteShapeModalOpen,
    readOnly,
    registerItem,
    shape.dbId,
    shape.id,
    shapeUrn,
    t,
    unregisterItem,
  ]);

  const getShapeIcon = useCallback(() => {
    let shapeIcon;
    let iconColor: [string, string] = [
      isHidden ? 'gray.300' : 'gray.400',
      isHidden ? 'gray.600' : 'gray.500',
    ];

    if (shape.sheetShapePolygon) {
      shapeIcon = <SparkelIcon icon={faDrawPolygon} color={iconColor} />;
    } else if (shape.sheetShapeLine) {
      shapeIcon = <SparkelIcon as={PolylineIcon} color={iconColor} />;
    } else if (shape.sheetShapePoint) {
      shapeIcon = <SparkelIcon icon={faCircleDot} color={iconColor} />;
    }

    return <Box px={2}>{shapeIcon}</Box>;
  }, [
    isHidden,
    shape.sheetShapeLine,
    shape.sheetShapePolygon,
    shape.sheetShapePoint,
  ]);

  return (
    <>
      {isEditingThisShape ? (
        <Box w={6} display="block" textAlign="center">
          <SparkelIcon icon={faPen} size="sm" color="teal.400"></SparkelIcon>
        </Box>
      ) : (
        getShapeIcon()
      )}

      {isRenaming ? (
        <form onSubmit={onSubmitShapeName} id="rename-shape-form">
          <Input
            {...register('name', {
              required: true,
            })}
            aria-label="Rename shape"
            borderRadius={0}
            p={0}
            size={'sm'}
            height="auto"
            autoFocus={true}
          />
        </form>
      ) : (
        <Flex
          textAlign="left"
          flexGrow={1}
          py={1}
          my={-1}
          fontSize="sm"
          color={
            isEditingThisShape ? 'teal.400' : isHidden ? 'gray.400' : undefined
          }
          onClick={(e) => onChange(e, index)}
          cursor={!isEditingThisShape ? 'pointer' : undefined}
        >
          <Tooltip label={shape.name} openDelay={400}>
            <Text noOfLines={1} wordBreak={'break-all'} maxWidth={'100%'}>
              {isEditingThisShape
                ? `${t('shapes.editing-prefix')} ${shape.name}`
                : shape.name}
            </Text>
          </Tooltip>
        </Flex>
      )}
      {isRenaming ? (
        <IconButton
          aria-label="submit row"
          isRound
          size="xs"
          colorScheme="brand"
          type="submit"
          form="rename-shape-form"
          isLoading={updateShapeResult.fetching}
          icon={<SparkelIcon icon={faCheck} color={['gray.50', 'gray.700']} />}
        />
      ) : !readOnly ? (
        <HStack height={6} gap={0}>
          <Menu closeOnSelect={false} isLazy>
            {
              // eslint-disable-next-line complexity
              ({ isOpen }) => (
                <>
                  <MenuButton
                    aria-label={`Shape ${shape.name} options`}
                    opacity={isOpen ? 1 : 0}
                    position={isOpen ? 'initial' : 'absolute'}
                    _groupHover={{
                      opacity: isEditingThisShape ? 0 : 1,
                      position: isEditingThisShape ? 'absolute' : 'initial',
                    }}
                    _groupActive={{
                      display: isEditingThisShape ? 'none' : 'inline-block',
                      position: isEditingThisShape ? 'absolute' : 'initial',
                    }}
                    ml={'auto'}
                    isRound
                    as={IconButton}
                    variant={'ghost'}
                    colorScheme="gray"
                    size={'xs'}
                    maxWidth={16}
                    mr={1}
                  >
                    <SparkelIcon icon={faEllipsisH} />
                  </MenuButton>

                  <Portal>
                    <MenuList maxW="xs" zIndex={2}>
                      {!isProtan && (
                        <MenuItem
                          icon={<SparkelIcon icon={faPen} fixedWidth />}
                          onClick={() => setIsRenaming(true)}
                          closeOnSelect={true}
                        >
                          {t('shapes.rename-shape')}
                        </MenuItem>
                      )}
                      <MenuItem
                        icon={<SparkelIcon icon={faDrawPolygon} fixedWidth />}
                        onClick={() => editShape(shape.id)}
                        closeOnSelect={true}
                        isDisabled={isDrawing}
                      >
                        {t('shapes.edit-shape')}
                      </MenuItem>
                      {!isProtan && (
                        <MenuItem
                          icon={<SparkelIcon icon={faCopy} fixedWidth />}
                          closeOnSelect={true}
                          onClick={duplicateShape}
                        >
                          {t('shapes.duplicate-shape')}
                        </MenuItem>
                      )}
                      {shape.folder && (
                        <MenuItem
                          icon={<SparkelIcon icon={faFolderMinus} fixedWidth />}
                          onClick={removeFromFolder}
                        >
                          {t('shapes.remove-from-folder')}
                        </MenuItem>
                      )}
                      {selectedShapes.length > 0 ? (
                        <MenuItem
                          icon={
                            <SparkelIcon
                              icon={faTrash}
                              color={'red.400'}
                              fixedWidth
                            />
                          }
                          textColor={'red.400'}
                          onClick={async (e) => {
                            e.stopPropagation();
                            onDeleteSelectedShapesModalOpen();
                          }}
                        >
                          {t('shapes.delete-multiple')}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          icon={
                            <SparkelIcon
                              icon={faTrash}
                              color={'red.400'}
                              fixedWidth
                            />
                          }
                          textColor={'red.400'}
                          onClick={async (e) => {
                            e.stopPropagation();
                            onDeleteShapeModalOpen();
                          }}
                        >
                          {t('shapes.delete')}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Portal>
                </>
              )
            }
          </Menu>
          <Box
            opacity={isHidden && !isEditingThisShape ? 1 : 0}
            display={'inline-block'}
            _groupHover={{
              opacity: isEditingThisShape ? 0 : 1,
            }}
            _groupActive={{
              opacity: isEditingThisShape ? 0 : 1,
            }}
          >
            <ToggleShapeVisibilityButton
              isHidden={isHidden}
              hideShape={() =>
                hide({
                  [shapeUrn]:
                    selectedShapes.length > 0
                      ? selectedShapes.map(
                          (selectedShape) => selectedShape.dbId
                        )
                      : [shape.dbId],
                })
              }
              showShape={() =>
                show({
                  [shapeUrn]:
                    selectedShapes.length > 0
                      ? selectedShapes.map(
                          (selectedShape) => selectedShape.dbId
                        )
                      : [shape.dbId],
                })
              }
            />
          </Box>
        </HStack>
      ) : null}

      <ConfirmDeletionModal
        onConfirm={deleteShape}
        isOpen={showDeleteShapeModal}
        onClose={onDeleteShapeModalClose}
      ></ConfirmDeletionModal>
      <ConfirmDeletionModal
        onConfirm={deleteSelectedShapes}
        isOpen={showDeleteSelectedShapesModal}
        onClose={onDeleteSelectedShapesModalClose}
        itemsToDelete={selectedShapes.map((e) => e.name)}
      />
    </>
  );
}

function getSheetShapeInput(
  shape: SheetShapeDeepFragment,
  name: string
): CreateSheetShapeInput {
  const geometry = getSheetGeometryInput(shape);
  return {
    sheetShape: {
      name,
      sheetId: shape.sheetId,
      sheetPageNumber: shape.sheetPageNumber,
      projectId: shape.projectId,
      urn: getSheetShapeUrn(shape.projectId),
      ...geometry,
    },
  };
}

function getSheetGeometryInput(shape: SheetShapeDeepFragment) {
  if (shape.sheetShapePolygon) {
    return {
      sheetShapePolygon: {
        create: [
          {
            multipolygon: shape.sheetShapePolygon.multipolygon,
          },
        ],
      },
    };
  } else if (shape.sheetShapePoint) {
    return {
      sheetShapePoint: {
        create: [
          {
            point: shape.sheetShapePoint.point,
          },
        ],
      },
    };
  } else {
    invariant(shape.sheetShapeLine, 'Shape does not have geometry');
    return {
      sheetShapeLine: {
        create: [
          {
            points: shape.sheetShapeLine.points,
          },
        ],
      },
    };
  }
}
