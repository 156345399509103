import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import awsExports from 'src/aws-exports';
import { Sheet } from 'src/hooks/sheets';

export const getSheetUrl = async (
  s3Client: S3Client,
  sheets: Sheet[],
  sheetId: string
) => {
  const activeSheet = sheets?.find((sheet) => sheet.id === sheetId);

  if (!activeSheet) {
    return null;
  }

  const getObjectCommand = new GetObjectCommand({
    Bucket: awsExports.attachments_bucket_name,
    Key: activeSheet.s3Key,
  });

  return getSignedUrl(s3Client, getObjectCommand, { expiresIn: 3600 });
};
