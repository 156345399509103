import Edit2D = Autodesk.Extensions.Edit2D;
import GuiViewer3D = Autodesk.Viewing.GuiViewer3D;
import Viewer3D = Autodesk.Viewing.Viewer3D;
import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { ApiCoordinatesConverter } from 'src/components/viewers/SheetsViewerV2/utils/apiCoordinatesConverter';
import { SheetShapeDeepFragment } from 'src/gql/graphql';

export interface ISheetShape {
  get apiModel(): SheetShapeDeepFragment;

  get urn(): string;

  get dbId(): number;

  draw(): void;

  remove(): void;

  hitTest(x: number, y: number): boolean;

  setSelected(selected: boolean): void;

  setHovered(hovered: boolean): void;

  equals(model?: ISheetShape | null): boolean;

  getLabels(): { point: THREE.Vector3; label: string }[];
}

export enum SheetShapeStyleType {
  default = 'default',
  hovered = 'hovered',
  selected = 'selected',
}

export abstract class AbstractSheetShape implements ISheetShape {
  protected isHovered = false;
  protected isSelected = false;
  protected textLabelPoints: { x: number; y: number }[] = [];
  protected shapes: Autodesk.Edit2D.Shape[] = [];

  protected constructor(
    protected readonly viewer: Viewer3D | GuiViewer3D,
    protected readonly edit2d: Edit2D,
    protected readonly activeSheetData: ActiveSheetData,
    public readonly apiModel: SheetShapeDeepFragment
  ) {}

  get urn() {
    return this.apiModel.urn;
  }

  get dbId() {
    return this.apiModel.dbId;
  }

  public equals(model?: ISheetShape | null) {
    return (
      this.apiModel.projectId === model?.apiModel.projectId &&
      this.apiModel.sheetId === model?.apiModel.sheetId &&
      this.apiModel.sheetPageNumber === model?.apiModel.sheetPageNumber &&
      this.apiModel.id === model?.apiModel.id
    );
  }

  public hitTest(x: number, y: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.shapes.some((shape) => shape.hitTest(x, y));
  }

  public draw(): void {
    this.edit2d.defaultContext.layer.addShapes(this.shapes);
  }

  public remove(): void {
    this.edit2d.defaultContext.layer.removeShapes(this.shapes);
  }

  public setSelected(selected = true) {
    this.isSelected = selected;

    if (selected) {
      this.setStyle(SheetShapeStyleType.selected);
    } else {
      this.setStyle(SheetShapeStyleType.default);
    }

    this.remove();
    this.draw();
  }

  public setHovered(hovered = true) {
    this.isHovered = hovered;

    if (this.isSelected) {
      return;
    } else if (hovered) {
      this.setStyle(SheetShapeStyleType.hovered);
    } else {
      this.setStyle(SheetShapeStyleType.default);
    }

    this.remove();
    this.draw();
  }

  public getLabels() {
    return this.textLabelPoints.map((point) => ({
      point: this.viewer.worldToClient(new THREE.Vector3(point.x, point.y, 0)),
      label: this.apiModel.name,
    }));
  }

  protected convertPointToRender(apiPoint: { x: number; y: number }) {
    const converter = new ApiCoordinatesConverter(
      this.viewer,
      this.activeSheetData.viewport
    );
    const converted = converter.convertToAutodeskPoint(apiPoint);

    return { x: converted.x, y: converted.y };
  }

  protected setStyle(type: SheetShapeStyleType) {
    throw new Error(`Unimplemented method .setStyle()`);
  }
}
