import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { PDFPageViewport } from 'src/components/viewers/SheetsViewerV2/mappers/mapPDFData';
import {
  INCHES_IN_ONE_METER,
  PDF_COORDINATE_MAPPING_DPI,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';

export type ApiPoint = { x: number; y: number };

type Transform = [number, number, number, number, number, number];
type Point = [ApiPoint['x'], ApiPoint['y']];

export class ApiCoordinatesConverter {
  constructor(
    private readonly viewer: AggregatedView['viewer'],
    private readonly pageViewport: PDFPageViewport
  ) {}

  convertToAutodeskPoint(point: ApiPoint): THREE.Vector3 {
    const transform = this._getTransform();
    const transformed = this._applyTransform([point.x, point.y], transform);

    return new THREE.Vector3(transformed[0], transformed[1], 0);
  }

  convertToApiPoint(point: THREE.Vector3): ApiPoint {
    const transform = this._getTransform();
    const transformed = this._applyInverseTransform(
      [point.x, point.y],
      transform
    );

    return { x: transformed[0], y: transformed[1] };
  }

  private _getScale() {
    const unitScaleToMeters = this.viewer.model?.getUnitScale() || 1;
    const scale =
      (INCHES_IN_ONE_METER * unitScaleToMeters) / PDF_COORDINATE_MAPPING_DPI;

    return { scale };
  }

  private _getViewBoxCenter() {
    const { viewBox } = this.pageViewport;
    const centerX = (viewBox[2] + viewBox[0]) / 2;
    const centerY = (viewBox[3] + viewBox[1]) / 2;

    return { centerX, centerY };
  }

  private _getNormalizedRotation() {
    const { rotation } = this.pageViewport;

    return Number.isFinite(this.pageViewport.rotation)
      ? ((rotation % 360) + 360) % 360
      : 0;
  }

  private _getTransformRotation() {
    const rotation = this._getNormalizedRotation();
    let rotateA, rotateB, rotateC, rotateD;

    switch (rotation) {
      case 180:
        rotateA = -1;
        rotateB = 0;
        rotateC = 0;
        rotateD = -1;
        break;
      case 90:
        rotateA = 0;
        rotateB = 1;
        rotateC = -1;
        rotateD = 0;
        break;
      case 270:
        rotateA = 0;
        rotateB = -1;
        rotateC = 1;
        rotateD = 0;
        break;
      case 0:
        rotateA = 1;
        rotateB = 0;
        rotateC = 0;
        rotateD = 1;
        break;
      default:
        throw new Error(
          'PageViewport: Invalid rotation, must be a multiple of 90 degrees.'
        );
    }

    return { rotateA, rotateB, rotateC, rotateD };
  }

  private _getTransform(): Transform {
    const { viewBox } = this.pageViewport;
    const { centerX, centerY } = this._getViewBoxCenter();
    const { rotateA, rotateB, rotateC, rotateD } = this._getTransformRotation();
    const { scale } = this._getScale();
    const [offsetCanvasX, offsetCanvasY] = [
      rotateA === 0
        ? Math.abs(centerY - viewBox[1]) * scale
        : Math.abs(centerX - viewBox[0]) * scale,
      rotateA === 0
        ? Math.abs(centerX - viewBox[0]) * scale
        : Math.abs(centerY - viewBox[1]) * scale,
    ];

    return [
      rotateA * scale,
      rotateB * scale,
      rotateC * scale,
      rotateD * scale,
      offsetCanvasX - rotateA * scale * centerX - rotateC * scale * centerY,
      offsetCanvasY - rotateB * scale * centerX - rotateD * scale * centerY,
    ];
  }

  private _applyTransform(p: Point, t: Transform) {
    const xt = p[0] * t[0] + p[1] * t[2] + t[4];
    const yt = p[0] * t[1] + p[1] * t[3] + t[5];
    return [xt, yt];
  }

  private _applyInverseTransform(p: Point, t: Transform) {
    const d = t[0] * t[3] - t[1] * t[2];
    const xt = (p[0] * t[3] - p[1] * t[2] + t[2] * t[5] - t[4] * t[3]) / d;
    const yt = (-p[0] * t[1] + p[1] * t[0] + t[4] * t[1] - t[5] * t[0]) / d;

    return [xt, yt];
  }
}
