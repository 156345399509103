import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'urql';
import { ShapeDrawingMode } from '../../SheetShapeDrawing';
import {
  ForgeAttributePredicateType,
  OrderDeepFragment,
  PredicateType,
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
  CreateOrderEntryFromTemplateDocument,
  GetOrderEntryTemplatesPageDocument,
} from 'src/gql/graphql';
import { useProtanProjectSteps } from 'src/components/common/ProtanProjectStepsProvider';
import {
  getInsulationAndFasteners,
  getTemplateForSku,
} from 'src/components/orderContractor/ProductChoices';

export function useInsulationHandler(
  order: OrderDeepFragment | null,
  activateDrawing: (mode: ShapeDrawingMode) => void
) {
  const { setStepStatus } = useProtanProjectSteps();
  const [{ data: resourceTemplates }] = useQuery({
    query: GetOrderEntryTemplatesPageDocument,
  });
  const [, createOrderEntryFromTemplate] = useMutation(
    CreateOrderEntryFromTemplateDocument
  );

  const orderLabels: { key: string; value: string }[] = useMemo(
    () => (order?.labels ? JSON.parse(order.labels) : []),
    [order?.labels]
  );

  // eslint-disable-next-line complexity
  const handleUseFlatInsulation = useCallback(async () => {
    if (!order) {
      return;
    }
    // Transition logic
    setStepStatus(
      ProtanProjectStepEnum.DrainLinePlacement,
      ProtanProjectStepStatusEnum.Completed
    );
    setStepStatus(
      ProtanProjectStepEnum.DrainPlacement,
      ProtanProjectStepStatusEnum.InProgress
    );
    activateDrawing(ShapeDrawingMode.Point);

    // Product creation logic
    const energyRequirement = orderLabels
      .find((label) => label.key === 'Energy requirements')
      ?.value.slice(0, 4);
    const location = orderLabels.find(
      (label) => label.key === 'Location'
    )?.value;
    const fireRequirement = orderLabels.find(
      (label) => label.key === 'Fire requirements'
    )?.value;
    const substrate = orderLabels.find(
      (label) => label.key === 'Substrate'
    )?.value;

    if (energyRequirement && location && fireRequirement && substrate) {
      const insulationproducts = getInsulationAndFasteners(
        energyRequirement,
        fireRequirement,
        location,
        substrate
      );
      if (Array.isArray(insulationproducts)) {
        const productsToAdd = insulationproducts;

        for (const product of productsToAdd) {
          const template = resourceTemplates
            ? getTemplateForSku(
                product.sku,
                resourceTemplates,
                product.partOfCombustible
              )
            : undefined;
          if (template) {
            if (template.elementIdentifiersQuery) {
              const orderIdPredicate: ForgeAttributePredicateType = {
                attribute: {
                  __typename: undefined,
                  category: 'Sparkel Attributes',
                  name: 'OrderId',
                },
                attributeValues: [order.id],
                predicate: PredicateType.AnyOf,
              };
              template.elementIdentifiersQuery.predicates =
                template.elementIdentifiersQuery.predicates.concat(
                  orderIdPredicate
                );
            }
            await createOrderEntryFromTemplate({
              input: {
                orderEntryTemplateId: template.id,
                orderId: order.id,
                title: template.name,
                color: '#FFFFFF',
                query: template.elementIdentifiersQuery || undefined,
                dbids: null,
              },
            });
          } else {
            console.error('Template not found for sku', product.sku);
          }
        }
      } else {
        console.error(
          'Could not find product match for insulation / fasteners'
        );
      }
    }
  }, [
    activateDrawing,
    createOrderEntryFromTemplate,
    order,
    orderLabels,
    resourceTemplates,
    setStepStatus,
  ]);

  return { handleUseFlatInsulation };
}
