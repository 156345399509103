import { MultiPolygon2Algorithm } from '../../algorithm-types';
import {
  MultiPolygon2,
  MultiPolyline2Result,
  Polyline2,
  ResultType,
} from '../../geometric-types';
import { getOrientedBoundingBox } from '../util/polygon';

export const calculateSheetShapeBoundingBoxWidth: MultiPolygon2Algorithm<
  MultiPolyline2Result
> = (shape: MultiPolygon2) => {
  // For multipolygon, we need to process each polygon and find the one with smallest width
  let minWidth = Infinity;
  let widthLines: Polyline2[] = [];

  // Process each polygon in the multipolygon
  for (let i = 0; i < shape.polygons.length; i++) {
    const currentPolygon = shape.polygons[i];

    // Get the oriented bounding box for the current polygon
    const orientedBoundingBox = getOrientedBoundingBox(currentPolygon);

    // Extract the coordinates of the oriented bounding box
    const coordinates = orientedBoundingBox.exterior;

    // Calculate the lengths of the sides
    const side1 = Math.hypot(
      coordinates[1][0] - coordinates[0][0],
      coordinates[1][1] - coordinates[0][1]
    );
    const side2 = Math.hypot(
      coordinates[2][0] - coordinates[1][0],
      coordinates[2][1] - coordinates[1][1]
    );

    // Determine the width (smaller dimension)
    const width = Math.min(side1, side2);

    // If this polygon has a smaller width, update our result
    if (width < minWidth) {
      minWidth = width;

      // Create lines representing the width sides of the bounding box
      widthLines = [];
      if (side1 < side2) {
        // If side1 is smaller, add the two width lines
        widthLines.push([
          [coordinates[0][0], coordinates[0][1]],
          [coordinates[1][0], coordinates[1][1]],
        ]);
      } else {
        // If side2 is smaller, add the two height lines
        widthLines.push([
          [coordinates[1][0], coordinates[1][1]],
          [coordinates[2][0], coordinates[2][1]],
        ]);
      }
    }
  }

  return {
    type: ResultType.MULTIPOLYLINE2,
    value: minWidth,
    multiPolyline: { lines: widthLines },
  };
};
