import {
  AbstractSheetShape,
  SheetShapeStyleType,
} from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import {
  DefaultLineStyle,
  HoveredLineStyle,
  SelectedLineStyle,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';

export abstract class AbstractSheetLinedShape extends AbstractSheetShape {
  protected setStyle(type: SheetShapeStyleType) {
    this.shapes.forEach((shape) => {
      switch (type) {
        case SheetShapeStyleType.default:
          shape.style.lineWidth = DefaultLineStyle.lineWidth;
          shape.style.lineColor = DefaultLineStyle.lineColor;
          break;
        case SheetShapeStyleType.hovered:
          shape.style.lineWidth = HoveredLineStyle.lineWidth;
          shape.style.lineColor = HoveredLineStyle.lineColor;
          break;
        case SheetShapeStyleType.selected:
          shape.style.lineWidth = SelectedLineStyle.lineWidth;
          shape.style.lineColor = SelectedLineStyle.lineColor;
          break;
      }
    });
  }
}
