import GuiViewer3D = Autodesk.Viewing.GuiViewer3D;
import Viewer3D = Autodesk.Viewing.Viewer3D;

export function upsertTextLabel(
  viewer: Viewer3D | GuiViewer3D,
  divId: string,
  {
    point,
    label,
    urn,
  }: {
    point: THREE.Vector3;
    urn: string;
    label: string;
  }
) {
  const labelsContainer =
    (viewer.container.querySelector(`#${divId}`) as HTMLDivElement) ||
    document.createElement('div');
  const labelElement =
    (labelsContainer.querySelector(
      `label[data-urn="${urn}"]`
    ) as HTMLLabelElement) || document.createElement('label');

  if (
    (labelsContainer.parentNode as HTMLDivElement)?.className !==
    viewer.container.className
  ) {
    viewer.container.appendChild(labelsContainer);

    labelsContainer.style.position = 'absolute';
    labelsContainer.style.left = '0';
    labelsContainer.style.top = '0';
    labelsContainer.style.width = '100%';
    labelsContainer.style.pointerEvents = 'none';
    labelsContainer.id = divId;
  }

  if ((labelElement.parentNode as HTMLDivElement)?.id !== labelsContainer.id) {
    labelsContainer.appendChild(labelElement);
  }

  labelElement.innerText = label;
  labelElement.style.position = 'absolute';
  labelElement.style.color = 'black';
  labelElement.style.fontWeight = 'bold';
  labelElement.style.textAlign = 'center';
  labelElement.style.left = `${point.x - labelElement.offsetWidth / 2}px`;
  labelElement.style.top = `${point.y - labelElement.offsetHeight / 2}px`;
  labelElement.setAttribute('data-urn', urn);
}

export function clearTextLabels(viewer: Viewer3D | GuiViewer3D, divId: string) {
  const labels = viewer.container
    .querySelector(`#${divId}`)
    ?.querySelectorAll('label');

  labels?.forEach((label) => {
    label.parentElement?.removeChild(label);
  });
}
