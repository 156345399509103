import { Map as IMap } from 'immutable';
import { useCallback, useMemo, useState } from 'react';
import { SheetShapesMap } from 'src/components/common/ForgeViewer';
import { SheetShapeDeepFragment } from 'src/gql/graphql';

export type SheetShapesManager = {
  renderSheetShapes: (urn: string, shapes: SheetShapeDeepFragment[]) => void;
  unrenderSheetShapes: (urn: string) => void;
  renderedSheetShapes: SheetShapesMap;
};

// Unique identifier for all sheet shapes for a project
// Using btoa to match the base64 format for BIM shape urns, allthough it is not necessary
export const getSheetShapeUrn = (projectId: string) => {
  return btoa('sheet-shape-' + projectId);
};

// Hook for managing which sheet shapes to render in the viewer
export function useSheetShapesManager(): SheetShapesManager {
  const [sheetShapesByUrn, setSheetShapesByUrn] = useState<
    IMap<string, SheetShapeDeepFragment[]>
  >(IMap());

  const renderSheetShapes = useCallback(
    (urn: string, shapes: SheetShapeDeepFragment[]) => {
      setSheetShapesByUrn((currentShapes) => currentShapes.set(urn, shapes));
    },
    []
  );

  const unrenderSheetShapes = useCallback((urn: string) => {
    setSheetShapesByUrn((currentShapes) => currentShapes.remove(urn));
  }, []);

  const renderedSheetShapes = useMemo(
    () => sheetShapesByUrn.toObject(),
    [sheetShapesByUrn]
  );

  return useMemo(
    () => ({
      renderSheetShapes,
      unrenderSheetShapes,
      renderedSheetShapes,
    }),
    [renderSheetShapes, unrenderSheetShapes, renderedSheetShapes]
  );
}
