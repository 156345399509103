import { useEffect, useRef, useState } from 'react';
import {
  buildAggregatedViewInitOptions,
  ViewerExtension,
} from 'src/components/viewers/common/utils/autodesk';

export type AggregatedView = Autodesk.Viewing.AggregatedView;

export function useSheetsAggregatedView() {
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [aggregatedView, setAggregatedView] = useState<AggregatedView | null>(
    null
  );

  useEffect(() => {
    if (viewerRef.current === null) return;

    const view = new Autodesk.Viewing.AggregatedView();

    view
      .init(viewerRef.current, buildAggregatedViewInitOptions())
      .then(() =>
        Promise.all([
          view.viewer.loadExtension(ViewerExtension.Snapping),
          view.viewer.loadExtension(ViewerExtension.PDF),
          view.viewer.loadExtension(ViewerExtension.Edit2D),
          view.viewer.loadExtension(ViewerExtension.SceneBuilder),
        ])
      )
      .then(() => {
        view.viewer.disableSelection(true);
        view.viewer.disableHighlight(true);
        setAggregatedView(view);
      });
  }, []);

  return { viewerRef, aggregatedView };
}
