import { ModuleThread, Pool } from 'threads';
import { MutableRefObject } from 'react';
import { PropertyContext } from '../property-operations';
import { OrderEntryDeepFragment } from 'src/gql/graphql';

interface HashInput {
  loadedModels: string[];
  context: Omit<PropertyContext, 'loadedModels'>;
  entry: Omit<OrderEntryDeepFragment, 'orderEntryCache'>;
  polygonCount: number | null | undefined;
  orderColumnIds: Array<string>;
}

interface CacheInput {
  loadedModels: Array<string>;
  context: PropertyContext;
  entry: Omit<OrderEntryDeepFragment, 'orderEntryCache'>;
  polygonCount: number | null | undefined;
  orderColumnIds: Array<string>;
}

export interface BulkHashOutput {
  entryId: string;
  hash: string;
}

export type SerializeNHashModule = {
  calculateOrderEntryCacheInputsHash: (input: HashInput) => string;
  bulkComputeHashes: (
    ctx: Omit<HashInput, 'entry'>,
    entries: Array<Omit<OrderEntryDeepFragment, 'orderEntryCache'>>
  ) => Array<BulkHashOutput>;
};

export async function bulkComputeHashInWorker(
  input: Omit<CacheInput, 'entry'>,
  entries: Array<Omit<OrderEntryDeepFragment, 'orderEntryCache'>>,
  workerPoolRef: MutableRefObject<Pool<
    ModuleThread<SerializeNHashModule>
  > | null>
): Promise<Array<BulkHashOutput>> {
  if (!workerPoolRef?.current) {
    throw new Error('WorkerPool is not defined');
  }
  const { context, ...rest } = input;
  const { loadedModels, ...others } = context;
  const ctx = {
    ...rest,
    context: others,
    loadedModels: input.loadedModels,
  };
  try {
    return workerPoolRef.current.queue(async (worker) => {
      return worker.bulkComputeHashes(ctx, entries);
    });
  } catch (e) {
    console.error('Failed to schedule work on the pool: ', e);
    throw e;
  }
}

export async function calculateOrderEntryCacheInputsHashInWorker(
  input: CacheInput,
  workerPoolRef: MutableRefObject<Pool<
    ModuleThread<SerializeNHashModule>
  > | null>
): Promise<string> {
  if (!workerPoolRef?.current) {
    throw new Error('WorkerPool is not defined');
  }
  const { context, ...rest } = input;
  const { loadedModels, ...others } = context;
  const payload = {
    ...rest,
    context: others,
    loadedModels: input.loadedModels,
  };
  try {
    return workerPoolRef.current.queue(async (worker) => {
      return worker.calculateOrderEntryCacheInputsHash(payload);
    });
  } catch (e) {
    console.error('Failed to schedule work on the pool: ', e);
    throw e;
  }
}
