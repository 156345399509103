import { RectangularProduct, SlopedProduct } from './TaperedInsulation';

export type Buildup = {
  baseLayer?: RectangularProduct;
  insulationLayer: RectangularProduct[];
  slopedInsulationLayer: SlopedProduct[];
  topLayer: RectangularProduct;
};

const BUILDUP1: Buildup = {
  baseLayer: {
    name: 'Rockwool DUO Energy 30mm x 1,2m x 2,0m',
    sku: 'RW225372',
    thermalConductivity: 0.036,
    dimensions: {
      height: 0.03,
    },
  },
  insulationLayer: [
    {
      name: 'Underlag Energy 0,6m x 2,0m x 120mm - 20 St/pal',
      sku: 'RW274405',
      thermalConductivity: 0.035,
      dimensions: {
        height: 0.12,
      },
    },
    {
      name: 'Underlag Energy 0,6m x 2,0m x 60mm - 40 St/pal',
      sku: 'RW274400',
      thermalConductivity: 0.035,
      dimensions: {
        height: 0.06,
      },
    },
  ],
  slopedInsulationLayer: [
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 20/35mm - Kile 20 (Pal)',
      sku: 'RW87224',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.02,
        maxHeight: 0.035,
        length: 0.6,
      },
    },
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 35/50mm - Kile 35 (Pal)',
      sku: 'RW87225',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.035,
        maxHeight: 0.05,
        length: 0.6,
      },
    },
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 50/65mm  - Kile 50 (Pal)',
      sku: 'RW102526',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.05,
        maxHeight: 0.065,
        length: 0.6,
      },
    },
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 65/80mm  - Kile 65 (Pal)',
      sku: 'RW102731',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.065,
        maxHeight: 0.08,
        length: 0.6,
      },
    },
  ],
  topLayer: {
    name: 'TF Board 30mm x 0,6m x 2,0m',
    sku: 'RW80056',
    thermalConductivity: 0.039,
    dimensions: {
      height: 0.03,
    },
  },
};

const BUILDUP2: Buildup = {
  insulationLayer: [
    {
      name: 'Underlag Energy 0,6m x 2,0m x 120mm - 20 St/pal',
      sku: 'RW274405',
      thermalConductivity: 0.035,
      dimensions: {
        height: 0.12,
      },
    },
    {
      name: 'Underlag Energy 0,6m x 2,0m x 60mm - 40 St/pal',
      sku: 'RW274400',
      thermalConductivity: 0.035,
      dimensions: {
        height: 0.06,
      },
    },
  ],
  slopedInsulationLayer: [
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 20/35mm - Kile 20 (Pal)',
      sku: 'RW87224',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.02,
        maxHeight: 0.035,
        length: 0.6,
      },
    },
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 35/50mm - Kile 35 (Pal)',
      sku: 'RW87225',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.035,
        maxHeight: 0.05,
        length: 0.6,
      },
    },
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 50/65mm  - Kile 50 (Pal)',
      sku: 'RW102526',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.05,
        maxHeight: 0.065,
        length: 0.6,
      },
    },
    {
      name: 'Hardrock Takfall 1:40 0,6m x 2,0m x 65/80mm  - Kile 65 (Pal)',
      sku: 'RW102731',
      thermalConductivity: 0.039,
      dimensions: {
        minHeight: 0.065,
        maxHeight: 0.08,
        length: 0.6,
      },
    },
  ],
  topLayer: {
    name: 'TF Board 30mm x 0,6m x 2,0m',
    sku: 'RW80056',
    thermalConductivity: 0.039,
    dimensions: {
      height: 0.03,
    },
  },
};

export function getTaperedInsulationBuildup(substrate: string) {
  if (substrate == 'Steel') {
    // Only use bottom layer if substrate is steel
    return BUILDUP1;
  }
  return BUILDUP2;
}
