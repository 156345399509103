import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { AbstractSheetLinedShape } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetLinedShape';
import { SheetShapeStyleType } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import { SheetShapeDeepFragment } from 'src/gql/graphql';
import Edit2D = Autodesk.Extensions.Edit2D;
import GuiViewer3D = Autodesk.Viewing.GuiViewer3D;
import Viewer3D = Autodesk.Viewing.Viewer3D;

export class PolylineShape extends AbstractSheetLinedShape {
  constructor(
    viewer: Viewer3D | GuiViewer3D,
    edit2d: Edit2D,
    activeSheetData: ActiveSheetData,
    shapeModel: SheetShapeDeepFragment
  ) {
    super(viewer, edit2d, activeSheetData, shapeModel);

    if (!shapeModel.sheetShapeLine) {
      throw new Error('shapeModel.sheetShapeLine must be defined');
    }

    const line = shapeModel.sheetShapeLine.points.map((point) =>
      super.convertPointToRender(point)
    );

    this.shapes = [new Autodesk.Edit2D.Polyline(line)];
    this.textLabelPoints = [line[Math.floor(line.length / 2)]];

    this.setStyle(SheetShapeStyleType.default);
  }

  public hitTest(x: number, y: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.shapes.some((shape) => shape.hitTest(x, y, 0.1));
  }
}
