import { useFlag } from '@unleash/proxy-client-react';
import { useEffect, useMemo } from 'react';
import { useViewerMode, ViewerMode } from 'src/components/common/viewer-mode';
import { ENABLE_APS_SHEET_VIEWER_FLAG } from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';

export function useEnabledSheetViewerModeValue() {
  const enableApsSheetViewer = useFlag(ENABLE_APS_SHEET_VIEWER_FLAG) || false;
  const { viewerMode, setViewerMode } = useViewerMode();

  useEffect(() => {
    // switch from sheets viewer v1 to v2 when enabled
    if (viewerMode === ViewerMode.Sheets && enableApsSheetViewer) {
      setViewerMode(ViewerMode.SheetsV2);
    } else if (viewerMode === ViewerMode.SheetsV2 && !enableApsSheetViewer) {
      setViewerMode(ViewerMode.Sheets);
    }
  }, [enableApsSheetViewer, viewerMode]);

  return useMemo(
    () => ({
      sheetsViewerMode: enableApsSheetViewer
        ? ViewerMode.SheetsV2
        : ViewerMode.Sheets,
    }),
    [enableApsSheetViewer]
  );
}
