import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import {
  CALIBRATION_TOOL_OVERLAY_ID,
  CALIBRATION_TOOL_SNAPPING_ANGLE_STEP,
  CalibrationDefaultLineStyle,
  CalibrationDrawingLineStyle,
  CROSSHAIR_CURSOR,
  VIEWER_TOP_OFFSET,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';
import {
  angleBetweenTwoPoints,
  endPointByAngle,
  snapAngle,
} from 'src/components/viewers/SheetsViewerV2/utils/cartesian';
import {
  drawCalibrationLine,
  LineStyle,
} from 'src/components/viewers/SheetsViewerV2/utils/drawing';

export class CalibrationTool extends Autodesk.Viewing.ToolInterface {
  names = [ActiveTool.Calibration];

  constructor(
    private readonly viewer: AggregatedView['viewer'],
    private readonly onChange: (
      pointA: THREE.Vector3 | null,
      pointB: THREE.Vector3 | null
    ) => void,
    public pointA: THREE.Vector3 | null,
    public pointB: THREE.Vector3 | null
  ) {
    super();

    delete (this as any).handleSingleClick;
    delete (this as any).handleMouseMove;
    delete (this as any).deactivate;
    delete (this as any).activate;
  }

  getCursor(): string {
    return CROSSHAIR_CURSOR;
  }

  activate(name: string, viewerApi?: Autodesk.Viewing.GuiViewer3D) {
    const callback = () => {
      this.clear(viewerApi);
      viewerApi?.removeEventListener(
        Autodesk.Viewing.MODEL_UNLOADED_EVENT,
        callback
      );
    };

    viewerApi?.addEventListener(
      Autodesk.Viewing.MODEL_UNLOADED_EVENT,
      callback
    );
  }

  clear(viewer = this.viewer) {
    viewer.impl.clearOverlay(CALIBRATION_TOOL_OVERLAY_ID);
    viewer.impl.invalidate(true, true, true);
  }

  deactivate() {
    this.clear();
  }

  handleSingleClick(event: MouseEvent, button: number): boolean {
    const clientX = event.clientX;
    const clientY = event.clientY - VIEWER_TOP_OFFSET;
    const cursorPosition = this.viewer.impl.intersectGround(clientX, clientY);

    if (!cursorPosition) return false;
    if (button === 2) return this._handleRightClick();
    if (button !== 0) return false;

    if (!this.pointA || this.pointB) {
      this.pointA = cursorPosition;
      this.pointB = null;
      this.onChange(this.pointA, null);
    } else if (this.pointA && !this.pointB) {
      this.pointB = this._calculateEndPoint(cursorPosition, event.shiftKey);
      this.onChange(this.pointA, this.pointB);
      CalibrationTool.drawLine(
        this.viewer,
        this.pointA,
        this.pointB,
        CalibrationDefaultLineStyle
      );
    }

    return true;
  }

  handleMouseMove(event: MouseEvent): boolean {
    const clientX = event.clientX;
    const clientY = event.clientY - VIEWER_TOP_OFFSET;
    const cursorPosition = this.viewer.impl.intersectGround(clientX, clientY);

    if (!this.pointB && this.pointA && cursorPosition) {
      const targetPoint = this._calculateEndPoint(
        cursorPosition,
        event.shiftKey
      );
      CalibrationTool.drawLine(
        this.viewer,
        this.pointA,
        targetPoint,
        CalibrationDrawingLineStyle
      );
    }

    return false; // When set to true, smooth panning is disabled.
  }

  _handleRightClick() {
    if (this.pointB) {
      this.pointB = null;
      this.onChange(this.pointA, this.pointB);
      return true;
    } else if (this.pointA) {
      this.pointA = null;
      this.pointB = null;
      this.onChange(this.pointA, this.pointB);
      this.clear();
      return true;
    }

    return false;
  }

  _calculateEndPoint(cursorPosition: THREE.Vector3, shiftKey: boolean) {
    if (!this.pointA) {
      throw new Error('Cannot calculate end point without start point');
    }

    const angle = angleBetweenTwoPoints([this.pointA, cursorPosition]);
    const snappedAngle = snapAngle(angle, CALIBRATION_TOOL_SNAPPING_ANGLE_STEP);

    if (shiftKey) {
      return endPointByAngle(
        this.pointA,
        snappedAngle,
        this.pointA.distanceTo(cursorPosition)
      );
    } else {
      return cursorPosition;
    }
  }

  static drawLine(
    viewer: AggregatedView['viewer'],
    startPoint: THREE.Vector3,
    endPoint: THREE.Vector3,
    style: LineStyle
  ) {
    const line = drawCalibrationLine(startPoint, endPoint, style);

    viewer.impl.createOverlayScene(CALIBRATION_TOOL_OVERLAY_ID, line.material);
    viewer.impl.addOverlay(CALIBRATION_TOOL_OVERLAY_ID, line.shape);
    viewer.impl.invalidate(true, true, true);
  }
}
