import { ColorMode } from '@chakra-ui/color-mode/dist/color-mode-types';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';

export type ThemeOptions = { colorMode: ColorMode };

export function setTheme(
  aggregatedView: AggregatedView,
  options: ThemeOptions
) {
  aggregatedView.viewer.navigation.setReverseZoomDirection(true);

  switch (options.colorMode) {
    case 'dark':
      aggregatedView.viewer.setTheme('dark-theme');
      aggregatedView.viewer.setBackgroundColor(23, 25, 35, 23, 25, 35);
      break;
    case 'light':
      aggregatedView.viewer.setTheme('light-theme');
      aggregatedView.viewer.setBackgroundColor(247, 250, 252, 247, 250, 252);
      break;
  }
}
