import { useColorMode } from '@chakra-ui/react';
import { useCallback } from 'react';
import { ViewerExtension } from 'src/components/viewers/common/utils/autodesk';
import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { mapPDFData } from 'src/components/viewers/SheetsViewerV2/mappers/mapPDFData';
import { setTheme } from 'src/components/viewers/SheetsViewerV2/utils/theme';
import PDFExtension = Autodesk.Viewing.Extensions.PDF.PDFExtension;

export type LoadPDFResult = Promise<ActiveSheetData>;

export function useLoadPDF(aggregatedView: AggregatedView | null) {
  const { colorMode } = useColorMode();

  const loadPDF = useCallback(
    async (id: string, url: string, page: number): LoadPDFResult => {
      if (!aggregatedView)
        throw new Error(
          'Cannot load sheet before aggregatedView initialization'
        );

      return new Promise((resolve, reject) =>
        aggregatedView.viewer.getExtension(
          ViewerExtension.PDF,
          (extension: PDFExtension) => {
            if (extension.viewer.model)
              // unload previously loaded model when was loaded
              extension.viewer.unloadModel(extension.viewer.model);

            extension.viewer.loadModel(
              url,
              { page },
              (model) => {
                setTheme(aggregatedView, { colorMode });
                mapPDFData(model, page)
                  .then((pdfData) => resolve({ id, page, ...pdfData }))
                  .catch(reject);
              },
              (errorCode, errorMessage) => {
                reject(new Error(`${errorMessage} (ErrorCode: ${errorCode}`));
              }
            );
          }
        )
      );
    },
    [aggregatedView, colorMode]
  );

  return { loadPDF };
}
