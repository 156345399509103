import { BoxProps, chakra } from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';
import { add, divide, multiply, norm, round, subtract } from 'mathjs';
import {
  toMultiPolygon2,
  toPoints2,
} from '../domain/geometry/algorithms/util/type-mapping';
import {
  LineSegment2,
  MultiPolygon2,
  Point2,
} from '../domain/geometry/geometric-types';
import {
  GetSparkelPropertiesForProjectQuery,
  SheetShapeDeepFragment,
} from '../gql/graphql';
import { hasDbIds } from '../services/viewer-services';
import { useUserTenant } from '../services/auth-info';
import { getPolylineMidpoint } from '../domain/geometry/algorithms/util/line-segment';
import { calculateSheetShapeArea } from '../domain/geometry/algorithms/sheet-shape-area';
import { getUnitScaleFromCalibration } from '../domain/sheet-calibration';
import { useOrderLabelsProtan } from '../hooks/protan-data';
import { DbIdsPerModel } from './common/ForgeViewer';
import {
  GhostMode,
  SheetViewerContextType,
  useSheetViewer,
} from './common/SheetViewer';
import SheetShapeLabel from './orderContractor/pdf-shapes/SheetShapeLabel';
import {
  getPolygon2PathData,
  transformMultipolygonCoordinates,
} from './orderContractor/shapes/util';
import {
  DiamondLabel,
  getDiamond,
} from './orderContractor/pdf-shapes/DiamondLabel';
import WedgeLabel from './orderContractor/pdf-shapes/WedgeLabel';
import {
  getCrossSectionFromProperties,
  getProductAreasFromProperties,
  getRoofShapeFromProperties,
  getRoofUValueFromProperties,
} from './orderContractor/pdf-shapes/SheetDrainLineDrawing';
import { TaperedInsulation } from './orderContractor/pdf-shapes/protan/TaperedInsulation';
import { Substrate } from './orderContractor/ProductChoices';

export const shapeUrnDataAttribute = 'data-shape-urn';
export const shapeDbIdDataAttribute = 'data-shape-db-id';

export const SheetShapes = ({
  getPointInViewerCoordinateSystem,
  showLabels,
}: {
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  showLabels: boolean;
}) => {
  const {
    shapesManager: { renderedSheetShapes },
    colorization: { colorization },
    visibilityManager,
    isDrawing,
    calibration: { calibration },
    propertyData,
  } = useSheetViewer();

  const tenant = useUserTenant();

  const visibleShapes = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(renderedSheetShapes).map((urn) => [
          urn,
          renderedSheetShapes[urn].filter(
            (shape) =>
              !isShapeHidden(
                visibilityManager.hiddenElements,
                urn,
                shape,
                visibilityManager.hiddenSheetShapes,
                visibilityManager.isolatedElements,
                false
              )
          ),
        ])
      ),
    [
      renderedSheetShapes,
      visibilityManager.hiddenElements,
      visibilityManager.hiddenSheetShapes,
      visibilityManager.isolatedElements,
    ]
  );

  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : null,
    [calibration]
  );

  const svgRef = useRef<SVGSVGElement>(null);

  return (
    <>
      <chakra.svg
        ref={svgRef}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        pointerEvents="none"
        sx={{
          '> *': {
            // Make shapes interactive without preventing pointer events on the pdf page
            pointerEvents: 'auto',
          },
        }}
      >
        {Object.values(visibleShapes)
          .flatMap((v) => v)
          .map((shape) => (
            <SheetShape
              key={shape.id}
              shape={shape}
              getPointInViewerCoordinateSystem={
                getPointInViewerCoordinateSystem
              }
              isDrawing={isDrawing}
            />
          ))}
      </chakra.svg>
      {showLabels
        ? Object.values(visibleShapes)
            .flatMap((v) => v)
            // eslint-disable-next-line complexity
            .map((shape) => {
              if (shape.sheetShapePolygon) {
                const subLabels: string[] = [];
                if (shape.sheetShapePolygon.multipolygon) {
                  const shapeArea =
                    calculateSheetShapeArea(
                      toMultiPolygon2(shape.sheetShapePolygon.multipolygon)
                    ).value * (unitScale ? unitScale ** 2 : 1);
                  subLabels.push(`${round(shapeArea, 2).toString()} m²`);
                }
                return (
                  <SheetShapeLabel
                    key={shape.id}
                    label={shape.name}
                    subLabels={subLabels}
                    position={polygonMidPoint(
                      toMultiPolygon2(shape.sheetShapePolygon.multipolygon),
                      getPointInViewerCoordinateSystem
                    )}
                    color={getColorForShape(
                      shape.urn,
                      shape.dbId,
                      colorization,
                      false
                    )}
                  />
                );
              } else if (shape.sheetShapePoint && !tenant.isProtan) {
                return (
                  <SheetShapeLabel
                    key={shape.id}
                    label={shape.name}
                    position={getPointInViewerCoordinateSystem([
                      shape.sheetShapePoint.point.x,
                      shape.sheetShapePoint.point.y,
                    ])}
                    color={getColorForShape(
                      shape.urn,
                      shape.dbId,
                      colorization,
                      false
                    )}
                  />
                );
              } else if (shape.sheetShapeLine) {
                if (!tenant.isProtan) {
                  return (
                    <SheetShapeLabel
                      key={shape.id}
                      label={shape.name}
                      position={getPointInViewerCoordinateSystem(
                        getPolylineMidpoint(
                          toPoints2(shape.sheetShapeLine.points)
                        )
                      )}
                      color={getColorForShape(
                        shape.urn,
                        shape.dbId,
                        colorization,
                        false
                      )}
                    />
                  );
                }

                if (propertyData && shape && calibration?.calibration) {
                  return (
                    <WedgeLabel
                      key={shape.id}
                      shape={shape}
                      getPointInViewerCoordinateSystem={
                        getPointInViewerCoordinateSystem
                      }
                      propertyData={propertyData}
                      calibration={calibration.calibration}
                    />
                  );
                }
              } else if (shape.sheetShapeSplitLine && tenant.isProtan) {
                const crossSection = getCrossSectionFromProperties(
                  propertyData,
                  shape.dbId,
                  shape.urn
                );
                if (crossSection) {
                  const baseLineDirection = subtract(
                    crossSection.baseLine[1],
                    crossSection.baseLine[0]
                  ) as Point2;

                  const tangentVector = divide(
                    baseLineDirection,
                    norm(baseLineDirection)
                  ) as Point2;

                  const normalVector = [tangentVector[1], -tangentVector[0]];

                  const labelPosition1 = add(
                    crossSection.baseLine[0],
                    multiply(normalVector, 100)
                  ) as Point2;

                  const labelPosition2 = add(
                    crossSection.baseLine[0],
                    multiply(normalVector, 80)
                  ) as Point2;

                  const uValue = getRoofUValueFromProperties(
                    propertyData,
                    shape.dbId,
                    shape.urn
                  );

                  const subLabels = [
                    `Min.: ${crossSection.minHeight * 1000} mm`,
                  ];

                  if (uValue) {
                    subLabels.push(`U-value: ${uValue} W/m²K`);
                  }

                  return (
                    <SheetShapeLabel
                      key={shape.id}
                      label={''}
                      subLabels={subLabels}
                      position={getPointInViewerCoordinateSystem(
                        labelPosition1
                      )}
                      color="black"
                    />
                  );
                }
              }
            })
        : undefined}
    </>
  );
};

function getColorForShape(
  shapeUrn: string,
  shapeDbId: number,
  colorization: SheetViewerContextType['colorization']['colorization'],
  isSelected: boolean
): string {
  if (isSelected) {
    return 'blue.300';
  }
  if (
    !colorization ||
    !colorization.dbIdsPerModel[shapeUrn] ||
    !colorization.dbIdsPerModel[shapeUrn].includes(shapeDbId)
  ) {
    return 'gray.500';
  }

  return colorization.colorFunc(shapeUrn, shapeDbId);
}
// eslint-disable-next-line complexity
const SheetShape = ({
  shape,
  getPointInViewerCoordinateSystem,
  isDrawing,
}: {
  shape: SheetShapeDeepFragment;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  isDrawing: boolean;
}) => {
  const {
    selectedDbIds,
    toggleSelectDbId,
    selectDbIds,
    visibilityManager: { hiddenElements, isolatedElements, hiddenSheetShapes },
    colorization: { colorization },
    ghosting: { ghostMode },
    propertyData,
  } = useSheetViewer();

  const tenant = useUserTenant();

  const shapeUrn = shape.urn;
  const isSelected = selectedDbIds[shapeUrn]?.includes(shape.dbId);
  const isHidden = isShapeHidden(
    hiddenElements,
    shapeUrn,
    shape,
    hiddenSheetShapes,
    isolatedElements,
    isSelected
  );
  const color =
    tenant.isProtan && shape.sheetShapePolygon
      ? isSelected
        ? 'blue.300'
        : 'white'
      : getColorForShape(shape.urn, shape.dbId, colorization, isSelected);

  if (isHidden && ghostMode !== GhostMode.NONE) {
    return null;
  }
  const ghostingStyle: BoxProps = getShapeGhostingStyle(isHidden, ghostMode);

  const onClick: React.MouseEventHandler = (e) => {
    if (isDrawing) {
      return;
    }
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      toggleSelectDbId(shape.dbId, shapeUrn);
    } else {
      selectDbIds({ [shapeUrn]: [shape.dbId] });
    }
  };
  const dataAttributes = {
    [shapeUrnDataAttribute]: shapeUrn,
    [shapeDbIdDataAttribute]: shape.dbId,
  };

  if (shape.sheetShapePolygon) {
    return (
      <SheetPolygonShape
        sheetShapePolygon={shape.sheetShapePolygon}
        onClick={onClick}
        isSelected={isSelected}
        fill={color}
        stroke={tenant.isProtan ? 'black' : color}
        fillOpacity={tenant.isProtan ? 0.2 : 0.6}
        {...ghostingStyle}
        {...dataAttributes}
        getPointInViewerCoordinateSystem={getPointInViewerCoordinateSystem}
        isDrawing={isDrawing}
      />
    );
  } else if (shape.sheetShapeLine) {
    return (
      <SheetPolyLineShape
        dbId={shape.dbId}
        urn={shape.urn}
        sheetShapeLine={shape.sheetShapeLine}
        onClick={onClick}
        stroke={getColorForShape(
          shape.urn,
          shape.dbId,
          colorization,
          isSelected
        )}
        {...ghostingStyle}
        {...dataAttributes}
        getPointInViewerCoordinateSystem={getPointInViewerCoordinateSystem}
        isDrawing={isDrawing}
      />
    );
  } else if (shape.sheetShapePoint) {
    return (
      <SheetPointShape
        sheetShapePoint={shape.sheetShapePoint}
        onClick={onClick}
        isSelected={isSelected}
        fill={getColorForShape(shape.urn, shape.dbId, colorization, isSelected)}
        {...ghostingStyle}
        {...dataAttributes}
        getPointInViewerCoordinateSystem={getPointInViewerCoordinateSystem}
        isDrawing={isDrawing}
      />
    );
  } else if (shape.sheetShapeSplitLine) {
    return (
      <SheetTaperedInsulation
        dbId={shape.dbId}
        urn={shape.urn}
        drainLines={shape.sheetShapeSplitLine}
        onClick={onClick}
        isSelected={isSelected}
        stroke={getColorForShape(
          shape.urn,
          shape.dbId,
          colorization,
          isSelected
        )}
        {...ghostingStyle}
        {...dataAttributes}
        getPointInViewerCoordinateSystem={getPointInViewerCoordinateSystem}
        isDrawing={isDrawing}
        propertyData={propertyData}
      />
    );
  }
  return null;
};

const SheetPolygonShape = ({
  sheetShapePolygon,
  isSelected,
  getPointInViewerCoordinateSystem,
  fill,
  stroke,
  isDrawing,
  fillOpacity,
  ...props
}: {
  sheetShapePolygon: NonNullable<SheetShapeDeepFragment['sheetShapePolygon']>;
  isSelected?: boolean;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  isDrawing: boolean;
  fillOpacity: number;
} & BoxProps) => {
  const { multipolygon } = sheetShapePolygon;

  const pathData = useMemo(() => {
    return transformMultipolygonCoordinates(
      toMultiPolygon2(multipolygon),
      getPointInViewerCoordinateSystem
    ).polygons.map((polygon) => {
      return getPolygon2PathData(polygon);
    });
  }, [getPointInViewerCoordinateSystem, multipolygon]);

  return (
    <chakra.g
      fillRule={'evenodd'}
      fill={fill}
      stroke={isSelected ? 'white' : stroke || fill}
      strokeOpacity={1}
      strokeWidth="2"
      fillOpacity={fillOpacity}
      _hover={{
        filter: isDrawing ? 'none' : 'brightness(130%)',
        fillOpacity: fillOpacity * 1.2,
      }}
    >
      {pathData.map((data, index) => (
        <chakra.path key={index} d={data} {...props} />
      ))}
    </chakra.g>
  );
};

// eslint-disable-next-line complexity
const SheetPolyLineShape = ({
  dbId,
  urn,
  sheetShapeLine,
  getPointInViewerCoordinateSystem,
  isDrawing,
  ...props
}: {
  dbId: number;
  urn: string;
  sheetShapeLine: Pick<
    NonNullable<SheetShapeDeepFragment['sheetShapeLine']>,
    'points'
  >;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  isDrawing: boolean;
} & BoxProps) => {
  const { points } = sheetShapeLine;

  const domPoints = useMemo(() => {
    return points.map((point) =>
      getPointInViewerCoordinateSystem([point.x, point.y])
    );
  }, [getPointInViewerCoordinateSystem, points]);

  const tenant = useUserTenant();

  const { propertyData } = useSheetViewer();

  const diamond = getDiamond(propertyData, dbId, urn);

  return (
    <>
      <chakra.polyline
        points={domPoints.map(([x, y]) => `${x},${y}`).join(' ')}
        strokeWidth="3"
        margin={3}
        fill="none"
        _hover={{
          filter: isDrawing ? 'none' : 'brightness(130%)',
        }}
        {...props}
      />
      {tenant.isProtan &&
        Object.values(diamond).some((triangle) => !!triangle) &&
        points.map((point, index) => {
          if (index === 0) return null;
          return (
            <DiamondLabel
              key={index}
              segment={[
                [points[index - 1].x, points[index - 1].y],
                [points[index].x, points[index].y],
              ]}
              getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
              diamond={diamond}
            />
          );
        })}
    </>
  );
};

const SheetTaperedInsulation = ({
  dbId,
  urn,
  getPointInViewerCoordinateSystem,
  isSelected,
  drainLines,
  isDrawing,
  propertyData,
  ...props
}: {
  dbId: number;
  urn: string;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  isSelected: boolean;
  drainLines: Pick<
    NonNullable<SheetShapeDeepFragment['sheetShapeSplitLine']>,
    'points'
  >;
  isDrawing: boolean;
  propertyData: GetSparkelPropertiesForProjectQuery | undefined;
} & BoxProps) => {
  const tenant = useUserTenant();

  const { substrate, buildingHeight, qkast } = useOrderLabelsProtan();

  const crossSection = useMemo(() => {
    if (!tenant.isProtan) return null;
    return getCrossSectionFromProperties(propertyData, dbId, urn);
  }, [tenant.isProtan, propertyData, dbId, urn]);

  const roofShapeDbId = useMemo(() => {
    if (!tenant.isProtan) return null;

    return getRoofShapeFromProperties(propertyData, dbId, urn);
  }, [tenant.isProtan, propertyData, dbId, urn]);

  const currentProductAreas = useMemo(() => {
    if (!tenant.isProtan) return {};

    return getProductAreasFromProperties(
      propertyData,
      dbId,
      urn,
      substrate ?? ''
    );
  }, [tenant.isProtan, propertyData, dbId, urn, substrate]);

  const currentRoofUValue = useMemo(() => {
    if (!tenant.isProtan) return null;

    return getRoofUValueFromProperties(propertyData, dbId, urn);
  }, [tenant.isProtan, propertyData, dbId, urn]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { points } = drainLines!;

  const domPoints = useMemo(() => {
    const drainLines: LineSegment2[] = [];
    for (let i = 0; i < points.length; i += 2) {
      if (i + 1 < points.length) {
        drainLines.push([
          getPointInViewerCoordinateSystem([points[i].x, points[i].y]),
          getPointInViewerCoordinateSystem([points[i + 1].x, points[i + 1].y]),
        ]);
      }
    }
    return drainLines;
  }, [getPointInViewerCoordinateSystem, points]);

  return (
    <>
      {domPoints.map((drainLine, index) => (
        <chakra.polyline
          key={index}
          points={drainLine.map(([x, y]) => `${x},${y}`).join(' ')}
          strokeWidth="3"
          strokeDasharray={'5,10'}
          strokeLinecap={'round'}
          margin={3}
          fill="none"
          _hover={{
            filter: isDrawing ? 'none' : 'brightness(130%)',
          }}
          {...props}
        />
      ))}
      {tenant.isProtan &&
        crossSection &&
        roofShapeDbId &&
        (substrate as Substrate) && (
          <TaperedInsulation
            crossSection={crossSection}
            currentProductAreas={currentProductAreas}
            currentRoofUValue={currentRoofUValue}
            getPointInViewerCoordinateSystem={getPointInViewerCoordinateSystem}
            isSelected={isSelected}
            onClick={props.onClick}
            roofShapeDbId={roofShapeDbId}
            dbId={dbId}
            substrate={substrate as Substrate}
            qKast={parseFloat(qkast ?? '0')}
            buildingHeight={buildingHeight ? parseFloat(buildingHeight) : 0}
          />
        )}
    </>
  );
};

const SheetPointShape = ({
  sheetShapePoint,
  isSelected,
  getPointInViewerCoordinateSystem,
  isDrawing,
  ...props
}: {
  sheetShapePoint: NonNullable<SheetShapeDeepFragment['sheetShapePoint']>;
  isSelected?: boolean;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  isDrawing: boolean;
} & BoxProps) => {
  const { point } = sheetShapePoint;

  const domPoint = useMemo(() => {
    return getPointInViewerCoordinateSystem([point.x, point.y]);
  }, [getPointInViewerCoordinateSystem, point]);

  const tenant = useUserTenant();

  return (
    <>
      {tenant.isProtan ? (
        <>
          <chakra.rect
            x={domPoint[0] - 8}
            y={domPoint[1] - 8}
            width={4}
            height={4}
            stroke="black"
            strokeWidth="1"
            _hover={{
              filter: isDrawing ? 'none' : 'brightness(130%)',
            }}
            {...props}
          />
          <chakra.circle
            cx={domPoint[0]}
            cy={domPoint[1]}
            r={2}
            stroke="black"
            strokeWidth="1"
            _hover={{
              filter: isDrawing ? 'none' : 'brightness(130%)',
            }}
            {...props}
          />
        </>
      ) : (
        <chakra.circle
          cx={domPoint[0]}
          cy={domPoint[1]}
          r={8}
          stroke="black"
          strokeWidth="1"
          _hover={{
            filter: isDrawing ? 'none' : 'brightness(130%)',
          }}
          {...props}
        />
      )}
    </>
  );
};
function getShapeGhostingStyle(
  isHidden: boolean,
  ghostMode: GhostMode
): BoxProps {
  return isHidden && ghostMode !== GhostMode.NONE
    ? {
        opacity: 0.1,
        pointerEvents: 'none',
      }
    : {};
}

export function isShapeHidden(
  hiddenElements: DbIdsPerModel,
  shapeUrn: string,
  shape: SheetShapeDeepFragment,
  hiddenSheetShapes: DbIdsPerModel,
  isolatedElements: DbIdsPerModel,
  isSelected: boolean
) {
  if (isSelected) {
    return false;
  }
  return (
    hiddenElements[shapeUrn]?.includes(shape.dbId) ||
    hiddenSheetShapes[shapeUrn]?.includes(shape.dbId) ||
    (hasDbIds(isolatedElements) &&
      !isolatedElements[shapeUrn]?.includes(shape.dbId))
  );
}

export const polygonMidPoint = (
  multipolygon: MultiPolygon2,
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2
) => {
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  multipolygon.polygons.forEach((polygon) => {
    polygon.exterior.forEach(([x, y]) => {
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    });
  });

  return getPointInViewerCoordinateSystem([
    (minX + maxX) / 2,
    (minY + maxY) / 2,
  ]);
};
