import { useEffect, useMemo } from 'react';
import { MeasurementTool } from 'src/components/viewers/SheetsViewerV2/autodesk/tools/MeasurementTool';
import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { useCalibration } from 'src/components/viewers/SheetsViewerV2/hooks/useCalibration';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';

type Props = {
  activeTool: ActiveTool;
  activeSheetData: ActiveSheetData | null;
  viewer: AggregatedView['viewer'] | undefined;
  calibration: ReturnType<typeof useCalibration>['existing'];
};

export function useMeasurementTool({
  activeTool,
  activeSheetData,
  viewer,
  calibration,
}: Props) {
  const isMeasuring = useMemo(
    () => activeTool === ActiveTool.Measurement && !!calibration,
    [activeTool, calibration]
  );

  useEffect(() => {
    if (!viewer || !calibration || !activeSheetData) return;

    const tool = new MeasurementTool(calibration, viewer, activeSheetData);
    const updateLabel = () => tool.drawLabel(viewer);

    tool.clear();

    if (isMeasuring) {
      viewer.toolController.registerTool(tool);
      viewer.disableSelection(true);
      viewer.disableHighlight(true);
      viewer.setContextMenu(null);
      viewer.toolController.activateTool(tool.getName());
      viewer.addEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        updateLabel
      );
    } else {
      viewer.setDefaultContextMenu();
      viewer.toolController.deactivateTool(tool.getName());
      viewer.toolController.deregisterTool(tool);
      viewer.removeEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        updateLabel
      );
    }
  }, [isMeasuring, calibration, viewer, activeSheetData]);

  return { isMeasuring };
}
