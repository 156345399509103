export type PDFData = {
  getPDF: () => {
    numPages: number;
    getPage(page: number): Promise<{
      getViewport(): {
        rotation: number;
      };
    }>;
  };
  metadata: {
    page_dimensions: {
      page_width: number;
      page_height: number;
      page_units: string;
    };
  };
};

export type PDFPageViewport = {
  offsetX: number;
  offsetY: number;
  rotation: number;
  scale: number;
  transform: [number, number, number, number, number, number];
  viewBox: [number, number, number, number];
};

export type MappedPDFData = {
  totalPages: number;
  unit: string;
  width: number;
  height: number;
  viewport: PDFPageViewport;
};

export async function mapPDFData(
  model: Autodesk.Viewing.Model,
  page: number
): Promise<MappedPDFData> {
  const data = model.getData() as PDFData;
  const pdf = data?.getPDF();
  const totalPages = pdf?.numPages ?? 1;
  const width = data?.metadata?.page_dimensions?.page_width;
  const height = data?.metadata?.page_dimensions?.page_height;
  const unit = model.getDisplayUnit();
  const pageInfo = await pdf?.getPage(page);
  const viewport = pageInfo?.getViewport() as PDFPageViewport;

  return {
    totalPages,
    width,
    height,
    unit,
    viewport,
  };
}
