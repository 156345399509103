import { AlgorithmTargetType } from './algorithm-map';
import { GeometricAlgorithmType } from './algorithm-types';

export const SPARKEL_GEOMETRIC_PROPERTY_SET = 'Sparkel properties';

// Ensure no overlap between geometric properties and algorithm type names
// Because we currently have no other way to separate them than string name...
export enum GeometricProperty {
  LENGTH = 'Length',
  HEIGHT = 'Height',
  WIDTH = 'Width',
  THICKNESS = 'Thickness',
  CIRCUMFERENCE = 'Circumference',
  LARGEST_SIDE_AREA = 'Largest side area',
  GROSS_SIDE_AREA = 'Gross side area',
  TOP_SURFACE_AREA = 'Top Surface Area',
  BOTTOM_SURFACE_AREA = 'Bottom Surface Area',
  EXTRUDED_SIDE_AREA = 'Extruded side area',
  TOTAL_SURFACE_AREA = 'Total Surface Area',
  VOLUME = 'Volume',
  DIAMETER = 'Diameter',
}

export const isGeometricProperty = (
  property: string
): property is GeometricProperty => {
  return Object.values(GeometricProperty).includes(
    property as GeometricProperty
  );
};

export enum ResultType {
  MULTIPOLYLINE,
  TRIANGULATED_SURFACE,
  MULTIPOLYGON,
  MESH,
  MULTIPOLYLINE2,
  MULTIPOLYGON2,
}
export const dimensionsForResultType = {
  [ResultType.MULTIPOLYLINE]: 1,
  [ResultType.MULTIPOLYLINE2]: 1,
  [ResultType.TRIANGULATED_SURFACE]: 2,
  [ResultType.MULTIPOLYGON]: 2,
  [ResultType.MULTIPOLYGON2]: 2,
  [ResultType.MESH]: 3,
};

export type MultiPolylineResult = {
  value: number;
  type: ResultType.MULTIPOLYLINE;
  multiPolyline: MultiPolyline;
};

// A triangulated surface in 3D space
export type TriangulatedSurfaceResult = {
  value: number;
  type: ResultType.TRIANGULATED_SURFACE;
  triangles: Triangle[];
};

// A closed mesh in 3D space
export type MeshResult = {
  value: number;
  type: ResultType.MESH;
  triangles: Triangle[];
};

export type MultiPolygonResult = {
  value: number;
  type: ResultType.MULTIPOLYGON;
  multiPolygon: MultiPolygon;
  plane: Plane;
};

export type MultiPolyline2Result = {
  value: number;
  type: ResultType.MULTIPOLYLINE2;
  multiPolyline: MultiPolyline2;
};

export type MultiPolygon2Result = {
  value: number;
  type: ResultType.MULTIPOLYGON2;
  multiPolygon: MultiPolygon2;
};

export type GeometricResult =
  | MultiPolylineResult
  | MultiPolygonResult
  | TriangulatedSurfaceResult
  | MeshResult
  | MultiPolyline2Result
  | MultiPolygon2Result
  | undefined;

export type Point2 = [number, number];
export type Point = [number, number, number];

export type LineSegment = [Point, Point];
export type LineSegment2 = [Point2, Point2];

export type Triangle2 = [Point2, Point2, Point2];
export type Triangle = [Point, Point, Point];

// Rings are represented as closed, e.g. the first and last point is equal
export type LinearRing2 = Point2[];
export type LinearRing = Point[];

export type Polyline2 = Point2[];
export type Polyline = Point[];

export type MultiPolyline2 = {
  lines: Polyline2[];
};

export type MultiPolyline = {
  lines: Polyline[];
};

export type Polygon2 = {
  exterior: LinearRing2;
  interiors: LinearRing2[];
};
export type Polygon = {
  exterior: LinearRing;
  interiors: LinearRing[];
};

export type MultiPolygon2 = {
  polygons: Polygon2[];
};
export type MultiPolygon = {
  polygons: Polygon[];
};

export type Plane = {
  unitNormal: Point;
  planeCoefficient: number;
};

export type TrianglesInPlane = {
  plane: Plane;
  triangles: Triangle[];
};

export type OperationContext = {
  dbId: number;
  unitScale: number;
  targetType: AlgorithmTargetType;
  algorithmType: GeometricAlgorithmType | undefined;
  modelUrn: string;
};

type AddUnits<U extends GeometricResult> = U extends undefined
  ? undefined
  : U & { units: string };

export type ScaledGeometricResult = AddUnits<GeometricResult>;

export type OperationResult = [ScaledGeometricResult, OperationContext];

export type MinimalBoundingBoxReturn = {
  boundingBox: [Point2, Point2, Point2, Point2];
  dimensions: { width: number; height: number };
};

export type BoundingBox2DReturn = (MinimalBoundingBoxReturn & {
  plane: TrianglesInPlane;
})[];
