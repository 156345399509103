import { sortPointsOnAxis } from './cartesian';

export type LineStyle = { lineWidth: number; lineColor: string };

export function drawCalibrationLine(
  point1: THREE.Vector3,
  point2: THREE.Vector3,
  style: LineStyle
) {
  const [start, end] = sortPointsOnAxis([point1, point2], 'x');
  const direction = new THREE.Vector3().subVectors(end, start);
  const length = direction.length();

  const geometry = new THREE.PlaneBufferGeometry(length, style.lineWidth);
  const material = new THREE.MeshBasicMaterial({ color: style.lineColor });
  const shape = new THREE.Mesh(geometry, material);

  const midPoint = new THREE.Vector3().addVectors(start, end).divideScalar(2);

  shape.position.copy(midPoint);

  const quaternion = new THREE.Quaternion().setFromUnitVectors(
    new THREE.Vector3(1, 0, 0),
    direction.normalize()
  );
  shape.setRotationFromQuaternion(quaternion);

  return { shape, material };
}
