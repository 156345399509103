import {
  Button,
  Divider,
  HStack,
  Heading,
  IconButton,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Portal,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  faArrowRight,
  faArrowsDownToLine,
  faChevronDown,
  faCircleDot,
  faDrawPolygon,
  faMagicWandSparkles,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useFlag } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ReactComponent as PolylineIcon } from '../../assets/icons/draw-polyline.svg';
import { ReactComponent as RectangleIcon } from '../../assets/icons/draw-rectangle.svg';
import { ReactComponent as ExtrudeIcon } from '../../assets/icons/extrude-icon.svg';
import { useSheetShapes } from '../../hooks/sheet-shapes';
import { useSheetViewer } from '../common/SheetViewer';
import SparkelIcon from '../common/icon/SparkelIcon';
import { useUserTenant } from '../../services/auth-info';
import { useProtanProjectSteps } from '../common/ProtanProjectStepsProvider';
import {
  OrderDeepFragment,
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
} from '../../gql/graphql';
import { ShapeDrawingMode } from './pdf-shapes/SheetShapeDrawing';
import { useInsulationHandler } from './pdf-shapes/protan/hooks/useInsulationHandler';
import { Substrate } from './ProductChoices';
import { useOrderLabelsProtan } from 'src/hooks/protan-data';

// eslint-disable-next-line complexity
export default function ProtanSheetShapesToolbar({
  order,
}: {
  order: OrderDeepFragment;
}) {
  const enableMagicSheetShapes = useFlag('enable-magic-sheet-shapes');

  const {
    drawing: {
      deactivate: deactivateDrawing,
      shapeToEdit,
      hasResult: hasDrawingResult,
      submit: submitDrawing,
      isSubmitting: isSubmittingDrawing,
      isDrawing,
      mode: drawingMode,
      activate: activateDrawing,
      minHeight,
      setMinHeight,
    },
  } = useSheetShapes();
  const { substrate } = useOrderLabelsProtan();
  const backgroundColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const activeIconColor = useColorModeValue('teal.500', 'teal.200');

  const { page, calibration } = useSheetViewer();

  useEffect(() => {
    if (!calibration.calibration) {
      deactivateDrawing();
    }
  }, [calibration.calibration, deactivateDrawing]);

  useHotkeys('esc', deactivateDrawing, { enabled: isDrawing });
  useHotkeys('enter', submitDrawing, [submitDrawing], {
    enabled: isDrawing && hasDrawingResult && !isSubmittingDrawing,
  });
  useHotkeys('shift+s', () => activateDrawing(), [activateDrawing], {
    enabled: !!page && !!calibration.calibration,
  });

  useHotkeys(
    'space',
    async () => {
      await submitDrawing();
      activateDrawing();
    },
    {
      enabled:
        isDrawing &&
        hasDrawingResult &&
        !isSubmittingDrawing &&
        !!calibration.calibration,
    },
    [submitDrawing, activateDrawing]
  );

  const user = useUserTenant();
  const { projectSteps } = useProtanProjectSteps();
  const {
    isOpen: isProtanShapesPopoverOpen,
    onClose: closeProtanShapesPopover,
  } = useDisclosure({
    defaultIsOpen: true,
  });

  const roofOutlineInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.RoofOutline
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  const drainLinePlacementInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.DrainLinePlacement
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  const drainPlacementInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.DrainPlacement
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  const wedgePlacementInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.WedgePlacement
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  const shouldShowToolbarPopover =
    isProtanShapesPopoverOpen &&
    user.isProtan &&
    (roofOutlineInProgress ||
      drainLinePlacementInProgress ||
      drainPlacementInProgress ||
      wedgePlacementInProgress);

  let protanPopoverHeaderText = 'Draw roof outline';
  let protanPopoverBodyText =
    'Click on the drawing where you want to place the roof outline. You can also draw a polygon or rectangle to more accurately define the roof.';

  if (drainLinePlacementInProgress) {
    protanPopoverHeaderText = 'Place drainage line';
    protanPopoverBodyText =
      'Click on the drawing where you want to place the drainage line.';
  } else if (
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.DrainPlacement
    )?.status === ProtanProjectStepStatusEnum.InProgress
  ) {
    protanPopoverHeaderText = 'Place drains';
    protanPopoverBodyText =
      'Click on the drawing where you want to place drains.';
  } else if (
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.WedgePlacement
    )?.status === ProtanProjectStepStatusEnum.InProgress
  ) {
    protanPopoverHeaderText = 'Place wedges';
    protanPopoverBodyText = 'Use the tools below to place wedges.';
  }

  const { handleUseFlatInsulation } = useInsulationHandler(
    order,
    activateDrawing
  );

  if (!isDrawing) {
    return null;
  }

  return (
    <Popover
      isOpen={shouldShowToolbarPopover}
      onClose={closeProtanShapesPopover}
      autoFocus={false}
    >
      <PopoverAnchor>
        <HStack
          backgroundColor={backgroundColor}
          rounded={50}
          px={1}
          height={8}
          borderColor={borderColor}
          borderWidth="1px"
          mb={1}
        >
          {drainLinePlacementInProgress && (
            <>
              <Tooltip label="Drainage line">
                <IconButton
                  variant={'ghost'}
                  colorScheme={'teal'}
                  aria-label="draw drainage line"
                  size="xs"
                  icon={
                    <SparkelIcon
                      icon={faChevronDown}
                      color={
                        drawingMode === ShapeDrawingMode.DrainLine
                          ? activeIconColor
                          : undefined
                      }
                    />
                  }
                  isActive={drawingMode === ShapeDrawingMode.DrainLine}
                  isDisabled={!!shapeToEdit}
                  onClick={() => {
                    activateDrawing(ShapeDrawingMode.DrainLine);
                  }}
                />
              </Tooltip>
              <InputGroup size={'xs'} width={24}>
                <InputLeftElement>
                  <SparkelIcon as={ExtrudeIcon} color={activeIconColor} />
                </InputLeftElement>
                <NumberInput
                  variant={'filled'}
                  max={1000}
                  min={80} // Stop from setting zero-thickness
                  color={activeIconColor}
                  precision={0}
                  step={15}
                  defaultValue={minHeight ? minHeight * 1000 : 1000}
                  onChange={(value) => {
                    const mm = parseFloat(value);
                    // First subtract base, then round up to nearest 60mm, then add base back
                    const base = substrate === Substrate.Concrete ? 50 : 80;
                    const roundedMm =
                      base + Math.ceil(Math.max(0, mm - base) / 15) * 15;
                    setMinHeight(roundedMm / 1000); // convert to meters
                  }}
                >
                  <NumberInputField
                    paddingLeft={6}
                    paddingRight={0}
                    borderRadius={'md'}
                    autoFocus
                    width={24}
                    backgroundColor={borderColor}
                    _hover={{ backgroundColor: 'blackAlpha.500' }}
                  />
                </NumberInput>
              </InputGroup>
            </>
          )}
          {drainPlacementInProgress && (
            <Tooltip label="Drain">
              <IconButton
                variant={'ghost'}
                colorScheme={'teal'}
                aria-label="place drain"
                size="xs"
                icon={
                  <SparkelIcon
                    icon={faCircleDot}
                    color={
                      drawingMode === ShapeDrawingMode.Point
                        ? activeIconColor
                        : undefined
                    }
                  />
                }
                isActive={drawingMode === ShapeDrawingMode.Point}
                isDisabled={!!shapeToEdit}
                onClick={() => {
                  activateDrawing(ShapeDrawingMode.Point);
                }}
              />
            </Tooltip>
          )}
          {wedgePlacementInProgress && (
            <Tooltip label="Wedge">
              <IconButton
                variant={'ghost'}
                colorScheme={'teal'}
                aria-label="place wedge"
                size="xs"
                icon={
                  <SparkelIcon
                    as={PolylineIcon}
                    color={
                      drawingMode === ShapeDrawingMode.LineString
                        ? activeIconColor
                        : undefined
                    }
                  />
                }
                isActive={drawingMode === ShapeDrawingMode.LineString}
                isDisabled={!!shapeToEdit}
                onClick={() => {
                  activateDrawing(ShapeDrawingMode.LineString);
                }}
              />
            </Tooltip>
          )}
          {roofOutlineInProgress && (
            <Tooltip label="Rectangle">
              <IconButton
                variant={'ghost'}
                colorScheme={'teal'}
                aria-label="draw rectangle"
                size="xs"
                icon={
                  <SparkelIcon
                    as={RectangleIcon}
                    color={
                      drawingMode === ShapeDrawingMode.Rectangle
                        ? activeIconColor
                        : undefined
                    }
                  />
                }
                isActive={drawingMode === ShapeDrawingMode.Rectangle}
                isDisabled={!!shapeToEdit}
                onClick={() => {
                  activateDrawing(ShapeDrawingMode.Rectangle);
                }}
              />
            </Tooltip>
          )}
          {projectSteps?.find(
            (step) => step.step === ProtanProjectStepEnum.RoofOutline
          )?.status === ProtanProjectStepStatusEnum.InProgress && (
            <Tooltip label="Polygon">
              <IconButton
                variant={'ghost'}
                colorScheme={'teal'}
                aria-label="draw polygon"
                size="xs"
                icon={
                  <SparkelIcon
                    icon={faDrawPolygon}
                    color={
                      drawingMode === ShapeDrawingMode.Polygon
                        ? activeIconColor
                        : undefined
                    }
                  />
                }
                isActive={drawingMode === ShapeDrawingMode.Polygon}
                isDisabled={!!shapeToEdit}
                onClick={() => {
                  activateDrawing(ShapeDrawingMode.Polygon);
                }}
              />
            </Tooltip>
          )}
          {enableMagicSheetShapes &&
            projectSteps?.find(
              (step) => step.step === ProtanProjectStepEnum.RoofOutline
            )?.status === ProtanProjectStepStatusEnum.InProgress && (
              <Tooltip label="Magic polygon">
                <IconButton
                  variant={'ghost'}
                  colorScheme={'teal'}
                  aria-label="draw magic polygon"
                  size="xs"
                  icon={<SparkelIcon icon={faMagicWandSparkles} />}
                  isActive={drawingMode === ShapeDrawingMode.MagicPolygon}
                  isDisabled={!!shapeToEdit}
                  onClick={() => {
                    activateDrawing(ShapeDrawingMode.MagicPolygon);
                  }}
                />
              </Tooltip>
            )}
          <Divider orientation="vertical" height={'70%'} />
          {drainLinePlacementInProgress ? (
            <Button
              aria-label="Skip"
              size="xs"
              colorScheme="gray"
              onClick={handleUseFlatInsulation}
              rightIcon={
                <SparkelIcon icon={faArrowsDownToLine} color={'inherit'} />
              }
            >
              Use flat insulation
            </Button>
          ) : (
            <Tooltip label="Cancel drawing (esc)">
              <IconButton
                aria-label="Cancel drawing"
                colorScheme="gray"
                size="xs"
                onClick={deactivateDrawing}
                isRound
                icon={<SparkelIcon icon={faTimes} />}
              ></IconButton>
            </Tooltip>
          )}
          <Tooltip label={hasDrawingResult || 'Create a valid shape to save'}>
            <Button
              aria-label="Save"
              size="xs"
              onClick={submitDrawing}
              isLoading={isSubmittingDrawing}
              isDisabled={!hasDrawingResult}
              rightIcon={
                <SparkelIcon
                  icon={faArrowRight}
                  color={['gray.50', 'gray.700']}
                />
              }
            >
              Next
            </Button>
          </Tooltip>
        </HStack>
      </PopoverAnchor>

      <Portal>
        <PopoverContent backgroundColor={'blue.100'} color={'blue.900'}>
          <PopoverCloseButton onClick={closeProtanShapesPopover} />
          <PopoverHeader borderBottom={'none'}>
            <Heading size="sm" as="h3" color={'inherit'}>
              {protanPopoverHeaderText}
            </Heading>
          </PopoverHeader>
          <PopoverBody>{protanPopoverBodyText}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

// Protan specific flow for drawing shapes.
// If roof outline is in progress and drain placement is not started, set roof outline to completed and drain placement to in progress
// If roof outline and drain placement is in progress, set drain placement to completed
// eslint-disable-next-line complexity
export const handleProtanFlow = async (
  activateDrawing: (mode: ShapeDrawingMode) => void,
  projectSteps:
    | {
        step: ProtanProjectStepEnum;
        status: ProtanProjectStepStatusEnum;
      }[]
    | undefined,
  setGhostMode: (ghostMode: number) => void,
  setStepStatus: (
    step: ProtanProjectStepEnum,
    status: ProtanProjectStepStatusEnum
  ) => void,
  fireRequirements: string | undefined,
  handleUseFlatInsulation: () => Promise<void>
) => {
  const roofOutlineStatus =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.RoofOutline
    )?.status || ProtanProjectStepStatusEnum.NotStarted;

  const drainLinePlacementStatus =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.DrainLinePlacement
    )?.status || ProtanProjectStepStatusEnum.NotStarted;

  const drainPlacementStatus =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.DrainPlacement
    )?.status || ProtanProjectStepStatusEnum.NotStarted;

  if (
    roofOutlineStatus === ProtanProjectStepStatusEnum.InProgress &&
    drainLinePlacementStatus === ProtanProjectStepStatusEnum.NotStarted
  ) {
    // First, if roof outline was in progress and drain line placement was not started,
    // set roof outline to completed and drain line placement to in progress
    setStepStatus(
      ProtanProjectStepEnum.RoofOutline,
      ProtanProjectStepStatusEnum.Completed
    );
    if (fireRequirements === 'Combustible') {
      // Skip drain line placement if fire requirements are combustible
      // (we don't support tapered insulation for combustible roofs)
      console.log('Skipping drain line placement');
      await handleUseFlatInsulation();
    } else {
      // Set drain line placement to in progress
      setStepStatus(
        ProtanProjectStepEnum.DrainLinePlacement,
        ProtanProjectStepStatusEnum.InProgress
      );
      activateDrawing(ShapeDrawingMode.DrainLine);
    }
  } else if (
    // If roof outline was completed and drain line placement was in progress,
    // set drain line placement to completed and drain placement to in progress
    roofOutlineStatus === ProtanProjectStepStatusEnum.Completed &&
    drainLinePlacementStatus === ProtanProjectStepStatusEnum.InProgress
  ) {
    setStepStatus(
      ProtanProjectStepEnum.DrainLinePlacement,
      ProtanProjectStepStatusEnum.Completed
    );
    setStepStatus(
      ProtanProjectStepEnum.DrainPlacement,
      ProtanProjectStepStatusEnum.InProgress
    );
    activateDrawing(ShapeDrawingMode.Point);
  } else if (
    drainLinePlacementStatus === ProtanProjectStepStatusEnum.Completed &&
    drainPlacementStatus === ProtanProjectStepStatusEnum.InProgress
  ) {
    // If drain line placement was completed and drain placement is in progress,
    // set drain placement to completed

    setGhostMode(1); // Make sure ghosting is enabled

    setStepStatus(
      ProtanProjectStepEnum.DrainPlacement,
      ProtanProjectStepStatusEnum.Completed
    );

    // Set wedges to completed for now, but should be set to in progress when the user starts drawing the wedges
    setStepStatus(
      ProtanProjectStepEnum.WedgePlacement,
      ProtanProjectStepStatusEnum.Completed
    );
  }
};
