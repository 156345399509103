import { useMemo } from 'react';
import { norm } from 'mathjs';
import { Substrate } from '../../../ProductChoices';
import { SlopedInsulationCrossSection } from '../../SheetShapeDrawing';
import { getPreAssembledFastenerProductForInsulationDepthAndSubstrate } from '../pre-assembled-fastener-products';
import {
  LineSegment2,
  Point2,
  Polygon2,
} from 'src/domain/geometry/geometric-types';
import { SheetShapeDeepFragment } from 'src/gql/graphql';
import { toMultiPolygon2 } from 'src/domain/geometry/algorithms/util/type-mapping';
import {
  intersectLineWithPolygon,
  polygonDifference,
} from 'src/domain/geometry/algorithms/util/polygon';
import { calculateSheetShapeCornerAreas } from 'src/domain/geometry/algorithms/sheet-shape-corner';
import { calculateSheetShapeRandfelt } from 'src/domain/geometry/algorithms/sheet-shape-randfelt';
import { calculateRelativePosition } from 'src/components/orderContractor/shapes/util';

export type PreAssembledFastenerProduct = {
  name: string;
  sku: string;
  dimensions: {
    minInsulationDepth: number;
    maxInsulationDepth: number;
  };
};

export type PreAssembledFastenerSlice = {
  polygons: Polygon2[] | undefined;
  color: string;
  product: PreAssembledFastenerProduct;
};

export type FastenerPoint = {
  position: Point2;
  height: number;
  // product will be added later when we implement product determination
};

type UsePreAssembledFastenerSlicesReturn = {
  productListWithCount: Record<string, number>;
  productListWithPositions: Record<string, FastenerPoint[]>;
};

const CORNER_ZONE_SPACING = 0.3;
const EDGE_ZONE_SPACING = 0.35;
const MID_ZONE_SPACING = 0.65;

export function usePreAssembledFastenerSlices(
  crossSection: SlopedInsulationCrossSection,
  roofShape: SheetShapeDeepFragment | undefined,
  substrate: Substrate,
  qKast: number,
  buildingHeight: number,
  unitScale: number
): UsePreAssembledFastenerSlicesReturn {
  const cornerZonePolygons = useMemo(() => {
    if (!roofShape?.sheetShapePolygon?.multipolygon) return null;

    return calculateSheetShapeCornerAreas(
      toMultiPolygon2(roofShape?.sheetShapePolygon?.multipolygon),
      buildingHeight,
      unitScale
    ).multiPolygon.polygons;
  }, [roofShape?.sheetShapePolygon?.multipolygon, buildingHeight, unitScale]);

  const randfeltPolygons = useMemo(() => {
    if (!roofShape?.sheetShapePolygon?.multipolygon) return null;

    return calculateSheetShapeRandfelt(
      toMultiPolygon2(roofShape?.sheetShapePolygon?.multipolygon),
      buildingHeight,
      unitScale
    ).multiPolygon;
  }, [roofShape?.sheetShapePolygon?.multipolygon, buildingHeight, unitScale]);

  const edgeZonePolygons = useMemo(() => {
    if (!roofShape?.sheetShapePolygon?.multipolygon) return null;
    if (!cornerZonePolygons || !randfeltPolygons) return null;

    // Remove corner zones from randfelt polygons to get edge zones
    const edgeZones = randfeltPolygons.polygons.flatMap((polygon) => {
      // Start with the original polygon as a single-element array
      let remainingPolygons = [polygon];

      // Subtract each corner zone from all remaining polygons
      for (const cornerZone of cornerZonePolygons) {
        try {
          remainingPolygons = remainingPolygons.flatMap((poly) =>
            polygonDifference(poly, cornerZone, 0.1)
          );
        } catch (error) {
          console.warn('Polygon difference calculation failed:', error);
        }
      }

      return remainingPolygons;
    });

    return edgeZones;
  }, [
    roofShape?.sheetShapePolygon?.multipolygon,
    cornerZonePolygons,
    randfeltPolygons,
  ]);

  const midZonePolygons = useMemo(() => {
    if (!randfeltPolygons || !roofShape?.sheetShapePolygon?.multipolygon)
      return null;

    const roofShapePolygons = toMultiPolygon2(
      roofShape?.sheetShapePolygon?.multipolygon
    );

    return roofShapePolygons.polygons.flatMap((polygon) => {
      // Subtract the randfelt polygons
      try {
        return randfeltPolygons?.polygons.flatMap((randfelt) =>
          polygonDifference(polygon, randfelt, 0.1)
        );
      } catch (error) {
        console.warn('Polygon difference calculation failed:', error);
        return [];
      }
    });
  }, [randfeltPolygons, roofShape?.sheetShapePolygon?.multipolygon]);

  const fastenerLinesInCornerZone = useMemo(() => {
    if (!cornerZonePolygons || !crossSection.baseLine) return [];
    return generateFastenerLinesForZone(
      cornerZonePolygons,
      crossSection.baseLine,
      unitScale
    );
  }, [cornerZonePolygons, crossSection.baseLine, unitScale]);

  const fastenerLinesInEdgeZone = useMemo(() => {
    if (!edgeZonePolygons || !crossSection.baseLine) return [];
    return generateFastenerLinesForZone(
      edgeZonePolygons,
      crossSection.baseLine,
      unitScale
    );
  }, [edgeZonePolygons, crossSection.baseLine, unitScale]);

  const fastenerLinesInMidZone = useMemo(() => {
    if (!midZonePolygons || !crossSection.baseLine) return [];
    return generateFastenerLinesForZone(
      midZonePolygons,
      crossSection.baseLine,
      unitScale
    );
  }, [midZonePolygons, crossSection.baseLine, unitScale]);

  const fastenerLinesWithSpacing = useMemo(() => {
    return [
      { lines: fastenerLinesInCornerZone, spacing: CORNER_ZONE_SPACING },
      { lines: fastenerLinesInEdgeZone, spacing: EDGE_ZONE_SPACING },
      { lines: fastenerLinesInMidZone, spacing: MID_ZONE_SPACING },
    ];
  }, [
    fastenerLinesInCornerZone,
    fastenerLinesInEdgeZone,
    fastenerLinesInMidZone,
  ]);

  const productListWithPositions = useMemo(() => {
    return getProductListWithPositions(
      fastenerLinesWithSpacing,
      crossSection,
      substrate,
      unitScale
    );
  }, [fastenerLinesWithSpacing, crossSection, substrate, unitScale]);

  const productListWithCount = useMemo(() => {
    return Object.entries(productListWithPositions).reduce(
      (acc, [productSku, points]) => {
        acc[productSku] = points.length;
        return acc;
      },
      {} as Record<string, number>
    );
  }, [productListWithPositions]);

  return {
    productListWithCount,
    productListWithPositions,
  };
}

function calculateHeightAtPosition(
  position: number,
  crossSection: SlopedInsulationCrossSection,
  unitScale: number
): number {
  const baselineLength = norm(crossSection.baseLine) as number;

  const lowPoints = [...crossSection.lowPoints].sort((a, b) => a - b);

  // If we're at the start or end, these are highPoints
  if (position === 0 || position === 1) {
    const nearestLowPoint = lowPoints.reduce(
      (nearest, point) =>
        Math.abs(point - position) < Math.abs(nearest - position)
          ? point
          : nearest,
      lowPoints[0]
    );
    const distanceToLowPoint =
      Math.abs(position - nearestLowPoint) * baselineLength;
    return (
      crossSection.minHeight +
      crossSection.slope * distanceToLowPoint * unitScale
    );
  }

  // Find the segment this position belongs to
  let leftLowPoint = -1;
  let rightLowPoint = 2; // Initialize outside valid range

  for (let i = 0; i < lowPoints.length - 1; i++) {
    if (position >= lowPoints[i] && position <= lowPoints[i + 1]) {
      leftLowPoint = lowPoints[i];
      rightLowPoint = lowPoints[i + 1];
      break;
    }
  }

  // If we're at a lowPoint, return minHeight
  if (lowPoints.includes(position)) {
    return crossSection.minHeight;
  }

  // Calculate nearest lowPoint and distance to it
  const nearestLowPoint = lowPoints.reduce(
    (nearest, point) =>
      Math.abs(point - position) < Math.abs(nearest - position)
        ? point
        : nearest,
    lowPoints[0]
  );

  // Calculate highPoint if we're between two lowPoints
  if (leftLowPoint !== -1 && rightLowPoint !== 2) {
    const highPoint = (leftLowPoint + rightLowPoint) / 2;
    if (position === highPoint) {
      const distanceToLowPoint =
        Math.abs(position - leftLowPoint) * baselineLength;
      return (
        crossSection.minHeight +
        crossSection.slope * distanceToLowPoint * unitScale
      );
    }
  }

  // For all other positions, interpolate linearly based on distance to nearest lowPoint
  const distanceToLowPoint =
    Math.abs(position - nearestLowPoint) * baselineLength;
  return (
    crossSection.minHeight + crossSection.slope * distanceToLowPoint * unitScale
  );
}

function generateFastenerLinesForZone(
  zone: Polygon2[],
  baseLine: LineSegment2,
  unitScale: number
): LineSegment2[] {
  if (!zone.length || !baseLine) return [];

  const FASTENER_LINE_SPACING = 1 - 0.1; // 1m spacing with 10cm overlap

  // Get the direction vector from the baseline
  const baselineVector = [
    baseLine[1][0] - baseLine[0][0],
    baseLine[1][1] - baseLine[0][1],
  ] as Point2;

  // Normalize the direction vector
  const length = norm(baselineVector) as number;
  const normalizedDirection = [
    baselineVector[0] / length,
    baselineVector[1] / length,
  ] as Point2;

  // Calculate perpendicular vector for spacing
  const perpVector = [
    -normalizedDirection[1],
    normalizedDirection[0],
  ] as Point2;

  const allLines: LineSegment2[] = [];

  // Handle each polygon separately
  zone.forEach((polygon) => {
    const polygonPoints = polygon.exterior;

    // Find a reference point for this polygon (let's use the centroid)
    const centroid: Point2 = [
      polygonPoints.reduce((sum, p) => sum + p[0], 0) / polygonPoints.length,
      polygonPoints.reduce((sum, p) => sum + p[1], 0) / polygonPoints.length,
    ];

    // Project points relative to the centroid
    const projections = polygonPoints.map(
      (p) =>
        (p[0] - centroid[0]) * perpVector[0] +
        (p[1] - centroid[1]) * perpVector[1]
    );

    const minProj = Math.min(...projections);
    const maxProj = Math.max(...projections);

    // Find baseline extends for line length
    const baselineProjections = polygonPoints.map(
      (p) =>
        (p[0] - centroid[0]) * normalizedDirection[0] +
        (p[1] - centroid[1]) * normalizedDirection[1]
    );
    const minBaselineProj = Math.min(...baselineProjections);
    const maxBaselineProj = Math.max(...baselineProjections);
    const lineLength = (maxBaselineProj - minBaselineProj) * 1.2; // 20% extra for safety

    // Generate parallel lines for this polygon
    for (
      let proj = minProj + 0.1 / unitScale; // 10cm offset
      proj <= maxProj;
      proj += FASTENER_LINE_SPACING / unitScale
    ) {
      // Calculate base point at this projection, offset from the centroid
      const basePoint: Point2 = [
        centroid[0] + proj * perpVector[0],
        centroid[1] + proj * perpVector[1],
      ];

      // Create start and end points by extending in baseline direction
      const startPoint: Point2 = [
        basePoint[0] - (normalizedDirection[0] * lineLength) / 2,
        basePoint[1] - (normalizedDirection[1] * lineLength) / 2,
      ];
      const endPoint: Point2 = [
        basePoint[0] + (normalizedDirection[0] * lineLength) / 2,
        basePoint[1] + (normalizedDirection[1] * lineLength) / 2,
      ];

      const line: LineSegment2 = [startPoint, endPoint];

      zone.forEach((polygon) => {
        try {
          const intersectionSegments = intersectLineWithPolygon(line, polygon);
          if (intersectionSegments.length > 0) {
            allLines.push(...intersectionSegments);
          }
        } catch (error) {
          console.warn('Failed to process line intersection:', error);
        }
      });
    }
  });

  return allLines;
}

export function isValidPoint(point: Point2): boolean {
  return point && isFinite(point[0]) && isFinite(point[1]);
}

function calculateFastenerPointsForLine(
  line: LineSegment2,
  spacing: number,
  unitScale: number,
  crossSection: SlopedInsulationCrossSection
): FastenerPoint[] {
  const [start, end] = line;
  if (!isValidPoint(start) || !isValidPoint(end)) {
    return [];
  }

  const dx = end[0] - start[0];
  const dy = end[1] - start[1];
  const length = Math.hypot(dx, dy);
  if (length < 0.0001 || !isFinite(length)) return [];

  const OFFSET = 0.05 / unitScale;
  const availableLength = length - 2 * OFFSET;
  const numPoints = Math.max(
    1,
    Math.floor(availableLength / (spacing / unitScale))
  );

  const points: FastenerPoint[] = [];
  for (let j = 0; j <= numPoints; j++) {
    const t = (OFFSET + j * (availableLength / numPoints)) / length;
    if (t > 1) continue;

    const position: Point2 = [start[0] + dx * t, start[1] + dy * t];
    if (isValidPoint(position)) {
      const baselinePosition = calculateRelativePosition(
        position,
        crossSection.baseLine
      );
      const height = calculateHeightAtPosition(
        baselinePosition,
        crossSection,
        unitScale
      );
      points.push({ position, height });
    }
  }

  return points;
}

function getProductListWithPositions(
  fastenerLinesWithSpacing: {
    lines: LineSegment2[];
    spacing: number;
  }[],
  crossSection: SlopedInsulationCrossSection,
  substrate: Substrate,
  unitScale: number
): Record<string, FastenerPoint[]> {
  const result: Record<string, FastenerPoint[]> = {};
  const heightCache = new Map<string, number>();
  const productCache = new Map<number, PreAssembledFastenerProduct | null>();

  const getHeightCached = (position: number): number => {
    const key = position.toFixed(3); // 3 decimal precision should be enough
    if (!heightCache.has(key)) {
      heightCache.set(
        key,
        calculateHeightAtPosition(position, crossSection, unitScale)
      );
    }
    return heightCache.get(key)!;
  };

  const getProductCached = (height: number) => {
    const key = Math.round(height * 100); // 1cm precision for product selection
    if (!productCache.has(key)) {
      productCache.set(
        key,
        getPreAssembledFastenerProductForInsulationDepthAndSubstrate(
          height,
          substrate
        )
      );
    }
    return productCache.get(key);
  };

  for (const { lines, spacing } of fastenerLinesWithSpacing) {
    for (const line of lines) {
      const points = calculateFastenerPointsForLine(
        line,
        spacing,
        unitScale,
        crossSection
      );

      for (const point of points) {
        // Calculate actual height at this point using the cross section
        const t = calculateRelativePosition(
          point.position,
          crossSection.baseLine
        );
        const height = getHeightCached(t);
        point.height = height;

        const product = getProductCached(height);
        if (!product) continue;

        if (!result[product.sku]) {
          result[product.sku] = [];
        }
        result[product.sku].push(point);
      }
    }
  }

  return result;
}
