import { faEyeSlash, faMousePointer } from '@fortawesome/free-solid-svg-icons';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IsolationIcon } from '../../assets/icons/isolation.svg';

import {
  isShapeHidden,
  shapeDbIdDataAttribute,
  shapeUrnDataAttribute,
} from '../../components/SheetShapes';
import {
  DbIdsPerModel,
  SheetShapesMap,
} from '../../components/common/ForgeViewer';
import SparkelIcon from '../../components/common/icon/SparkelIcon';
import { SheetsVisibilityManager } from '../../components/viewers/common/hooks/useSheetsVisibilityManager';
import {
  ContextMenuContextType,
  ContextMenuProvider,
  OnShowProps,
  PredicateParams,
  useCustomContextMenu,
} from './CustomContextMenu';

export type SheetViewerMenuProps = {
  selections: DbIdsPerModel;
  hasSelectedElements: boolean;
  target?: {
    type: 'SHAPE';
    modelUrn: string;
    dbId: number;
  };
};

export const SheetContextMenuContext = createContext<
  ContextMenuContextType<SheetViewerMenuProps>
>({
  registerItem: () => {
    throw new Error('No Context Menu Context provider found.');
  },
  unregisterItem: () => {
    throw new Error('No Context Menu Context provider found.');
  },
  items: [],
});

export const SheetContextMenuProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <ContextMenuProvider context={SheetContextMenuContext}>
      {children}
    </ContextMenuProvider>
  );
};

export function useSheetViewerContextMenu(
  viewerRef: React.RefObject<HTMLDivElement>,
  visibilityManager: SheetsVisibilityManager,
  selectedDbIds: DbIdsPerModel,
  setSelectedDbIds: (dbIds: DbIdsPerModel) => void,
  renderedSheetShapes: SheetShapesMap
) {
  const showAndSelect = useCallback(
    async ({ event, showContextMenu }: OnShowProps<SheetViewerMenuProps>) => {
      const currentViewer = viewerRef.current;
      if (!currentViewer) {
        return;
      }
      // First, check if no elements are selected and there is on under the cursor
      let clientX: number;
      let clientY: number;

      if (event.type === 'mouseup') {
        event = event as React.MouseEvent<HTMLDivElement>;
        clientX = event.clientX;
        clientY = event.clientY;
      } else {
        invariant(event.type === 'touchstart', 'Unexpected event type');
        const touch = (event as React.TouchEvent<HTMLDivElement>).touches[0];
        clientX = touch.clientX;
        clientY = touch.clientY;
      }

      const currentSelection = Object.values(selectedDbIds).flat();

      const clickedElement = document.elementFromPoint(clientX, clientY);
      // read dbId and urn from clickedElement, if present
      const dbIdString = clickedElement?.getAttribute(shapeDbIdDataAttribute);
      const urn = clickedElement?.getAttribute(shapeUrnDataAttribute);

      if (dbIdString && urn && currentSelection.length === 0) {
        const dbId = Number(dbIdString);
        setSelectedDbIds({
          [urn]: [Number(dbId)],
        });
        showContextMenu({
          event,
          props: {
            selections: {
              [urn]: [dbId],
            },
            hasSelectedElements: true,
            target: {
              type: 'SHAPE',
              dbId,
              modelUrn: urn,
            },
          },
        });
      } else if (currentSelection.length === 1) {
        showContextMenu({
          event,
          props: {
            hasSelectedElements: true,
            selections: selectedDbIds,
            target: {
              type: 'SHAPE',
              dbId: currentSelection[0],
              modelUrn: Object.keys(selectedDbIds)[0],
            },
          },
        });
      } else if (currentSelection.length > 1) {
        showContextMenu({
          event,
          props: {
            hasSelectedElements: true,
            selections: selectedDbIds,
          },
        });
      } else {
        showContextMenu({ event });
      }
    },
    [selectedDbIds, setSelectedDbIds, viewerRef]
  );

  const { registerItem, unregisterItem } = useSheetContextMenuContext();

  const selectAllVisibleHandler = useCallback(() => {
    const visibleShapes = Object.fromEntries(
      Object.keys(renderedSheetShapes).map((urn) => [
        urn,
        renderedSheetShapes[urn]
          .filter(
            (shape) =>
              !isShapeHidden(
                visibilityManager.hiddenElements,
                urn,
                shape,
                visibilityManager.hiddenSheetShapes,
                visibilityManager.isolatedElements,
                false
              )
          )
          .map((shape) => shape.dbId),
      ])
    );
    setSelectedDbIds(visibleShapes);
  }, [
    renderedSheetShapes,
    setSelectedDbIds,
    visibilityManager.hiddenElements,
    visibilityManager.hiddenSheetShapes,
    visibilityManager.isolatedElements,
  ]);

  const hideSelectedHandler = useCallback(() => {
    visibilityManager.hide(selectedDbIds);
    setSelectedDbIds({});
  }, [selectedDbIds, setSelectedDbIds, visibilityManager]);

  const isolateSelectedHandler = useCallback(() => {
    visibilityManager.setIsolatedElements(selectedDbIds);
  }, [selectedDbIds, visibilityManager]);

  const hideSelectionRelatedItems = useCallback(
    ({ props }: PredicateParams<SheetViewerMenuProps>) => {
      if (!props) {
        return true;
      } else {
        return !props.hasSelectedElements;
      }
    },
    []
  );

  const { t } = useTranslation('project');

  useEffect(() => {
    registerItem({
      id: 'isolateSelected',
      label: t('viewer-menu.isolate-selected'),
      onClick: isolateSelectedHandler,
      hidden: hideSelectionRelatedItems,
      group: 'viewer visibility',
      icon: <SparkelIcon as={IsolationIcon} />,
    });
    return () => {
      unregisterItem('isolateSelected');
    };
  }, [
    hideSelectionRelatedItems,
    registerItem,
    isolateSelectedHandler,
    unregisterItem,
    t,
  ]);

  useEffect(() => {
    registerItem({
      id: 'hideSelected',
      label: t('viewer-menu.hide-selected'),
      onClick: hideSelectedHandler,
      hidden: hideSelectionRelatedItems,
      group: 'viewer visibility',
      icon: <SparkelIcon size="sm" fixedWidth icon={faEyeSlash} />,
    });
    return () => {
      unregisterItem('hideSelected');
    };
  }, [
    hideSelectionRelatedItems,
    registerItem,
    hideSelectedHandler,
    unregisterItem,
    t,
  ]);

  useEffect(() => {
    registerItem({
      id: 'selectAllVisible',
      label: t('viewer-menu.select-visible'),
      onClick: selectAllVisibleHandler,
      group: 'viewer selection',
      icon: <SparkelIcon size="sm" fixedWidth icon={faMousePointer} />,
    });
    return () => {
      unregisterItem('selectAllVisible');
    };
  }, [registerItem, selectAllVisibleHandler, t, unregisterItem]);

  const { items } = useSheetContextMenuContext();
  return useCustomContextMenu(showAndSelect, items, [
    'viewer visibility',
    'viewer selection',
  ]);
}
export const useSheetContextMenuContext = () =>
  useContext(SheetContextMenuContext);
