import {
  SheetViewerContextType,
  useSheetViewer,
} from 'src/components/common/SheetViewer';
import { ViewerMode } from 'src/components/common/viewer-mode';
import { useEnabledSheetViewerModeValue } from 'src/components/viewers/common/hooks/useEnabledSheetViewerModeValue';
import { useSheetViewerV2 } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetViewerV2';
import { SheetsViewerV2ContextType } from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.context';

export function useEnabledSheetViewer():
  | SheetViewerContextType
  | SheetsViewerV2ContextType {
  const { sheetsViewerMode } = useEnabledSheetViewerModeValue();
  const sheetViewerV1 = useSheetViewer();
  const sheetViewerV2 = useSheetViewerV2();

  return sheetsViewerMode === ViewerMode.SheetsV2
    ? sheetViewerV2
    : sheetViewerV1;
}
