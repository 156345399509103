import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { AbstractSheetFilledShape } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetFilledShape';
import { SheetShapeStyleType } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import { MultiPolygon2Fragment, SheetShapeDeepFragment } from 'src/gql/graphql';
import Edit2D = Autodesk.Extensions.Edit2D;
import GuiViewer3D = Autodesk.Viewing.GuiViewer3D;
import Viewer3D = Autodesk.Viewing.Viewer3D;

export class PolygonShape extends AbstractSheetFilledShape {
  constructor(
    viewer: Viewer3D | GuiViewer3D,
    edit2d: Edit2D,
    activeSheetData: ActiveSheetData,
    shapeModel: SheetShapeDeepFragment
  ) {
    super(viewer, edit2d, activeSheetData, shapeModel);

    if (!shapeModel.sheetShapePolygon) {
      throw new Error('shapeModel.sheetShapePolygon must be defined');
    }

    this.shapes = shapeModel.sheetShapePolygon.multipolygon.polygons.map(
      (polygon) => {
        const svg = this._toSVG(polygon);

        // https://aps.autodesk.com/en/docs/viewer/v7/developers_guide/advanced_options/edit2d-manual/#import-and-convert-svg-shapes
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Autodesk.Edit2D.Shape.fromSVG(svg);
      }
    );
    this.textLabelPoints = this.shapes.map((shape) => shape.getBBox().center());
    this.setStyle(SheetShapeStyleType.default);
  }

  private _toSVG(polygon: MultiPolygon2Fragment['polygons'][number]): string {
    const exterior =
      'M ' +
      polygon.exterior.points
        .map((point) => {
          const converted = super.convertPointToRender(point);

          return `${converted.x},${converted.y}`;
        })
        .join(' L ');
    const interiors = polygon.interiors
      .map(
        ({ points }) =>
          'M ' +
          points
            .map((point) => {
              const converted = super.convertPointToRender(point);

              return `${converted.x},${converted.y}`;
            })
            .join(' L ')
      )
      .join(' ');

    return `<path d="${exterior} Z ${interiors} Z"/>`;
  }
}
