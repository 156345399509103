import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { useCalibration } from 'src/components/viewers/SheetsViewerV2/hooks/useCalibration';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { ApiCoordinatesConverter } from 'src/components/viewers/SheetsViewerV2/utils/apiCoordinatesConverter';

export function getCalibrationDistance(
  viewer: AggregatedView['viewer'],
  activeSheetData: ActiveSheetData,
  calibration: ReturnType<typeof useCalibration>['existing']
) {
  if (!calibration) {
    return 0;
  }

  const converter = new ApiCoordinatesConverter(
    viewer,
    activeSheetData.viewport
  );
  const { pointA, pointB } = calibration;
  const convertedA = converter.convertToAutodeskPoint(pointA);
  const convertedB = converter.convertToAutodeskPoint(pointB);

  return convertedA.distanceTo(convertedB);
}
