import { useCallback, useState } from 'react';
import { ActiveSheetData } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';

type Props = ActiveSheetData | undefined;

export type ActiveSheetPagination = {
  pageNumber: number;
  numberOfPages: number;
  setPageNumber: (pageNumber: number) => void;
};

export const useActiveSheetPagination = (
  activeSheetData: Props
): ActiveSheetPagination => {
  const [pageNumber, setPageNumberValue] = useState(1);
  const numberOfPages = activeSheetData?.totalPages ?? 1;
  const setPageNumber = useCallback(
    (page: number) => {
      if (page > numberOfPages) {
        setPageNumberValue(numberOfPages);
      } else if (page < 1) {
        setPageNumberValue(1);
      } else if (Number.isInteger(page)) {
        setPageNumberValue(page);
      }
    },
    [numberOfPages]
  );

  return {
    pageNumber,
    numberOfPages,
    setPageNumber,
  };
};
