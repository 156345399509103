import { GridItem } from '@chakra-ui/react';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useState,
} from 'react';
import { useViewerMode, ViewerMode } from 'src/components/common/viewer-mode';
import { useEnabledSheetViewerModeValue } from 'src/components/viewers/common/hooks/useEnabledSheetViewerModeValue';
import {
  SelectedDbIds,
  useSelectedDbIds,
} from 'src/components/viewers/common/hooks/useSelectedDbIds';
import { SheetShapesManager } from 'src/components/viewers/common/hooks/useSheetShapesManager';
import {
  SheetsVisibilityManager,
  useSheetsVisibility,
} from 'src/components/viewers/common/hooks/useSheetsVisibilityManager';
import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import {
  ActiveSheet,
  useActiveSheet,
} from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheet';
import { ActiveSheetPagination } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheetPagination';
import {
  SheetViewerV2Calibration,
  useCalibration,
} from 'src/components/viewers/SheetsViewerV2/hooks/useCalibration';
import { useMeasurementTool } from 'src/components/viewers/SheetsViewerV2/hooks/useMeasurementTool';
import { useShapes } from 'src/components/viewers/SheetsViewerV2/hooks/useShapes';
import { useSheetsAggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { Sheet } from 'src/hooks/sheets';
import AggregatedView = Autodesk.Viewing.AggregatedView;

export type SheetsViewerV2ContextType = SelectedDbIds & {
  viewer: {
    elementRef: MutableRefObject<HTMLDivElement | null>;
    aggregated: AggregatedView | null;
  };
  viewerMode: ViewerMode;
  activeSheet: ActiveSheet | null;
  activeSheetId: string | null;
  changeActiveSheet: (sheetId: string | null) => void;
  sheets: Sheet[];
  pagination: ActiveSheetPagination;
  setPageNumber: (pageNumber: number) => void;
  calibration: SheetViewerV2Calibration;
  shapesManager: SheetShapesManager;
  visibilityManager: SheetsVisibilityManager;
  activeTool: ActiveTool;
  setActiveTool: Dispatch<SetStateAction<ActiveTool>>;
  measurement: { isMeasuring: boolean };
};

export const SheetsViewerV2Context =
  createContext<SheetsViewerV2ContextType | null>(null);

type Props = {
  projectId: string;
  shouldHideViewer: boolean;
  children: ReactElement;
};

export function SheetsViewerV2ContextProvider(props: Props) {
  const [activeTool, setActiveTool] = useState(ActiveTool.Select);
  const activeToolContext = { activeTool, setActiveTool };
  const { projectId, children, shouldHideViewer } = props;
  const { viewerMode } = useViewerMode();
  const { sheetsViewerMode } = useEnabledSheetViewerModeValue();
  const { viewerRef, aggregatedView } = useSheetsAggregatedView();
  const activeSheetContext = useActiveSheet({ projectId, aggregatedView });
  const calibration = useCalibration({
    aggregatedView,
    ...activeToolContext,
    ...activeSheetContext,
  });
  const visibilityManager = useSheetsVisibility();
  const selectDbIds = useSelectedDbIds();

  const { shapesManager } = useShapes(
    aggregatedView?.viewer,
    activeTool,
    activeSheetContext.activeSheet,
    selectDbIds,
    visibilityManager
  );

  const measurement = useMeasurementTool({
    activeTool,
    viewer: aggregatedView?.viewer,
    calibration: calibration.existing,
    activeSheetData: activeSheetContext.activeSheet?.data || null,
  });

  return (
    <SheetsViewerV2Context.Provider
      value={{
        viewer: {
          elementRef: viewerRef,
          aggregated: aggregatedView,
        },
        viewerMode,
        calibration,
        shapesManager,
        visibilityManager,
        measurement,
        ...activeSheetContext,
        ...selectDbIds,
        ...activeToolContext,
      }}
    >
      <>
        {children}
        {sheetsViewerMode === ViewerMode.SheetsV2 && (
          <GridItem
            id={'sheets-viewer-v2'}
            position="relative"
            ref={viewerRef}
            zIndex={
              !shouldHideViewer && viewerMode === ViewerMode.SheetsV2 ? 1 : 0
            }
            opacity={
              !shouldHideViewer && viewerMode === ViewerMode.SheetsV2 ? 1 : 0
            }
            pointerEvents={shouldHideViewer ? 'none' : undefined}
            area="sidebar-left-start / sidebar-left-start / sidebar-right-end / sidebar-right-end"
            sx={{
              '.adsk-viewing-viewer': {
                transition: 'opacity 0.8s ease-in-out',
                opacity: 1,
                bottom: undefined,
              },
              '@media (max-width: 1200px)': {
                gridArea:
                  'sidebar-left-start / sidebar-left-start / main-end / main-end',
              },
            }}
          />
        )}
      </>
    </SheetsViewerV2Context.Provider>
  );
}
