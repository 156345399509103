import {
  AbstractSheetShape,
  SheetShapeStyleType,
} from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import {
  DefaultShapeStyle,
  HoveredShapeStyle,
  SelectedShapeStyle,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';

export abstract class AbstractSheetFilledShape extends AbstractSheetShape {
  protected setStyle(type: SheetShapeStyleType) {
    this.shapes.forEach((shape) => {
      switch (type) {
        case SheetShapeStyleType.default:
          shape.style.lineWidth = DefaultShapeStyle.lineWidth;
          shape.style.fillAlpha = DefaultShapeStyle.fillAlpha;
          shape.style.lineColor = DefaultShapeStyle.lineColor;
          shape.style.fillColor = DefaultShapeStyle.fillColor;
          break;
        case SheetShapeStyleType.hovered:
          shape.style.lineWidth = HoveredShapeStyle.lineWidth;
          shape.style.fillAlpha = HoveredShapeStyle.fillAlpha;
          shape.style.lineColor = HoveredShapeStyle.lineColor;
          shape.style.fillColor = HoveredShapeStyle.fillColor;
          break;
        case SheetShapeStyleType.selected:
          shape.style.lineWidth = SelectedShapeStyle.lineWidth;
          shape.style.fillAlpha = SelectedShapeStyle.fillAlpha;
          shape.style.lineColor = SelectedShapeStyle.lineColor;
          shape.style.fillColor = SelectedShapeStyle.fillColor;
          break;
      }
    });
  }
}
