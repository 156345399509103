export function sortPointsOnAxis(
  points: THREE.Vector3[],
  axis: 'x' | 'y' | 'z'
) {
  return points.sort((a, b) => a[axis] - b[axis]);
}

export function angleBetweenTwoPoints(points: THREE.Vector3[]): number {
  if (points.length < 2) {
    throw new Error('At least two points are required to calculate an angle.');
  }

  const [start, end] = points;
  const direction = new THREE.Vector3().subVectors(end, start);
  const radians = Math.atan2(direction.y, direction.x);

  return radians * (180 / Math.PI);
}

export function snapAngle(angle: number, step = 45): number {
  return Math.round(angle / step) * step;
}

export function endPointByAngle(
  start: THREE.Vector3,
  angle: number,
  length: number
): THREE.Vector3 {
  const radians = (angle * Math.PI) / 180;

  return new THREE.Vector3(
    start.x + Math.cos(radians) * length,
    start.y + Math.sin(radians) * length,
    start.z
  );
}
