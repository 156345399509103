import { useEffect, useRef, useState } from 'react';
import { useActiveSheetPagination } from 'src/components/viewers/SheetsViewerV2/hooks/useActiveSheetPagination';
import { useLoadPDF } from 'src/components/viewers/SheetsViewerV2/hooks/useLoadPDF';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { MappedPDFData } from 'src/components/viewers/SheetsViewerV2/mappers/mapPDFData';
import { getSheetUrl } from 'src/components/viewers/SheetsViewerV2/utils/s3';
import { useLocalStorage } from 'src/hooks/local-storage';
import { useS3Client } from 'src/hooks/s3';
import { useSheets } from 'src/hooks/sheets';

type Props = { projectId: string; aggregatedView: AggregatedView | null };

export type ActiveSheetData = { id: string; page: number } & MappedPDFData;
export type ActiveSheet = {
  isLoading: boolean;
  data?: ActiveSheetData;
  error?: Error;
};
const InitActiveSheetState = { isLoading: true };

export const useActiveSheet = ({ projectId, aggregatedView }: Props) => {
  const debounceRef = useRef<number>();
  const s3Client = useS3Client(projectId);
  const { sheets } = useSheets(projectId);
  const { loadPDF } = useLoadPDF(aggregatedView);
  const [activeSheetId, changeActiveSheet] = useLocalStorage<string | null>(
    `${projectId}-active-sheet-id`,
    null
  );
  const [activeSheet, setActiveSheet] =
    useState<ActiveSheet>(InitActiveSheetState);
  const pagination = useActiveSheetPagination(activeSheet.data);

  useEffect(() => {
    if (!sheets || !activeSheetId) return;
    // reset pagination after changing a sheet model
    if (activeSheetId !== activeSheet?.data?.id) pagination.setPageNumber(1);
    // used debounce effect to prevent doubled loading of the sheet
    if (debounceRef.current) window.clearTimeout(debounceRef.current);

    debounceRef.current = window.setTimeout(() => {
      setActiveSheet((prevState) => ({ ...prevState, isLoading: true }));
      getSheetUrl(s3Client, sheets, activeSheetId)
        .then((signedUrl) =>
          signedUrl
            ? loadPDF(activeSheetId, signedUrl, pagination.pageNumber)
            : Promise.reject(new Error('Could not load pdf'))
        )
        .then((data) => {
          setActiveSheet({ data, isLoading: false });
        })
        .catch((error) => {
          setActiveSheet({ error, isLoading: false });
        });
    }, 50);
  }, [
    activeSheetId,
    aggregatedView,
    sheets,
    pagination.pageNumber,
    s3Client,
    loadPDF,
  ]);

  return {
    sheets: sheets || [],
    activeSheet,
    activeSheetId,
    pagination,
    setPageNumber: pagination.setPageNumber,
    changeActiveSheet,
  };
};
