import { Client } from 'urql';
import { BulkUpsertOrderEntryCacheDocument } from 'src/gql/graphql';

const mutationCache = new Map<string, Promise<unknown>>();

export function dedupedMutation<T>(client: Client, key: string, data: T) {
  if (mutationCache.has(key)) {
    return mutationCache.get(key)!;
  }
  const promise = client
    .mutation(BulkUpsertOrderEntryCacheDocument, {
      input: { items: data },
    })
    .toPromise()
    .finally(() => mutationCache.delete(key));
  mutationCache.set(key, promise);
  return promise;
}

const operationsCache = new Map<string, Promise<any>>();

export function dedupeOperations<T>(key: string, task: () => Promise<T>) {
  if (operationsCache.has(key)) {
    return { valuePromise: operationsCache.get(key)!, original: false };
  }

  const promise = task();
  operationsCache.set(key, promise);

  promise.finally(() => operationsCache.delete(key));
  return { valuePromise: promise, original: true };
}
