import { RectangularProduct, SlopedProduct } from './TaperedInsulation';
import { Buildup } from './tapered-insulation-products';

// Constants for surface resistance (m²K/W)
const SURFACE_RESISTANCE = {
  INTERNAL: 0.16, // For internal surface
  EXTERNAL: 0.04, // For external surface
};

type Product = RectangularProduct | SlopedProduct;

function isSlopedProduct(product: Product): product is SlopedProduct {
  return (
    'dimensions' in product &&
    'maxHeight' in product.dimensions &&
    'minHeight' in product.dimensions
  );
}

export function calculateR0(products: RectangularProduct[]): number {
  let totalR = 0;

  // Add R-values for all non-tapered layers
  for (const product of products) {
    // R = d/λ where d is thickness and λ is thermal conductivity
    totalR += product.dimensions.height / product.thermalConductivity;
  }

  return totalR;
}

export function calculateR2(taperedProduct: SlopedProduct): number {
  // R2 here represents only the triangular part of the tapered layer
  // R2 = (d2-d1)/λ where (d2-d1) is the height difference
  return (
    (taperedProduct.dimensions.maxHeight -
      taperedProduct.dimensions.minHeight) /
    taperedProduct.thermalConductivity
  );
}

export function calculateSliceUValue(products: Product[]): number {
  // Start with surface resistances
  let totalR = Object.values(SURFACE_RESISTANCE).reduce(
    (acc, resistance) => acc + resistance,
    0
  );

  // Find the tapered product (assuming one per slice)
  const taperedProduct = products.find(isSlopedProduct);

  if (!taperedProduct) {
    // If no tapered product, use simple R-value calculation
    totalR += calculateR0(products as RectangularProduct[]);
    return 1 / totalR;
  }

  // Calculate R0 for the rectangular products
  const rectangularProducts = products.filter(
    (p) => !isSlopedProduct(p)
  ) as RectangularProduct[];

  totalR += calculateR0(rectangularProducts);

  // Create a rectangular product for the base part of the tapered layer
  const rectangularPartOfSlopedProduct = {
    ...taperedProduct,
    dimensions: {
      height: taperedProduct.dimensions.minHeight,
    },
  };

  // Calculate R0 for the tapered layer
  const R0 = calculateR0([rectangularPartOfSlopedProduct]);

  // Calculate R2 for just the triangular part of the tapered layer
  const R2 = calculateR2(taperedProduct);

  // Calculate U value for tapered layer using the formula: U = 1/R2 * ln(1 + R2/R0)
  const U = (1 / R2) * Math.log(1 + R2 / R0);

  totalR += 1 / U;

  return 1 / totalR;
}

export function calculateRoofUValue(
  buildUp: Buildup,
  slicesWithArea: {
    products: Product[];
    area: number;
  }[]
): number {
  let totalWeightedU = 0;
  let totalArea = 0;

  for (const slice of slicesWithArea) {
    // Deep clone the products to prevent mutations
    const products = [
      ...slice.products.map(
        (p) => ({ ...p, dimensions: { ...p.dimensions } } as Product)
      ),
      {
        ...buildUp.topLayer,
        dimensions: { ...buildUp.topLayer.dimensions },
      } as Product,
    ];

    // Add the base layer if it exists
    buildUp.baseLayer &&
      products.unshift({
        ...buildUp.baseLayer,
        dimensions: { ...buildUp.baseLayer.dimensions },
      });

    const sliceU = calculateSliceUValue(products);
    totalWeightedU += sliceU * slice.area;
    totalArea += slice.area;
  }

  // Calculate overall U-value using area-weighted average
  return totalWeightedU / totalArea;
}
