import { useMemo } from 'react';
import { add, divide, max, min, multiply, norm, subtract } from 'mathjs';
import { useFlag } from '@unleash/proxy-client-react';
import {
  Point2,
  Polygon2,
  LinearRing2,
} from '../../../../../domain/geometry/geometric-types';
import { calculateRelativePosition } from '../../../shapes/util';
import {
  getOrientedBoundingBox,
  intersectPolygons,
  toJstsPolygon,
} from '../../../../../domain/geometry/algorithms/util/polygon';
import { toPolygon2 } from '../../../../../domain/geometry/algorithms/util/type-mapping';
import { getColorValueFromGradient } from '../../../../../utils/color-util';
import { GRADIENTS } from '../../../../../utils/row-color-util';
import { calculateRoofUValue } from '../thermal-calculation-util';
import {
  RectangularProduct,
  RectangularProductSegment,
  SlopedProduct,
  SlopedProductSegment,
} from '../TaperedInsulation';
import { SlopedInsulationCrossSection } from '../../SheetShapeDrawing';
import { Buildup } from '../tapered-insulation-products';
import { SheetShapeDeepFragment } from 'src/gql/graphql';

export type InsulationProductSlice = {
  products: (RectangularProduct | SlopedProduct)[];
  width: number;
  height: number;
};

export type InsulationProductSliceWithColorAndArea = {
  polygons: Polygon2[] | undefined;
  color: string;
  area: number;
  products: (RectangularProduct | SlopedProduct)[];
};

type UseInsulationProductSlicesProps = {
  slopedInsulationSegmentsPerTrapezoid: SlopedProductSegment[][];
  rectangularInsulationSegmentsPerTrapezoid: RectangularProductSegment[][];
  crossSection: SlopedInsulationCrossSection;
  roofShape: SheetShapeDeepFragment | undefined;
  unitScale: number;
  buildup: Buildup;
};

type UseInsulationProductSlicesReturn = {
  insulationProductSlices: InsulationProductSliceWithColorAndArea[] | null;
  productListWithArea: Record<string, number>;
  roofUValue: number | null;
};

export function useInsulationProductSlices({
  slopedInsulationSegmentsPerTrapezoid,
  rectangularInsulationSegmentsPerTrapezoid,
  crossSection,
  roofShape,
  unitScale,
  buildup,
}: UseInsulationProductSlicesProps): UseInsulationProductSlicesReturn {
  const enableUvalueCalculation = useFlag('enable-uvalue-calculation');

  const productSlices = useMemo(() => {
    const slices: InsulationProductSlice[] = [];

    for (
      let trapezoidIndex = 0;
      trapezoidIndex < slopedInsulationSegmentsPerTrapezoid.length;
      trapezoidIndex++
    ) {
      // Collect slices for this trapezoid in a temporary array
      const trapezoidSlices: InsulationProductSlice[] = [];

      // Alternate the order of the slices within each trapezoid (lowpoints and high points are every other)
      const shouldFlip = trapezoidIndex % 2 === 0;

      for (
        let segmentIndex = 0;
        segmentIndex <
        slopedInsulationSegmentsPerTrapezoid[trapezoidIndex].length;
        segmentIndex++
      ) {
        const slopedInsulationSegment =
          slopedInsulationSegmentsPerTrapezoid[trapezoidIndex][segmentIndex];
        const rectangularInsulationSegment =
          rectangularInsulationSegmentsPerTrapezoid[trapezoidIndex][
            segmentIndex
          ];

        const heightOfRectangularInsulation =
          rectangularInsulationSegment.products.reduce(
            (acc, product) => acc + product.dimensions.height,
            0
          );

        for (const slopedProduct of slopedInsulationSegment.products) {
          const products = [
            slopedProduct,
            ...rectangularInsulationSegment.products,
          ];

          const slice: InsulationProductSlice = {
            products,
            width: slopedProduct.dimensions.length,
            height:
              heightOfRectangularInsulation +
              slopedProduct.dimensions.minHeight,
          };

          trapezoidSlices.push(slice);
        }
      }

      if (shouldFlip) {
        trapezoidSlices.reverse();
      }

      slices.push(...trapezoidSlices);
    }

    return slices;
  }, [
    rectangularInsulationSegmentsPerTrapezoid,
    slopedInsulationSegmentsPerTrapezoid,
  ]);

  const productSlicePolygonsWithColor = useMemo(() => {
    if (!roofShape || productSlices.length === 0) {
      return null;
    }

    let offset = crossSection.baseLine[0] as Point2;

    const baseLineDirection = subtract(
      crossSection.baseLine[1],
      crossSection.baseLine[0]
    ) as Point2;

    const baseLineDirectionNormalized = divide(
      baseLineDirection,
      norm(baseLineDirection)
    ) as Point2;

    const normal = [
      -baseLineDirectionNormalized[1],
      baseLineDirectionNormalized[0],
    ] as Point2;

    const tValues = roofShape.sheetShapePolygon?.multipolygon.polygons.flatMap(
      (polygon) =>
        polygon.exterior.points.map((point) =>
          calculateRelativePosition([point.x, point.y], [[0, 0], normal])
        )
    );

    if (!tValues || tValues.length === 0) return null;

    const sliceHeight = max(tValues) - min(tValues);
    const minHeight = min(productSlices.map((slice) => slice.height));
    const maxHeight = max(productSlices.map((slice) => slice.height));

    return productSlices.map((slice) => {
      const sliceWidth = slice.width / unitScale;

      const polygon = {
        exterior: [
          offset,
          add(offset, multiply(sliceWidth, baseLineDirectionNormalized)),
          add(
            offset,
            add(
              multiply(sliceWidth, baseLineDirectionNormalized),
              multiply(sliceHeight, normal)
            )
          ),
          add(offset, multiply(sliceHeight, normal)),
          offset,
        ] as LinearRing2,
        interiors: [],
      } as Polygon2;

      const intersections =
        roofShape.sheetShapePolygon?.multipolygon.polygons.flatMap(
          (shapePolygon) => intersectPolygons(polygon, toPolygon2(shapePolygon))
        );

      offset = add(
        offset,
        multiply(sliceWidth, baseLineDirectionNormalized) as Point2
      );

      const heightFactor = (slice.height - minHeight) / (maxHeight - minHeight);
      const color = getColorValueFromGradient(GRADIENTS[0], heightFactor);

      const area =
        intersections?.reduce((acc, intersection) => {
          return acc + toJstsPolygon(intersection).getArea() * unitScale ** 2;
        }, 0) || 0;

      const sliceOrientedBoundingBoxes = intersections?.map((intersection) => {
        return getOrientedBoundingBox(intersection);
      });

      const sliceOrientedBoundingBoxArea =
        sliceOrientedBoundingBoxes?.reduce((acc, polygon) => {
          return acc + toJstsPolygon(polygon).getArea() * unitScale ** 2;
        }, 0) || 0;

      return {
        polygons: intersections,
        color,
        area,
        boundingBoxArea: sliceOrientedBoundingBoxArea,
        products: slice.products,
      };
    });
  }, [crossSection.baseLine, productSlices, roofShape, unitScale]);

  // Calculate total areas and roof U-value
  const { productListWithArea, roofUValue } = useMemo(() => {
    if (!productSlicePolygonsWithColor) {
      return { productListWithArea: {}, roofUValue: null };
    }

    const totalAreaPerProduct = new Map<string, number>();

    productSlicePolygonsWithColor.forEach((polygonWithColor) => {
      polygonWithColor.products.forEach((product) => {
        // Use the bounding box area, since this is represents how much actual product is in the slice
        const area = polygonWithColor.boundingBoxArea;
        const currentArea = totalAreaPerProduct.get(product.sku) || 0;
        totalAreaPerProduct.set(product.sku, currentArea + area);
      });
    });

    const roofUValue = enableUvalueCalculation
      ? Math.round(
          calculateRoofUValue(buildup, productSlicePolygonsWithColor) * 100
        ) / 100
      : null;

    return {
      productListWithArea: Object.fromEntries(totalAreaPerProduct),
      roofUValue,
    };
  }, [productSlicePolygonsWithColor, enableUvalueCalculation, buildup]);

  return {
    insulationProductSlices: productSlicePolygonsWithColor,
    productListWithArea,
    roofUValue,
  };
}
