import { ReactElement } from 'react';
import { SheetsViewerV2ContextProvider } from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.context';

type Props = {
  projectId: string;
  shouldHideViewer: boolean;
  children: ReactElement;
};

export function SheetsViewerV2({
  projectId,
  shouldHideViewer,
  children,
}: Props) {
  return (
    <SheetsViewerV2ContextProvider
      projectId={projectId}
      shouldHideViewer={shouldHideViewer}
    >
      {children}
    </SheetsViewerV2ContextProvider>
  );
}
