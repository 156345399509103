import { MultiPolygonAlgorithm } from '../../algorithm-types';
import {
  MultiPolylineResult,
  Polyline,
  Polyline2,
  ResultType,
} from '../../geometric-types';
import { to3dPoints, toPlanarMultiPolygon } from '../util/plane';
import { getOrientedBoundingBox } from '../util/polygon';

export const calculateShapeBoundingBoxLength: MultiPolygonAlgorithm<
  MultiPolylineResult
> = (polygon, plane) => {
  const planarPolygon = toPlanarMultiPolygon(polygon, plane);

  // For multipolygon, we need to process each polygon and find the one with smallest length
  let minLength = Infinity;
  let lengthLines: Polyline2[] = [];

  // Process each polygon in the multipolygon
  for (let i = 0; i < planarPolygon.polygons.length; i++) {
    const currentPolygon = planarPolygon.polygons[i];

    // Get the oriented bounding box for the current polygon
    const orientedBoundingBox = getOrientedBoundingBox(currentPolygon);

    // Extract the coordinates of the oriented bounding box
    const coordinates = orientedBoundingBox.exterior;

    // Calculate the lengths of the sides
    const side1 = Math.hypot(
      coordinates[1][0] - coordinates[0][0],
      coordinates[1][1] - coordinates[0][1]
    );
    const side2 = Math.hypot(
      coordinates[2][0] - coordinates[1][0],
      coordinates[2][1] - coordinates[1][1]
    );

    // Determine the length (larger dimension)
    const length = Math.max(side1, side2);

    // If this polygon has a smaller length, update our result
    if (length < minLength) {
      minLength = length;

      // Create lines representing the length sides of the bounding box
      lengthLines = [];
      if (side1 > side2) {
        // If side1 is the longer side, add the length line
        lengthLines.push([
          [coordinates[0][0], coordinates[0][1]],
          [coordinates[1][0], coordinates[1][1]],
        ]);
      } else {
        // If side2 is the longer side, add the length line
        lengthLines.push([
          [coordinates[1][0], coordinates[1][1]],
          [coordinates[2][0], coordinates[2][1]],
        ]);
      }
    }
  }

  // Convert the length lines to 3d lines
  const lines = lengthLines.map((line) =>
    to3dPoints(line, plane)
  ) as Polyline[];

  return {
    type: ResultType.MULTIPOLYLINE,
    value: minLength,
    multiPolyline: { lines },
    plane,
  };
};
