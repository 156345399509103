import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { CalibrationTool } from 'src/components/viewers/SheetsViewerV2/autodesk/tools/CalibrationTool';
import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { CalibrationDefaultLineStyle } from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';

type Props = {
  activeTool: ActiveTool;
  setActiveTool: Dispatch<SetStateAction<ActiveTool>>;
  viewer: AggregatedView['viewer'] | undefined;
  initPointA: THREE.Vector3 | null;
  initPointB: THREE.Vector3 | null;
  onChange: (
    pointA: THREE.Vector3 | null,
    pointB: THREE.Vector3 | null
  ) => void;
};

export function useCalibrationTool({
  activeTool,
  setActiveTool,
  viewer,
  initPointA,
  initPointB,
  onChange,
}: Props) {
  const calibrationToolInstance = useMemo(
    () =>
      viewer
        ? new CalibrationTool(viewer, onChange, initPointA, initPointB)
        : null,
    [viewer, onChange, initPointA, initPointB]
  );
  const isCalibrating = activeTool === ActiveTool.Calibration;
  const enable = () => setActiveTool(ActiveTool.Calibration);
  const disable = () => setActiveTool(ActiveTool.Default);

  useEffect(() => {
    if (!viewer || !calibrationToolInstance) {
      return;
    }

    if (isCalibrating) {
      viewer.toolController.registerTool(calibrationToolInstance);
      viewer.disableSelection(true);
      viewer.disableHighlight(true);
      viewer.setContextMenu(null);
      viewer.toolController.activateTool(calibrationToolInstance.getName());

      if (calibrationToolInstance.pointA && calibrationToolInstance.pointB) {
        CalibrationTool.drawLine(
          viewer,
          calibrationToolInstance.pointA,
          calibrationToolInstance.pointB,
          CalibrationDefaultLineStyle
        );
      }
    } else {
      viewer.setDefaultContextMenu();
      viewer.toolController.deactivateTool(calibrationToolInstance.getName());
      viewer.toolController.deregisterTool(calibrationToolInstance);
    }
  }, [calibrationToolInstance, isCalibrating, initPointA, initPointB, viewer]);

  return {
    enable,
    disable,
    isCalibrating,
  };
}
