import { Amplify } from '@aws-amplify/core';
import '@aws-amplify/ui-react/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Link,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useFlag } from '@unleash/proxy-client-react';

import { createContext, lazy, Suspense, useState } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  Link as RouterLink,
  RouterProvider,
  useParams,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import awsExports from './aws-exports';
import Header, { CrumbContext } from './components/header/Header';
import { LauncherMenu } from './components/launcher/LauncherMenu';

import { BreadcrumbProvider } from './components/common/BreadcrumbProvider';
import { ExportStateManager } from './components/common/ExportStateManagerContext';
import Company from './pages/Company';
import ProjectPage from './pages/ProjectPage';
import { AuthInfoProvider, useUserTenant } from './services/auth-info';
import SparkelErrorBoundary from './SparkelErrorBoundary';
import i18n from './i18n';
import LoadCustomerTranslations from './LoadCustomerTranslations';
import SharedViewPage from './pages/SharedViewPage';
import { ViewManagerProvider } from './components/common/ViewManagerContext';

const LoginPage = lazy(() => import('./pages/LoginPage'));
const ProjectDetailPage = lazy(() => import('./pages/ProjectDetailPage'));
const UserSettingsPage = lazy(
  () => import('./components/company/UserSettingsPage')
);
const UserAdminPageLazy = lazy(() => import('./pages/UserAdminPage'));

const UserAdminPage = () => {
  const user = useUserTenant();

  if (!user.isAdmin) {
    return <Navigate to="/" replace={true} />;
  } else {
    return <UserAdminPageLazy />;
  }
};

const ResourceOverviewPageLazy = lazy(
  () => import('./pages/ResourceOverviewPage')
);
const TemplatesSubPage = lazy(
  () => import('./components/company/TemplatesSubPage')
);
const ProjectsSubPage = lazy(
  () => import('./components/company/projectsPage/ProjectsSubPage')
);

// NOTE: We need to figure out what do with the naming of this page/file soon
const OrderContractorPageLazy = lazy(() => import('./pages/OrderContractor'));

const OrdersPanelLazy = lazy(
  () => import('./components/orderContractor/Orders')
);

const GraphiqlPlaygroundPageLazy = lazy(
  () => import(`./pages/GraphiqlPlaygroundPage`)
);

const IssuesPageLazy = lazy(() => import('./pages/IssuesPage'));

const GraphiqlPlaygroundPage = () => {
  const enableGraphiql = useFlag('enable-graphiql');
  if (!enableGraphiql) {
    return <Navigate to="/" replace={true} />;
  } else {
    return <GraphiqlPlaygroundPageLazy />;
  }
};
export const CommentsContext = createContext<any>(null);

const SparkelRootLayout = () => {
  const [headerRef, setHeaderRef] = useState<any>(null);

  return (
    <I18nextProvider i18n={i18n}>
      <SparkelErrorBoundary>
        <CommentsContext.Provider value={{ headerRef, setHeaderRef }}>
          <AuthInfoProvider>
            <LoadCustomerTranslations />
            <BreadcrumbProvider>
              <LauncherMenu />
              <Flex
                direction="column"
                height="100vh"
                justifyContent="flex-start"
              >
                <ExportStateManager>
                  <ViewManagerProvider>
                    <Header />
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  </ViewManagerProvider>
                </ExportStateManager>
              </Flex>
            </BreadcrumbProvider>
          </AuthInfoProvider>
        </CommentsContext.Provider>
      </SparkelErrorBoundary>
    </I18nextProvider>
  );
};

const SparkelNotFoundElement = () => {
  const [headerRef, setHeaderRef] = useState<any>(null);

  return (
    <SparkelErrorBoundary>
      <CommentsContext.Provider value={{ headerRef, setHeaderRef }}>
        <AuthInfoProvider>
          <BreadcrumbProvider>
            <LauncherMenu />
            <Flex direction="column" height="100vh" justifyContent="flex-start">
              <Header />
              <Alert
                status="info"
                flexDirection={'column'}
                display="flex"
                gap={2}
                background="none"
                justifyContent="center"
                height="50%"
              >
                <AlertIcon />
                <AlertTitle fontSize="xl">Page not found</AlertTitle>
                <AlertDescription>
                  <Link as={RouterLink} to="/projects" replace={true}>
                    Click here to go back to your list of projects.
                  </Link>
                </AlertDescription>
              </Alert>
            </Flex>
          </BreadcrumbProvider>
        </AuthInfoProvider>
      </CommentsContext.Provider>
    </SparkelErrorBoundary>
  );
};

// If non-appsync endpont is used, make sure Authorization header is correctly formatted
Amplify.configure(awsExports);

const createSentryRoutes = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = createSentryRoutes([
  {
    element: <SparkelRootLayout />,
    errorElement: <SparkelNotFoundElement />,
    children: [
      {
        path: 'project/:projectId',
        element: <ProjectPage />,
        children: [
          {
            path: 'resources',
            element: <ResourceOverviewPageLazy />,
            handle: {
              hideViewer: true,
              crumb: (context: CrumbContext) => ({
                value: context.projectName,
                label: 'Project',
                to: `/project/${context.projectId}/resources`,
              }),
            },
          },
          {
            path: 'takeoff',
            element: <OrderContractorPageLazy />,
            handle: {
              crumb: (context: CrumbContext) => ({
                value: context.projectName,
                label: 'Project',
                to: `/project/${context.projectId}/takeoff`,
              }),
            },
            children: [
              {
                path: ':orderId',
                element: <OrdersPanelLazy />,
                handle: {
                  showInReadonlyContext: true,
                  crumb: (context: CrumbContext) => ({
                    value: context.orderName,
                    label: 'Table',
                    to: `/project/${context.projectId}/takeoff/${context.orderId}`,
                  }),
                },
              },
            ],
          },
          {
            path: 'order',
            children: [
              {
                path: ':orderId',
                element: <NavigateToTakeoff />,
              },
              {
                index: true,
                element: <Navigate to="../takeoff" replace={true} />,
              },
            ],
          },
          {
            path: 'issues',
            element: <IssuesPageLazy />,
            handle: {
              crumb: (context: CrumbContext) => ({
                value: context.projectName,
                label: 'Project',
                to: `/project/${context.projectId}/issues`,
              }),
            },
          },
          {
            index: true,
            element: <Navigate to="takeoff" replace={true} />,
          },
        ],
      },
      {
        element: <Company />,
        children: [
          {
            path: 'projects',
            element: <ProjectsSubPage />,
            handle: {
              title: 'Projects',
            },
          },
          {
            path: 'templates',
            element: <TemplatesSubPage />,
            handle: {
              title: 'Templates',
            },
          },
          {
            path: 'user-settings',
            element: <UserSettingsPage />,
            handle: {
              title: 'User Settings',
            },
          },
          {
            path: 'users',
            element: <UserAdminPage />,
            handle: {
              title: 'Users',
            },
          },
          {
            path: 'graphiql',
            element: <GraphiqlPlaygroundPage />,
            handle: {
              title: 'GraphQL Playground',
            },
          },
        ],
      },

      {
        index: true,
        element: <Navigate to="projects" replace={true} />,
      },
    ],
  },
  {
    path: '/view/:viewId',
    element: (
      <Suspense>
        <SharedViewPage />
      </Suspense>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense>
        <LoginPage />
      </Suspense>
    ),
  },
]);

function NavigateToTakeoff() {
  let { orderId } = useParams();
  return <Navigate to={`../../takeoff/${orderId}`} replace={true} />;
}

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
