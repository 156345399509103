import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { useClient, useMutation, useQuery } from 'urql';
import {
  Select as ChakraReactSelect,
  ChakraStylesConfig,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import {
  faCaretDown,
  faCaretRight,
  faLink,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import {
  CreateInitialStateDocument,
  CreateSavedViewDocument,
  GetExternalAccessTokenDocument,
  GetOrdersForProjectDocument,
  InitialStateInput,
} from '../../gql/graphql';
import { useUserTenant } from '../../services/auth-info';
import { EventName, useMixpanel } from '../../services/mixpanel';
import { useBreadcrumb } from '../common/BreadcrumbProvider';
import SparkelIcon from '../common/icon/SparkelIcon';
import { useViewManager } from '../common/ViewManagerContext';

type ExpiresInChoice = {
  label: string;
  value: number;
};

type ProjectPathParams = {
  projectId: string;
};

// eslint-disable-next-line complexity
export default function OrderSharingPopover() {
  const client = useClient();
  const [error, setError] = useState<string | null>();
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);

  const { t } = useTranslation('project');

  const { orderId: currentOrderId, projectName, orderName } = useBreadcrumb();

  // Generate a list of choices for the expiry time
  const EXPIRY_CHOICES: ExpiresInChoice[] = useMemo(
    () => [
      {
        label: t('table-sharing-modal.expiration.5-minutes'),
        value: 60 * 60,
      },
      {
        label: t('table-sharing-modal.expiration.24-hours'),
        value: 24 * 60 * 60,
      },
      {
        label: t('table-sharing-modal.expiration.7-days'),
        value: 7 * 24 * 60 * 60,
      },
      {
        label: t('table-sharing-modal.expiration.14-days'),
        value: 14 * 24 * 60 * 60,
      },
      {
        label: t('table-sharing-modal.expiration.28-days'),
        value: 28 * 24 * 60 * 60,
      },
    ],
    [t]
  );

  const [expiresIn, setExpiresIn] = useState<number>(EXPIRY_CHOICES[2].value);
  const [, setAccessToken] = useState<string | null>(null);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);

  const { projectId } = useParams<
    keyof ProjectPathParams
  >() as ProjectPathParams;

  const { tenant } = useUserTenant();

  const { trackEvent } = useMixpanel();

  //const { savedViews, createSavedView } = useSavedViews(projectId, false);

  const {
    getCurrentView,
    getCurrentItemIdSelection,
    getCurrentExpandedItemIds,
  } = useViewManager();

  const [{ data: ordersData }] = useQuery({
    query: GetOrdersForProjectDocument,
    variables: { projectId },
  });

  const orders = useMemo(() => {
    if (ordersData?.orders) {
      return ordersData.orders.map((order) => ({
        id: order.id,
        name: order.name,
      }));
    }
    return [];
  }, [ordersData?.orders]);

  const currentOrder =
    useMemo(
      () => orders.find((order) => order.id === currentOrderId) || undefined,
      [currentOrderId, orders]
    ) || null;

  const [selectedOrders, setSelectedOrders] = useState(
    currentOrder ? [currentOrder] : orders
  );

  // Reset selected orders when the current order changes
  useEffect(() => {
    setSelectedOrders(currentOrder ? [currentOrder] : orders);
  }, [currentOrder, orders]);

  const { isOpen, onClose, onToggle } = useDisclosure();

  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    if (hasCopied) {
      trackEvent(EventName.TableShared, {
        'Project Name': projectName,
        'Project ID': projectId,
        'Table Name': orderName,
        'Expiration Duration': EXPIRY_CHOICES.find(
          (choice) => choice.value === expiresIn
        )?.label,
      });
    }
  }, [
    EXPIRY_CHOICES,
    expiresIn,
    hasCopied,
    orderName,
    projectId,
    projectName,
    tenant?.name,
    trackEvent,
  ]);

  const fetchToken = useCallback(
    async (expiresIn: number) => {
      const result = await client.query(
        GetExternalAccessTokenDocument,
        {
          orderIds: selectedOrders.map((order) => order.id),
          expiresIn,
        },
        { requestPolicy: 'network-only' }
      );
      if (!result.data?.externalAccessToken?.accessToken) {
        console.error('Could not get access token', result.error);
        setError('Could not get access token');
      } else {
        const accessToken = result.data?.externalAccessToken?.accessToken;
        setAccessToken(accessToken);

        return accessToken;
      }
    },
    [client, selectedOrders]
  );

  const [, executeCreateSavedViewMutation] = useMutation(
    CreateSavedViewDocument
  );

  const [, createInitialState] = useMutation(CreateInitialStateDocument);

  const toast = useToast();

  const handleGenerateLinkClick = useCallback(async () => {
    setIsGeneratingLink(true);
    const currentView = await getCurrentView();
    const savedViewResult = await executeCreateSavedViewMutation({
      input: {
        savedView: {
          projectId,
          name: uuid(),
          cameraPosition: currentView.cameraPosition,
          categories: currentView.categories,
          layers: currentView.layers,
          statuses: currentView.statuses,
          hiddenElements: currentView.hiddenElements,
          hiddenModels: currentView.hiddenModels,
          isolatedElements: currentView.isolatedElements,
          isCommentView: false,
        },
      },
    });

    const savedViewId = savedViewResult.data?.createSavedView?.savedView?.id;

    if (!savedViewId) {
      setError('Could not create view');
      setIsGeneratingLink(false);
      return;
    }

    const externalAccessToken = await fetchToken(expiresIn);

    if (!externalAccessToken) {
      setError('Could not get access token');
      setIsGeneratingLink(false);
      return;
    }

    const isIsolationToggleActive =
      window.localStorage.getItem('isolate-linked-elements') === 'true';
    const isShowingShapeLabels =
      window.localStorage.getItem('shape-labels') === 'true';
    const ghosting =
      JSON.parse(
        window.localStorage.getItem(
          'Autodesk.Viewing.Private.GuiViewer3D.SavedSettings.AEC'
        ) ?? ''
      ).ghosting ?? false;
    const orthographic =
      window.localStorage.getItem('orthographic-mode') === 'true';

    const selectedOrderEntries = await getCurrentItemIdSelection();
    const expandedOrderEntries = await getCurrentExpandedItemIds();

    const initialState: InitialStateInput = {
      projectId,
      selectedOrderEntries,
      expandedOrderEntries,
      viewSettings: {
        ghosting,
        focusMode: isIsolationToggleActive,
        showLabels: isShowingShapeLabels,
        orthographicMode: orthographic,
      },
      viewId: savedViewId,
      externalAccessToken,
    };

    const initialStateResult = await createInitialState({
      input: {
        initialState,
      },
    });

    if (initialStateResult.error) {
      setError('Could not create initial state');
      setIsGeneratingLink(false);
      return;
    }

    const initialStateId =
      initialStateResult.data?.createInitialState?.initialState?.id;

    // Copy initial state ID to clipboard
    await window.navigator.clipboard.writeText(
      `${window.location.origin}/view/${initialStateId}` as string
    );
    setHasCopied(true);

    toast({
      title: t('table-sharing-popover.success'),
      status: 'success',
    });

    setIsGeneratingLink(false);
  }, [
    getCurrentView,
    executeCreateSavedViewMutation,
    projectId,
    fetchToken,
    expiresIn,
    getCurrentItemIdSelection,
    getCurrentExpandedItemIds,
    createInitialState,
    toast,
    t,
  ]);

  let modalBodyElement: React.ReactElement | null = null;

  if (error) {
    modalBodyElement = (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }
  modalBodyElement = (
    <VStack padding={1} marginTop={3}>
      {selectedOrders.length > 0 && (
        <>
          <Button
            onClick={handleGenerateLinkClick}
            isLoading={isGeneratingLink}
            colorScheme="gray"
            leftIcon={<SparkelIcon icon={faLink} color={'inherit'} />}
            size={'xs'}
            width="100%"
          >
            {hasCopied
              ? t('table-sharing-modal.link-copied')
              : t('table-sharing-modal.copy-link')}
          </Button>
        </>
      )}
      <Button
        size={'xs'}
        onClick={() => setIsSettingsExpanded(!isSettingsExpanded)}
        variant={'ghost'}
        colorScheme="gray"
        fontWeight={'normal'}
        color={'gray.500'}
        alignSelf={'flex-start'}
        leftIcon={
          <SparkelIcon
            icon={isSettingsExpanded ? faCaretDown : faCaretRight}
            color={'inherit'}
          />
        }
      >
        {isSettingsExpanded
          ? t('table-sharing-popover.hide-settings')
          : t('table-sharing-popover.show-settings')}
      </Button>
      {isSettingsExpanded && (
        <>
          <Text alignSelf="flex-start" fontSize={'xs'} color={'gray.500'}>
            {t('table-sharing-modal.select-tables')}
          </Text>
          <Box width="100%">
            <ChakraReactSelect
              isMulti
              isInvalid={selectedOrders.length === 0}
              closeMenuOnSelect={false}
              chakraStyles={chakraStyles}
              tagVariant="solid"
              variant="filled"
              value={selectedOrders.map((order) => ({
                value: order.id,
                label: order.name,
              }))}
              options={orders.map((order) => ({
                value: order.id,
                label: order.name,
              }))}
              onChange={(selectedOptions) =>
                setSelectedOrders(
                  selectedOptions.map((option) => ({
                    id: (option as { value: string; label: string }).value,
                    name: (option as { value: string; label: string }).label,
                  }))
                )
              }
            />
          </Box>
          <Text alignSelf="flex-start" fontSize={'xs'} color={'gray.500'}>
            {t('table-sharing-modal.expiration.label')}
          </Text>
          <Select
            value={expiresIn}
            variant={'filled'}
            size={'xs'}
            borderRadius={'md'}
            onChange={(event) =>
              void setExpiresIn(Number.parseInt(event.target.value))
            }
          >
            {EXPIRY_CHOICES.map((choice) => (
              <option key={choice.label} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </Select>
        </>
      )}
    </VStack>
  );

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        setHasCopied(false);
        onClose();
      }}
      isLazy
      closeOnBlur
    >
      <PopoverTrigger>
        <Button
          leftIcon={<SparkelIcon icon={faShare} color={'inherit'} />}
          size={'sm'}
          borderRadius={'md'}
          onClick={onToggle}
        >
          Share
        </Button>
      </PopoverTrigger>
      <Portal containerRef={{ current: document.body }}>
        <PopoverContent
          borderRadius={'lg'}
          shadow={'2xl'}
          width={'2xs'}
          zIndex={999}
        >
          <PopoverBody>
            <Heading as={'h3'} size={'xs'} marginY={1}>
              {t('table-sharing-modal.title')}
            </Heading>
            {modalBodyElement}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

const chakraStyles: ChakraStylesConfig = {
  control: (provided) => {
    return {
      ...provided,
      borderRadius: 'md',
      minHeight: 6,
      overflowY: 'auto',
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      paddingLeft: 2,
      fontSize: 'xs',
      marginTop: -0.5,
      paddingRight: 0,
      paddingY: 1,
    };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      fontSize: 'xs',
      paddingTop: 0.5,
      paddingBottom: 0,
      minHeight: 0,
      minWidth: 'fit-content',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      paddingY: 0,
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      padding: 0,
      width: 4,
      height: 4,
      fontSize: '0.5rem',
      colorScheme: 'gray',
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      marginX: 0,
      padding: 0,
      paddingBottom: 1,
      marginTop: '2px',
    };
  },
  noOptionsMessage: (provided) => {
    return {
      ...provided,
      fontSize: 'xs',
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      borderRadius: 'md',
      padding: 1,
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      minWidth: 200,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 'xs',
      borderRadius: 'lg',
      paddingY: 0,
      paddingLeft: 2,
      minHeight: 7,
      fontWeight: state.isSelected ? 'bold' : 'normal',
    };
  },
};
