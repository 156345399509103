import { useCallback, useMemo, useState } from 'react';
import { DbIdsPerModel } from 'src/components/common/ForgeViewer';

export type SelectedDbIds = {
  selectedDbIds: DbIdsPerModel;
  toggleSelectDbId: (dbId: number, modelUrn: string) => void;
  setSelectedDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
  selectDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
};

export function useSelectedDbIds() {
  const [selectedDbIds, setSelectedDbIds] = useState<DbIdsPerModel>({});
  const toggleSelectDbId = useCallback(
    (dbId: number, modelUrn: string) => {
      setSelectedDbIds((selectedDbIds) => {
        const selectedDbIdsForModel = selectedDbIds[modelUrn] || [];
        const newSelectedDbIdsForModel = selectedDbIdsForModel.includes(dbId)
          ? selectedDbIdsForModel.filter((id) => id !== dbId)
          : [...selectedDbIdsForModel, dbId];
        return {
          ...selectedDbIds,
          [modelUrn]: newSelectedDbIdsForModel,
        };
      });
    },
    [setSelectedDbIds]
  );

  const selectDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      setSelectedDbIds((selectedDbIds) => {
        return {
          ...selectedDbIds,
          ...dbIdsPerModel,
        };
      });
    },
    [setSelectedDbIds]
  );

  const selectedDbIdsWithoutEmptyEntries = useMemo(() => {
    return Object.fromEntries(
      Object.entries(selectedDbIds).filter(([_, dbIds]) => dbIds.length > 0)
    );
  }, [selectedDbIds]);

  return {
    selectDbIds,
    setSelectedDbIds,
    toggleSelectDbId,
    selectedDbIds,
    selectedDbIdsWithoutEmptyEntries,
  };
}
