import { PromisePool, ProcessHandler } from '@supercharge/promise-pool';

export const runWorkInBatches = async <T, Y>(
  data: Array<T>,
  callBack: ProcessHandler<T, Y>,
  concurrency = 10
) => {
  return PromisePool.for(data).withConcurrency(concurrency).process(callBack);
};

export function makeTinierBatches<T>(items: Array<T>, take = 10): T[][] {
  const batches: T[][] = [];

  for (let i = 0; i < items.length; i += take) {
    batches.push(items.slice(i, i + take));
  }

  return batches;
}
