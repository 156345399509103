import {
  AUTODESK_VIEWING_INITIALIZER_API,
  AUTODESK_VIEWING_INITIALIZER_ENV,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';
import AggregatedViewInitOptions = Autodesk.Viewing.AggregatedViewInitOptions;

export enum ViewerExtension {
  Snapping = 'Autodesk.Snapping',
  PDF = 'Autodesk.PDF',
  Edit2D = 'Autodesk.Edit2D',
  SceneBuilder = 'Autodesk.Viewing.SceneBuilder',
}

type InitializeAutodeskViewingProps = {
  getAccessToken: (
    callback?: (accessToken: string, expires: number) => void
  ) => void;
};

export function initializeAutodeskViewing({
  getAccessToken,
}: InitializeAutodeskViewingProps) {
  // This is a temporary fix until Autodesk provides a way to get the SVF2 dbids from the SVF1 dbids.
  // https://aps.autodesk.com/blog/temporary-workaround-mapping-between-svf1-and-svf2-ids
  // Override `PropDbLoader#load` so that the svf1/svf2 ID mapping is always loaded.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const _load = Autodesk.Viewing.Private.PropDbLoader.prototype.load;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Autodesk.Viewing.Private.PropDbLoader.prototype.load = function () {
    this.needsDbIdRemap = true;
    _load.call(this);
  };

  // Override `PropDbLoader#processLoadResult` so that the ID mapping is stored within all models (and not just in 2D models).
  const _processLoadResult =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Autodesk.Viewing.Private.PropDbLoader.prototype.processLoadResult;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Autodesk.Viewing.Private.PropDbLoader.prototype.processLoadResult = function (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result
  ) {
    _processLoadResult.call(this, result);
    this.model.idRemap = result.dbidOldToNew;
  };

  return new Promise<void>((resolve) => {
    Autodesk.Viewing.Initializer(
      {
        env: AUTODESK_VIEWING_INITIALIZER_ENV,
        api: AUTODESK_VIEWING_INITIALIZER_API,
        getAccessToken,
      },
      () => resolve()
    );
  });
}

export function buildAggregatedViewInitOptions(): AggregatedViewInitOptions {
  return {
    getCustomLoadOptions: () => ({
      applyRefPoint: false,
    }),
  };
}
